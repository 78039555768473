export default {
  "yes": "Yes",
  "no": "No",
  "ok": "OK",
  "cancel": "Cancel",
  "hour": "Hour",
  "hours": "Hours",
  "week": "Week",
  "month": "Month",
  "year": "Year",
  "tyCard": "Thank You Card",
  "tyCards": "Thank You Cards",
  "send": "Send",
  "public": "Public",
  "private": "Private",
  "like": "Like",
  "likes": "Likes",
  "comment": "Comment",
  "comments": "Comments",
  "reply": "Reply",
  "share": "Share",
  "person": "person",
  "people": "people",
  "selected": "selected",
  "search": "Search",
  "members": "Members",
  "errors": {
    "errorLoadingData": "Error loading data",
    "errorLoadingPage": "Error loading page",
    "errorReload": "Error loading menu, please reload the page",
    "translationError": "Error translating text, please try again",
    "translationErrorLabel": "Translations Error",
    "translationErrorText": "Unable to translate text",
  },
  "success": {
    "general": "Action was successful"
  },
  "noData": "It looks like there is nothing here yet!",
  "noDataDescription": "Check back later to see updates.",
  "translate": "Translate",
  "seeOriginal": "See Original",
  "publishedBy": "Published by",
  "sort": "Sort",
  "aToZ": "A to Z",
  "zToA": "Z to A",
  "mostViewed": "Most viewed",
  "mostLiked": "Most liked",
  "oldestFirst": "Oldest first",
  "newestFirst": "Newest first",
  "edited": "Edited",
  "default": "Default",
  "folder": "Folder",
};
