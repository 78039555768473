import React, { FunctionComponent, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { faArrowUpArrowDown, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './filterPanel.scss';
import doubleTick from '../../../icons/circle-check-solid.svg';
import { ContentData } from '~/types/types';
import { useTranslation } from 'react-i18next';
import { SearchField } from '../searchField/searchField';

export enum Sort {
    default = 'default',
    mostViewed = 'mostViewed',
    mostLiked = 'mostLiked',
    oldestDate = 'oldestDate',
    newestDate = 'newestDate',
    alphabeticalAscending = 'alphabeticalAscending',
    alphabeticalDescending = 'alphabeticalDescending',
}

type FilterPanelProps = {
    searchQuery: string;
    showFilter: boolean;
    showSearch: boolean;
    showSort: boolean;
    contentFiltered: ContentData[];
    handleOnClickResetAliasFilter: () => void;
    handleOnClickFilterByName: (name: string) => void;
    handleOnClickFilterMostViewed: React.MouseEventHandler<HTMLElement>;
    handleOnClickFilterMostLiked: React.MouseEventHandler<HTMLElement>;
    handleOnClickFilterOldestFirst: React.MouseEventHandler<HTMLElement>;
    handleOnClickFilterNewestFirst: React.MouseEventHandler<HTMLElement>;
    handleOnClickFilterAlphabetically: (isAscending: boolean) => void;
    handleOnClickFilterDefault: React.MouseEventHandler<HTMLElement>;
    handleSearch: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
    handleSearchReset: ((name?: any) => void) | undefined;
};

const FilterPanel: FunctionComponent<FilterPanelProps> = ({
    searchQuery,
    showFilter,
    showSearch,
    showSort,
    contentFiltered,
    handleOnClickResetAliasFilter,
    handleOnClickFilterByName,
    handleOnClickFilterMostViewed,
    handleOnClickFilterMostLiked,
    handleOnClickFilterOldestFirst,
    handleOnClickFilterNewestFirst,
    handleOnClickFilterAlphabetically,
    handleOnClickFilterDefault,
    handleSearch,
    handleSearchReset,
}) => {
    const [toggleSort, setToggleSort] = useState<boolean>(false);
    const [toggleAlias, setToggleAlias] = useState<boolean>(false);
    const { t } = useTranslation();

    let uniqueAliases: { [key: string]: any } = {};

    contentFiltered?.forEach((content) => {
        const alias = content.contentPage.publisher;
        if (alias) {
            uniqueAliases[`${alias.entity}_${alias.id}`] = alias;
        }
    });

    const toggleSortHandleOnClick = () => {
        setToggleSort(!toggleSort);
    };

    const toggleAliasHandleOnClick = () => {
        setToggleAlias(!toggleAlias);
    };

    return (
        <div data-testid='filter-panel' className={`filter-panel-dropdown ${showSearch || showFilter || showSort ? '' : 'd-none'}`}>
            {showSearch ? (
                <div className='filter-panel-item search-wrapper'>
                    <SearchField
                        data-testid='search-element'
                        searchQuery={searchQuery}
                        handleSearch={handleSearch}
                        handleSearchReset={handleSearchReset}
                        placeholder={String(t('general.search'))}
                        sx={{ color: 'black' }}
                        displayClearButton={true}
                        iconStyle={{ fontSize: '16px', padding: '0 10px' }}
                    />
                </div>
            ) : (
                ''
            )}
            {showFilter ? (
                <div data-testid='filter-element' className='filter-panel-item'>
                    <Dropdown toggle={toggleAliasHandleOnClick} isOpen={toggleAlias}>
                        <DropdownToggle className='filter-panel-dropdown-button' data-cy='alias-filter'>
                            {t('general.publishedBy')}
                            <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '5px' }} />
                        </DropdownToggle>
                        <DropdownMenu style={{ width: 'auto', marginTop: '6px', zIndex: 1 }}>
                            <DropdownItem data-testid='all-filter-item' onClick={handleOnClickResetAliasFilter}>
                                <img
                                    src={doubleTick}
                                    alt='all'
                                    className='media-object rounded-circle'
                                    height='43'
                                    width='43'
                                    style={{ marginRight: '5px', objectFit: 'cover' }}
                                />
                                {t('cards.all')}
                            </DropdownItem>
                            {Object.values(uniqueAliases).map((alias) => {
                                return (
                                    <DropdownItem data-testid='alias-name' key={alias.id} onClick={() => handleOnClickFilterByName(alias.name)}>
                                        <img
                                            src={alias.image}
                                            alt='alias-group'
                                            className={`media-object ${alias?.is_round ? 'rounded-circle' : ''}`}
                                            height='43'
                                            width='43'
                                            style={{ marginRight: '5px', objectFit: 'cover' }}
                                        />
                                        {alias.name}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            ) : (
                ''
            )}
            {showSort ? (
                <div data-testid='sort-element' className='filter-panel-item'>
                    <Dropdown toggle={toggleSortHandleOnClick} isOpen={toggleSort}>
                        <DropdownToggle className='filter-panel-dropdown-button'>
                            <FontAwesomeIcon icon={faArrowUpArrowDown} style={{ marginRight: '3px' }} />
                            {t('general.sort')}
                        </DropdownToggle>
                        <DropdownMenu style={{ marginTop: '6px', zIndex: 1 }}>
                            <DropdownItem data-testid='default' onClick={handleOnClickFilterDefault}>
                                {t('general.default')}
                            </DropdownItem>
                            <DropdownItem data-testid='ascending-sort' onClick={() => handleOnClickFilterAlphabetically(true)}>
                                {t('general.aToZ')}
                            </DropdownItem>
                            <DropdownItem data-testid='descending-sort' onClick={() => handleOnClickFilterAlphabetically(false)}>
                                {t('general.zToA')}
                            </DropdownItem>
                            <DropdownItem data-testid='most-viewed-sort' onClick={handleOnClickFilterMostViewed}>
                                {t('general.mostViewed')}
                            </DropdownItem>
                            <DropdownItem data-testid='most-liked-sort' onClick={handleOnClickFilterMostLiked}>
                                {t('general.mostLiked')}
                            </DropdownItem>
                            <DropdownItem data-testid='oldest-sort' onClick={handleOnClickFilterOldestFirst}>
                                {t('general.oldestFirst')}
                            </DropdownItem>
                            <DropdownItem data-testid='newest-sort' onClick={handleOnClickFilterNewestFirst}>
                                {t('general.newestFirst')}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default FilterPanel;
