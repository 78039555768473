import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import connectService from '../../../../web/services/connectService';
import { ContactUserData, ContactUserMainDetails } from '../../../../types/types';
import './profileSection.scss';
import { AxiosResponse } from 'axios';
import awardsService from '../../../../web/services/awardsService';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SpinnerLoad from '../../common/spinnerLoad/spinnerLoad';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FollowingDetailModal from '../../followingDetailModal/followingDetailModal';
import SendThankYouButton from '../../common/sendThankYouButton/sendThankYouButton';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export type profileSectionProps = {
    setUserData: Function;
};

const ProfileSection: React.FunctionComponent<profileSectionProps> = ({ setUserData }) => {
    const { t } = useTranslation();
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    const {
        tycText: {
            btn: { value: awardsConfigName },
        },
        button: p2pBtn,
    } = useSelector((state: any) => state.app.tenant.tyBranding);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [openFollowingDetailDialog, setOpenFollowingDetailDialog] = useState<{ open: boolean; defaultTab: number }>({
        open: false,
        defaultTab: 0,
    });

    const [userDetail, setUserDetails] = useState<ContactUserData>();
    const [mainDetail, setMainDetails] = useState<ContactUserMainDetails>();
    const [inboxAwards, setInboxAwards] = useState([]);

    const fetchDataById = (id: string) => {
        connectService.getContactById(id, (resp: AxiosResponse) => {
            setUserData(resp.data.data.user);
            setUserDetails(resp.data.data.user);
            setMainDetails(resp.data.data);
        });
    };

    useEffect(() => {
        setLoading(true);
        connectService.getLoggedInUserData((resp: AxiosResponse) => {
            fetchDataById(resp.data.data.user.contact_id);
        });

        awardsService.getInbox(1, (resp: AxiosResponse) => {
            setInboxAwards(resp.data.data.slice(0, 5));
            setLoading(false);
        });
    }, []);

    const renderAwards = inboxAwards?.map((award: any) => (
        <div data-testid='award-list' className='award' key={award.id}>
            <img src={award?.image_url} alt='Award image' className='award-img' />
            <h3 className='d-flex w-100 justify-content-center mt-3 font-secondary-text'>{award?.image_title}</h3>
            <h4 className='mb-3 d-flex w-100 justify-content-center font-secondary-text'>
                {t('feed.SentBy')} {award.sender.full_name}
            </h4>
        </div>
    ));

    const handleFollowingCount = () => {
        fetchDataById(userDetail && userDetail.contact_id ? userDetail.contact_id.toString() : '');
    };

    return (
        <div data-testid='profile-awards-section' className='h-100 profile-awards-section'>
            <Card className='profile-card position-relative'>
                {isLoading || typeof userDetail === 'undefined' ? (
                    <CardBody className='loader-card-body'>
                        <SpinnerLoad
                            className={
                                'position-absolute bg-white place-at-top d-flex justify-content-center align-items-center h-100 w-100 top-0 left-0'
                            }
                        />
                    </CardBody>
                ) : (
                    <>
                        <img
                            alt='Profile header image'
                            className='profile-background-image'
                            src={
                                userDetail?.header_image_url && userDetail.header_image_url !== ''
                                    ? userDetail.header_image_url
                                    : userDetail?.default_header_img
                            }
                        />
                        <CardBody>
                            <Row className='position-absolute w-100 profile-row'>
                                <Col lg={4}></Col>
                                <Col lg={4} className='d-flex justify-content-center'>
                                    <Link to={`/connect/people/${userDetail.contact_id}`} className='react-router-link'>
                                        <img alt='Profile image' className='user-profile-img c-pointer' src={userDetail.image_url} />
                                    </Link>
                                </Col>
                                <Col lg={4}></Col>
                            </Row>
                            <Link to={`/connect/people/${userDetail.contact_id}`} className='react-router-link'>
                                <div className='d-flex justify-content-center w-100 mt-4 pt-2 text-center c-pointer'>
                                    <h3 data-testid='user-fullname' className='font-primary-text'>
                                        {userDetail?.full_name}
                                    </h3>
                                </div>
                            </Link>
                            <Link to={`/connect/people/${userDetail.contact_id}`} className='react-router-link'>
                                <div className='d-flex justify-content-center w-100 mb-1 text-center c-pointer'>
                                    <h4 data-testid='user-job-title' className='font-secondary-text'>
                                        {userDetail?.job_title}
                                    </h4>
                                </div>
                            </Link>

                            {userDetail?.awards && (
                                <div data-testid='user-awards' className='d-flex justify-content-center align-items-center w-100'>
                                    {userDetail.awards.map((award, index) =>
                                        index < 3 ? (
                                            <React.Fragment key={index}>
                                                <ReactTooltip anchorId={`detail_${award?.id}`} />
                                                <a href={`page/${award.page_id}`} className='react-router-link'>
                                                    <img
                                                        src={award.badge_image}
                                                        className='me-1 mb-2 user-award c-pointer'
                                                        alt={`${award.title} image`}
                                                        id={`detail_${award?.id}`}
                                                        data-tooltip-content={award.title}
                                                        data-iscapture='true'
                                                    />
                                                </a>
                                            </React.Fragment>
                                        ) : (
                                            ''
                                        )
                                    )}
                                </div>
                            )}
                            {mainDetail?.social_post_view && (
                                <>
                                    <Row className='w-100 mt-2 p-0 m-0'>
                                        <Col lg={6} xs={6} className='d-flex justify-content-center followers-column'>
                                            <div
                                                className='user-followers'
                                                onClick={() => {
                                                    setOpenFollowingDetailDialog({
                                                        open: true,
                                                        defaultTab: 1,
                                                    });
                                                }}
                                            >
                                                <h3 data-testid='user-followers' className='d-flex justify-content-center font-primary-text'>
                                                    {mainDetail?.followers}
                                                </h3>
                                                <h4 className='mt-1 font-secondary-text'>{t('feed.Followers')}</h4>
                                            </div>
                                        </Col>
                                        <Col lg={6} xs={6} className='d-flex justify-content-center following-column'>
                                            <div
                                                className='user-following'
                                                onClick={() => {
                                                    setOpenFollowingDetailDialog({
                                                        open: true,
                                                        defaultTab: 0,
                                                    });
                                                }}
                                            >
                                                <h3 data-testid='user-followings' className='d-flex justify-content-center font-primary-text'>
                                                    {mainDetail?.following}
                                                </h3>
                                                <h4 className='mt-1 font-secondary-text'>{t('feed.Following')}</h4>
                                            </div>
                                        </Col>
                                        {openFollowingDetailDialog.open && (
                                            <FollowingDetailModal
                                                isOpen={openFollowingDetailDialog.open}
                                                userId={userDetail.contact_id}
                                                defaultTab={openFollowingDetailDialog.defaultTab}
                                                totalFollowing={mainDetail.following}
                                                totalFollowers={mainDetail.followers}
                                                handleDialogClose={() => {
                                                    handleFollowingCount();
                                                    setOpenFollowingDetailDialog({ open: false, defaultTab: 0 });
                                                }}
                                            />
                                        )}
                                    </Row>
                                </>
                            )}
                            {tenantInfo.allowP2P && (
                                <div className='d-flex justify-content-center w-100 mt-2'>
                                    <SendThankYouButton
                                        className='w-100'
                                        label={awardsConfigName}
                                        backgroundColor={p2pBtn.background}
                                        foregroundColor={p2pBtn.foreground}
                                        icon={p2pBtn.icon}
                                    />
                                </div>
                            )}
                        </CardBody>
                    </>
                )}
            </Card>
            {tenantInfo.allowP2P && (
                <Card
                    className={`awards-card position-relative mt-3 ${
                        userDetail?.awards && userDetail.awards.length > 0 ? 'with-awards' : 'no-awards'
                    } ${userDetail?.job_title ? '' : 'no-job-title'}`}
                >
                    <CardBody className='mb-3 award-card-body h-100'>
                        <div className='d-flex justify-content-between' style={{ height: '1.875rem' }}>
                            <h3 className='font-primary-text'>{t('feed.SentToYou')}</h3>
                            <Link
                                data-testid='see-all-btn'
                                className='font-secondary-text'
                                to='/awards/me'
                                style={{
                                    cursor: 'pointer',
                                    color: 'black',
                                    textDecoration: 'underline',
                                }}
                            >
                                {t('feed.SeeAll')}
                            </Link>
                        </div>
                        {isLoading ? (
                            <SpinnerLoad
                                className={
                                    'position-absolute bg-white place-at-top d-flex justify-content-center align-items-center h-100 w-100 top-0 left-0'
                                }
                            />
                        ) : inboxAwards.length != 0 ? (
                            <div className='awards-inbox mt-2'>{renderAwards}</div>
                        ) : (
                            <div className='h-100 w-100 d-flex justify-content-center no-awards-content'>
                                <Typography
                                    sx={{
                                        fontSize: '1rem',
                                        color: '#000',
                                        textAlign: 'center',
                                        fontWeight: '500',
                                    }}
                                >
                                    {t('feed.emptyAwardsMessage', '')}
                                </Typography>
                            </div>
                        )}
                    </CardBody>
                </Card>
            )}
        </div>
    );
};

export default ProfileSection;
