import React from 'react';
import { Box, SxProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { SvgIcon } from '../svgIcon/svgIcon';
import { ReactComponent as CircleXMarkIcon } from '../../../icons/circle-xmark-solid.svg';

export interface SearchFieldProps {
    handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    handleSearch?: React.ChangeEventHandler<HTMLInputElement>;
    searchQuery: string | any | number | readonly string[] | undefined;
    sx?: SxProps;
    placeholder?: string;
    iconStyle?: React.CSSProperties;
    name?: string;
    handleSearchReset?: (name?: string) => void;
    displayClearButton?: boolean;
    displayClearButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
    icon?: React.ReactNode;
    iconSx?: SxProps;
}

export const SearchField = (props: SearchFieldProps) => {
    const {
        handleKeyDown,
        sx,
        handleSearch,
        searchQuery,
        placeholder,
        iconStyle,
        name,
        handleSearchReset,
        displayClearButton = false,
        displayClearButtonProps,
        icon,
        iconSx,
    } = props;

    return (
        <Box data-testid='search-field' className='search-field' sx={{ ...iconStyle, ...sx }}>
            <FontAwesomeIcon
                icon={faMagnifyingGlass}
                fontSize='20px'
                style={
                    iconStyle
                        ? iconStyle
                        : {
                              padding: '0 10px',
                          }
                }
            />
            <input
                className='search-field-input'
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                data-testid='search-field-input'
                onChange={handleSearch}
                value={searchQuery}
                name={name}
            />
            {searchQuery && displayClearButton && (
                <button className='unstyled-btn' onClick={() => handleSearchReset && handleSearchReset(name)} {...displayClearButtonProps}>
                    <SvgIcon sx={{ width: '15px', height: '15px', marginBottom: '8px', color: '#9C9C9C', ...iconSx }}>
                        <CircleXMarkIcon />
                    </SvgIcon>
                </button>
            )}
        </Box>
    );
};
