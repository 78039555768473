import React from 'react';
import { useSelector } from 'react-redux';

export type TranslateBtnWrapperProps = {
    children: React.ReactNode;
    locale?: string | null;
};

export const TranslateBtnWrapper = (props: TranslateBtnWrapperProps) => {
    const features = useSelector((state: any) => state.app.features);
    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const displayTranslateBtn = features?.translations?.user_content;
    const { children, locale } = props;
    return <>{displayTranslateBtn && locale && (locale !== languagetoTranslate || locale === 'translated') && children}</>;
};
