import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './sendThankYouButton.scss';
import { AnchorWrapper } from '../../anchorWrapper/anchorWrapper';

export type SendThankYouButtonProps = {
    className?: string;
    label: string;
    icon?: string;
    backgroundColor?: string;
    foregroundColor?: string;
    isIconAtEnd?: boolean;
    handleOnClick?: Function;
};

const SendThankYouButton: React.FC<SendThankYouButtonProps> = ({
    className,
    label,
    icon,
    backgroundColor,
    foregroundColor,
    handleOnClick,
    isIconAtEnd = false,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const renderIcon = () =>
        icon ? (
            <img src={icon} alt='thank-you-button-icon' className='esg-thanks text-engage-primary icon-size-connect tyc-icon-feed' />
        ) : (
            <em
                className={'esg-icon esg-thanks text-engage-primary icon-size-connect tyc-icon-feed m-0'}
                style={{ color: foregroundColor ? foregroundColor : '#ffffff' }}
            ></em>
        );

    return (
        <AnchorWrapper anchorEl={false} linkProps={{ to: '/awards/new', className: 'react-router-link' }}>
            <button
                data-testid='send-p2p-btn'
                onClick={() => {
                    if (handleOnClick) {
                        handleOnClick();
                        navigate('/awards/new');
                    }
                }}
                className={`${className} send-p2p-btn pe-3 ps-3 d-flex justify-content-center align-items-center`}
                style={{ backgroundColor: backgroundColor ? backgroundColor : '#62257d' }}
            >
                {!isIconAtEnd && renderIcon()}
                <h3
                    style={{ color: foregroundColor ? foregroundColor : '#ffffff' }}
                    className={`${isIconAtEnd ? 'me-2' : 'ms-2'} send-p2p-btn-text font-secondary-text tyc-btn-text`}
                >
                    {` ${t('feed.SendAThankYou', {
                        label: label ?? t('awards.thankYou'),
                    })}`}
                </h3>
                {isIconAtEnd && renderIcon()}
            </button>
        </AnchorWrapper>
    );
};

export default SendThankYouButton;
