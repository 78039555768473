import React, { useEffect } from 'react';
import { ThreadList } from '../../modules/ThreadList/ThreadList';
import { Grid } from '@mui/material';
import { useSearchHook, SearchHook } from '../../../web/hooks/useSearchHook';
import { ChatBox } from '../../modules/ChatBox/ChatBox';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDebounce } from '../../../web/hooks/useDebounceHook';
import { useSelector } from 'react-redux';
import { useThreadsQuery, useMessagesQuery, useContactsQuery } from './queryHooks';
import { useQueryClient } from 'react-query';
import { SocketTypes } from '../../../types/enum/socketTypes';
import { PostSendMessage, useSendMessage } from '../../../web/hooks/useSendMessageHook';
import { PostCreatedPayload } from '../../../types/interface/PostCreatedPayload';
import { NotificationPayload } from '../../../types/interface/NotificationPayload';
import { GroupDetailsType } from '../../../types/interface/Thread';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { useTranslation } from 'react-i18next';
import { removeHtmlTags, removeUserTags } from '../../../web/utils/htmlParser';
import { createSocketConnection } from '../../../socket-service';
import { useMarkAsRead } from '../../hooks/useMarkAsReadHook';
const renderHtml = require('html-react-parser');

import _ from 'lodash';
import { DeletePostPayload } from '../../../types/interface/DeletePostPayload';

const initialGroupDetails = {
    id: '',
    title: '',
    description: '',
    image: null,
};

export const Chat = () => {
    const { id: threadID } = useParams<{ id: string }>();
    const threadIDRef = React.useRef<string | undefined>(threadID);
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
        searchQuery: threadSearchQuery,
        handleChange: handleSearchThread,
        handleSearchReset: handleResetThreadQuery,
    }: SearchHook = useSearchHook('');
    const {
        searchQuery: contactSearchQuery,
        handleChange: handleSearchContact,
        handleSearchReset: handleResetContactQuery,
    }: SearchHook = useSearchHook('');
    const { debouncedValue: debouncedThreadSearchQuery, setDebouncedValue: setDebouncedThreadQuery } = useDebounce(threadSearchQuery);
    const { debouncedValue: debouncedContactSearchQuery, setDebouncedValue: setDebouncedContactSearchQuery } = useDebounce(contactSearchQuery);
    const [displayCreateChat, setDisplayCreateChat] = React.useState<boolean>(false);
    const [displayArchivedChat, setDisplayArchivedChat] = React.useState<boolean>(false);
    const [isResetThreadList, setIsResetThreadList] = React.useState<boolean>(false);
    const [displayCreateNewGroup, setDisplayCreateNewGroup] = React.useState<boolean>(false);
    const [isGroupSetting, setIsGroupSetting] = React.useState<boolean>(false);
    const [groupEditMode, setGroupEditMode] = React.useState<boolean>(false);
    const [groupEditDetails, setGroupEditDetails] = React.useState<GroupDetailsType>(initialGroupDetails);
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    const userInfo = useSelector((state: any) => state.app.user);
    const appState = useSelector((state: any) => state.app.app);
    const postCreateRef = React.useRef<PostCreatedPayload | null>(null);
    const notificationRef = React.useRef<NotificationPayload | null>(null);
    const deleteMessageRef = React.useRef<any>(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { mutate: sendMessage, isLoading: sendingMessage } = useSendMessage(
        (data: any, variable: PostSendMessage) => successSendMessage(data, variable),
        (data: any, variable: PostSendMessage) => errorSendMessage(data, variable)
    );
    const { mutate: markAsRead } = useMarkAsRead();

    const successSendMessage = (data: any, variable: PostSendMessage) => {
        let unreadMessage: boolean = false;
        if (!threadID) {
            navigate(`/chat/${data.thread_id}`, {
                state: {
                    title: location.state?.title,
                    description: location.state?.description,
                    icon_url: location.state?.icon_url,
                    id: location.state?.id,
                },
            });
            queryClient.invalidateQueries(['threads', [debouncedThreadSearchQuery, displayArchivedChat]]);
        }

        queryClient.setQueryData(['messages', threadID], (prevData: any) => {
            if (!prevData) return;
            const newData = _.cloneDeep(prevData);
            const post = data;
            post.sent_by_me = post.author_id === userInfo.id;
            post.author.id = post.author_id === userInfo.id ? userInfo.id : post.author_id;
            newData.pages.forEach((page: any) => {
                if (!page || page?.data.length === 0) return;
                const postIndex = page.data.findIndex((post: any) => post.id === data.request_id);
                if (postIndex !== -1) {
                    page.data.splice(postIndex, 1, post);
                }
            });
            return newData;
        });

        //update unread count to 0 when a user sends a message
        queryClient.setQueryData(['threads', [debouncedThreadSearchQuery, displayArchivedChat]], (prevData: any) => {
            if (!prevData) return;
            const threadData = _.cloneDeep(prevData);
            threadData.pages.map((page: any) => {
                page?.data.map((thread: any, index: number) => {
                    if (thread.id.toString() === threadID) {
                        unreadMessage = thread.unread_count > 0;
                        thread.unread_count = 0;
                        return;
                    }
                });
            });
            return threadData;
        });
        if (unreadMessage && threadID) {
            markAsRead(parseInt(threadID));
        }
    };

    const errorSendMessage = (error: any, variable: PostSendMessage) => {
        queryClient.setQueryData(['messages', threadID], (prevData: any) => {
            if (!prevData) return;
            const newData = _.cloneDeep(prevData);
            newData.pages.forEach((page: any) => {
                const postIndex = page.data.findIndex((post: any) => post.id === variable.request_id);
                if (postIndex !== -1) {
                    page.data[postIndex].error = true;
                    page.data[postIndex].error_message = t('chat.failedToSendMessage');
                    page.data[postIndex].formData = variable;
                }
            });
            return newData;
        });
        dispatch(
            addNotification({
                label: t('chat.sendMessage'),
                text: error.message + ': ' + t('chat.failedToSendMessage'),
                type: 'danger',
            })
        );
    };

    const navigateToFirstThread = (threadList: any, navigate: any) => {
        if (threadList?.pages?.length) {
            let threads = threadList.pages[0];
            const firstThread = threads.data[0];
            if (!firstThread) return;
            navigate(`/chat/${firstThread?.id}`, {
                state: {
                    title: firstThread.title,
                    description: firstThread.description,
                    icon_url: firstThread.icon_url,
                },
            });
        }
    };

    const handleResetThreadList = (data: any) => {
        if (isResetThreadList) {
            setIsResetThreadList(false);
            navigateToFirstThread(data, navigate);
        }
    };

    const {
        isLoading: isFetchingThreads,
        error: fetchError,
        data: threadListData,
        fetchNextPage: fetchThreadsNextPage,
        isFetchingNextPage: fetchingNextThreadPage,
        hasNextPage: hasNextThreadPage,
    } = useThreadsQuery(debouncedThreadSearchQuery, displayArchivedChat, handleResetThreadList);

    const {
        isLoading: isFetchingMessages,
        data: messagesData,
        fetchNextPage: fetchNextMessages,
        isFetchingNextPage: isFetchingNextMessagePage,
    } = useMessagesQuery(threadID);

    const {
        isLoading: isFetchingContacts,
        data: contactsData,
        refetch: refetchContacts,
        fetchNextPage: fetchContactNextPage,
        isFetchingNextPage: fetchingNextContactPage,
    } = useContactsQuery(debouncedContactSearchQuery, displayCreateChat);

    if (fetchError) {
        return <div data-testid='chat-error'>An error has occurred</div>;
    }

    const refreshThreadDetail = (isLeaveGroup: boolean = false) => {
        queryClient.invalidateQueries(['threads', [debouncedThreadSearchQuery, displayArchivedChat]]);
        if (!isLeaveGroup) queryClient.invalidateQueries(['messages', threadID]);
    };

    const handleEditGroupDetail = (groupDetails: GroupDetailsType) => {
        setGroupEditDetails((prev) => {
            return {
                ...prev,
                id: threadID,
                title: groupDetails.title,
                description: groupDetails.description,
                image: groupDetails.image,
            };
        });
    };

    const handleArchiveThread = (threadId: string | undefined) => {
        let trigger = false;
        let prevThread: any;
        if (threadListData?.pages?.length) {
            threadListData.pages.map((page: any) => {
                page?.data?.map((thread: any, index: number) => {
                    if (trigger) {
                        navigate(`/chat/${thread.id}`, {
                            state: {
                                title: thread.title,
                                description: thread.description,
                                icon_url: thread.icon_url,
                            },
                        });
                        trigger = false;
                    }
                    if (thread.id == threadId) {
                        if (page?.data?.length === 1) {
                            setDisplayArchivedChat(false);
                            setIsResetThreadList(true);
                            navigate('/chat');
                        }
                        if (index == page?.data?.length - 1 && prevThread) {
                            navigate(`/chat/${prevThread.id}`, {
                                state: {
                                    title: prevThread.title,
                                    description: prevThread.description,
                                    icon_url: prevThread.icon_url,
                                },
                            });
                        }
                        trigger = true;
                    }
                    prevThread = thread;
                });
            });
        }
    };

    /**
     * @description: Handles the keydown event for the search input
     * @param e : React.KeyboardEvent<HTMLInputElement>
     */
    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter') {
            displayCreateChat ? setDebouncedContactSearchQuery(contactSearchQuery) : setDebouncedThreadQuery(threadSearchQuery);
        }
    };

    const postCreatedCallback = (payload: PostCreatedPayload) => {
        if (!payload || _.isEqual(payload, postCreateRef.current)) return;
        queryClient.setQueryData(['messages', threadID], (prevData: any) => {
            if (!prevData) return;
            const newData = _.cloneDeep(prevData);
            const post = payload.post;
            post.sent_by_me = post.author_id === userInfo.id;
            post.author.id = post.author_id === userInfo.id ? userInfo.id : post.author_id;
            if (!post.sent_by_me) {
                newData.pages[0].data.unshift(post);
            }
            return newData;
        });
        queryClient.setQueryData(['threads', [debouncedThreadSearchQuery, displayArchivedChat]], (prevData: any) => {
            if (!prevData) return;
            const threadData = _.cloneDeep(prevData);
            let selectedThread: any;
            threadData.pages.map((page: any) => {
                page.data.map((thread: any, index: number) => {
                    if (thread.id.toString() === threadID) {
                        selectedThread = thread;
                        page.data.splice(index, 1);
                    }
                });
            });
            //add thread to the top of the list and return the new data
            threadData.pages[0].data.unshift({
                ...selectedThread,
                preview_text: `${payload?.post.author.name}: ${
                    !payload?.post.post
                        ? payload?.post.files.length > 0
                            ? '📁'
                            : ''
                        : renderHtml(removeHtmlTags(removeUserTags(payload?.post.post)))
                }`,
                updated_at_human: payload.post.created_at ? moment.utc(payload.post.created_at).local().fromNow() : '',
                updated_at: payload.post.created_at,
            });
            return threadData;
        });
        postCreateRef.current = payload;
    };

    const notificationCallback = (payload: NotificationPayload) => {
        if (!payload || _.isEqual(payload, notificationRef.current)) return;
        queryClient.setQueryData(['threads', [debouncedThreadSearchQuery, displayArchivedChat]], (prevData: any) => {
            if (!prevData) return;
            // Clone the previous data to avoid modifying the original data
            const newData = _.cloneDeep(prevData);
            //pop the thread by checking for thread_id in all pages and push new one to the top
            newData.pages.map((page: any) => {
                page?.data?.map((thread: any, index: number) => {
                    if (thread.id == payload.thread_id) {
                        page?.data?.splice(index, 1);
                    }
                });
            });
            let selectedThread = threadIDRef.current && +threadIDRef.current == payload.thread_id;
            const threadData = {
                ...payload,
                id: payload.thread_id,
                unread_count: selectedThread ? 0 : payload.unread_posts_count,
            };
            if (selectedThread) {
                markAsRead(payload.thread_id);
            } else {
                dispatch({
                    type: 'SET_APP_STATE',
                    payload: {
                        ...appState,
                        mark_as_read_message: true,
                    },
                });
            }
            newData.pages[0].data.unshift(threadData);
            return newData;
        });
        notificationRef.current = payload;
    };

    const deleteMessageCallback = (payload: { post: DeletePostPayload }) => {
        if (!payload.post || _.isEqual(payload, deleteMessageRef.current)) return;
        const { post } = payload;
        let lastestPost: boolean = false;
        let isUser: boolean = post.author_id === userInfo.id;
        if (isUser) {
            deleteMessageRef.current = payload;
            return;
        }
        queryClient.setQueryData(['messages', threadID], (prevData: any) => {
            if (!prevData) return;
            const newData = _.cloneDeep(prevData);
            newData.pages.some((page: any) => {
                //check if it is page 0 index 0
                if (page.data[0].id === post.id) {
                    lastestPost = true;
                }
                const postIndex = page.data.findIndex((message: any) => message.id === post.id);
                if (postIndex !== -1) {
                    page.data[postIndex].deleted = true;
                    page.data[postIndex].deleted_by_user = false;
                    return true;
                }
                return false;
            });
            return newData;
        });

        if (lastestPost && !isUser) {
            queryClient.setQueryData(['threads', [debouncedThreadSearchQuery, displayArchivedChat]], (prevData: any) => {
                const newThreadData = _.cloneDeep(prevData);
                newThreadData.pages[0].data[0].deleted = true;
                newThreadData.pages[0].data[0].updated_at_human = moment.utc(post.deleted_at).local().fromNow();
                newThreadData.pages[0].data[0].updated_at = post.deleted_at;
                return newThreadData;
            });
        }

        deleteMessageRef.current = payload;
    };

    useEffect(() => {
        if (!tenantInfo.id || !threadID || !userInfo.id) {
            console.debug('Missing tenantId or channelId for socket connection');
            return;
        }

        // Join the socket channel for the current thread
        createSocketConnection();

        threadIDRef.current = threadID;

        window.Echo.private(`${tenantInfo.id}.thread.${threadID as string}`).listen(SocketTypes.POST_CREATED, postCreatedCallback);

        window.Echo.private(`${tenantInfo.id}.user.${userInfo.id}`).notification(notificationCallback);

        window.Echo.private(`${tenantInfo.id}.thread.${threadID as string}`).listen(SocketTypes.POST_DELETED, deleteMessageCallback);
    }, [tenantInfo.id, threadID, userInfo.id]);

    useEffect(() => {
        if (location.pathname === '/chat') {
            navigateToFirstThread(threadListData, navigate);
        }
        if (threadIDRef.current) {
            const thread = threadListData?.pages
                ?.map((page: any) => page.data)
                .flat()
                .find((thread: any) => thread.id == threadIDRef.current);
            if (thread && thread.unread_count > 0) {
                markAsRead(parseInt(threadIDRef.current));
                queryClient.invalidateQueries(['threads', [debouncedThreadSearchQuery, displayArchivedChat]]);
            }
        }
        if (!appState?.mark_as_read_message) {
            dispatch({
                type: 'SET_APP_STATE',
                payload: {
                    ...appState,
                    mark_as_read_message: true,
                },
            });
        }
    }, [threadListData]);

    return (
        <Grid container spacing={2} data-testid='chat'>
            <Grid item xs={6} lg={4}>
                <ThreadList
                    data={!displayCreateChat ? threadListData : contactsData}
                    handleSearch={displayCreateChat ? handleSearchContact : handleSearchThread}
                    handleResetThreadSearch={handleResetThreadQuery}
                    handleResetContactSearch={handleResetContactQuery}
                    loading={!displayCreateChat ? isFetchingThreads : isFetchingContacts}
                    selectedThread={threadID}
                    searchQuery={displayCreateChat ? contactSearchQuery : threadSearchQuery}
                    handleKeyDown={handleKeyDown}
                    setDisplayCreateChat={setDisplayCreateChat}
                    displayCreateChat={displayCreateChat}
                    showCreateChatBtn={tenantInfo.messagesEnabled}
                    displayArchivedChat={displayArchivedChat}
                    displayCreateNewGroup={displayCreateNewGroup}
                    isGroupSetting={isGroupSetting}
                    groupEditMode={groupEditMode}
                    groupEditDetails={groupEditDetails}
                    setDisplayArchivedChat={setDisplayArchivedChat}
                    fetchNextPage={!displayCreateChat ? fetchThreadsNextPage : fetchContactNextPage}
                    fetchingNextPage={!displayCreateChat ? fetchingNextThreadPage : fetchingNextContactPage}
                    setIsResetThreadList={setIsResetThreadList}
                    setIsGroupSetting={setIsGroupSetting}
                    setDisplayCreateNewGroup={setDisplayCreateNewGroup}
                    refreshThreadDetail={refreshThreadDetail}
                    setGroupEditMode={setGroupEditMode}
                    handleArchiveThread={handleArchiveThread}
                />
            </Grid>
            <Grid item xs={6} lg={8}>
                <ChatBox
                    title={location.state?.title ? location.state?.title : messagesData?.pages?.[0]?.related?.thread?.title}
                    description={location.state?.description ? location.state?.description : messagesData?.pages?.[0]?.related?.thread?.description}
                    recipientId={location.state?.id}
                    mutedUntil={location.state?.mutedUntil}
                    debouncedSearchQuery={debouncedThreadSearchQuery}
                    mutedByAdmin={location.state?.mutedByAdmin}
                    loading={isFetchingMessages}
                    threadList={threadListData?.pages}
                    hasNextThreadPage={hasNextThreadPage}
                    fetchThreadsNextPage={fetchThreadsNextPage}
                    data={messagesData}
                    icon_url={location.state?.icon_url ? location.state?.icon_url : messagesData?.pages?.[0]?.related?.thread?.icon_url}
                    displayCreateChat={displayCreateChat}
                    displayArchivedChat={displayArchivedChat}
                    newChat={location.pathname.indexOf('new') !== -1}
                    fetchNextPage={fetchNextMessages}
                    isGroupSetting={isGroupSetting}
                    displayCreateNewGroup={displayCreateNewGroup}
                    fetchingNextPage={isFetchingNextMessagePage}
                    groupEditMode={groupEditMode}
                    handleArchiveThread={handleArchiveThread}
                    refreshThreadDetail={refreshThreadDetail}
                    sendMessage={sendMessage}
                    sendingMessage={sendingMessage}
                    setIsGroupSetting={setIsGroupSetting}
                    setGroupEditMode={setGroupEditMode}
                    handleEditGroupDetail={handleEditGroupDetail}
                />
            </Grid>
        </Grid>
    );
};
