// utils/translationErrorHandler.ts

import { Dispatch } from 'redux';
import { addNotification } from '../../shared/reducers/notifications/actionTypes';
import { TFunction } from 'i18next';
import { SetStateAction } from 'react';

type HandleTranslationErrorParams = {
    translateID: number | null;
    setTranslateID: ((id: number | null) => void) | React.Dispatch<React.SetStateAction<Element | null>>;
    setClickedTranslate: React.Dispatch<SetStateAction<{ [key: number]: boolean | null; }>>;
    dispatch: Dispatch;
    t: TFunction;
};

export const handleTranslationError = ({
    translateID,
    setTranslateID,
    setClickedTranslate,
    dispatch,
    t,
}: HandleTranslationErrorParams) => {
    setTranslateID(null);
    setClickedTranslate((prev) => ({
        ...prev,
        [translateID as number]: false,
    }));
    dispatch(
        addNotification({
            label: t('general.errors.translationErrorLabel'),
            text: t('general.errors.translationErrorText'),
            type: 'danger',
        })
    );
};
