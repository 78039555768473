import React from 'react';
import { Grid, Box } from '@mui/material';

export type CustomTemplateOneProps = {
    components: any[];
};

export const CustomTemplateOne = (props: CustomTemplateOneProps) => {
    const { components } = props;

    return (
        <Grid container data-template={1} spacing={2} data-testid='custom-template-1'>
            <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box
                    data-pos={1}
                    sx={{
                        maxHeight: components[1]?.key === 'banner' ? '100%' : '35vh',
                    }}
                >
                    {components[1]}
                </Box>
                <Box
                    data-pos={2}
                    sx={{
                        maxHeight: '15vh',
                        overflow: 'auto',
                    }}
                >
                    {components[2]}
                </Box>
                <Box data-pos={3} sx={{ height: '100%' }}>
                    {components[3]}
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box
                    data-pos={4}
                    sx={{
                        position: 'sticky',
                        top: 0,
                        height: '88vh',
                        overflow: 'auto',
                    }}
                >
                    {components[4]}
                </Box>
            </Grid>
        </Grid>
    );
};
