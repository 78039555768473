import './recentComments.scss';
import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { Card, CardHeader, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
const renderHtml = require('html-react-parser');
import { useTranslation } from 'react-i18next';
import { LatestComment, RecentComment } from '~/types/types';
import { Box, Skeleton, SxProps } from '@mui/material';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faGlobe as faSolidGlobe } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { TranslateBtnWrapper } from '../translateBtnWrapper/translateBtnWrapper';

type RecentCommentsProps = {
    data?: RecentComment[];
    feedCommentList?: LatestComment[];
    handleTranslateComment?: Function;
    clickedTranslateCommentObj?: {
        [key: number]: boolean | null;
    };
    translatingComment?: boolean;
    commentToTranslateID?: number | null;
    sx?: SxProps;
};

const RecentComments: FunctionComponent<RecentCommentsProps> = ({
    data = [],
    feedCommentList = [],
    handleTranslateComment,
    clickedTranslateCommentObj,
    translatingComment,
    commentToTranslateID,
    sx,
}) => {
    const { t } = useTranslation();
    const textRefs = useRef<any>([]);
    const [textContainerHeight, setTextContainerHeight] = useState<{ height: number }[]>([]);
    const brandingColors = useSelector((state: any) => state.app.branding);
    useEffect(() => {
        if (textRefs.current.length > 0) {
            const height = textRefs.current.map((el: any) => {
                return { height: el.clientHeight };
            });
            setTextContainerHeight(height);
        }
    }, [textRefs.current]);

    const renderComment = (data: any) => {
        return data.map((comment: LatestComment | RecentComment, commentIndex: number) => {
            return (
                <ListGroupItem
                    key={comment.id}
                    data-testid='recent-comment-list'
                    className='mt-1'
                    style={{
                        display: 'flex',
                        flexFlow: 'row nowrap',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Link to={`/connect/people/${comment.author.contact_id}`} className='react-router-link'>
                        <img
                            src={comment.author.image_url}
                            alt={`${comment.author.full_name} profile photo`}
                            className='rounded-circle thumb48 c-pointer'
                            data-testid='profile-icon'
                            style={{ objectFit: 'cover' }}
                        />
                    </Link>
                    <div style={{ marginLeft: '10px', flex: 1 }}>
                        <div className='user-comment-detail mb-1' style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link to={`/connect/people/${comment.author.contact_id}`} className='react-router-link'>
                                <ListGroupItemHeading
                                    tag='h3'
                                    className='font-weight-bold mb-0 py-1 pb-0 font-primary-text author-name'
                                    data-testid='author-fullname'
                                >
                                    {comment.author.full_name}
                                </ListGroupItemHeading>
                            </Link>
                            <a href={`/page/${comment.content_id}`} className='react-router-link'>
                                <div className='timestamp' style={{ marginTop: '-0.25em', fontSize: '0.9em' }}>
                                    <p data-testid='updated-at-humans' className='my-0 font-secondary-text comment-timestamp'>
                                        {comment.updated_at_humans}
                                    </p>
                                </div>
                            </a>
                        </div>
                        <a href={`/page/${comment.content_id}`} className='react-router-link'>
                            <ListGroupItemText data-testid='comment-text' className='mb-0 font-secondary-text comment-value'>
                                {translatingComment && commentToTranslateID === comment.id ? (
                                    <Skeleton
                                        variant='text'
                                        width='100%'
                                        height={`${textContainerHeight[commentIndex]?.height}px`}
                                        animation={'wave'}
                                    />
                                ) : (
                                    <div ref={(el) => (textRefs.current[commentIndex] = el)}>
                                        {comment.value ? renderHtml(String(comment.value)) : ''}
                                    </div>
                                )}
                            </ListGroupItemText>
                        </a>
                        <TranslateBtnWrapper
                            locale={
                                clickedTranslateCommentObj && clickedTranslateCommentObj[comment.id]
                                    ? 'translated'
                                    : (comment?.locale as string | null)
                            }
                        >
                            <div
                                className='translate-btn c-pointer'
                                style={{ marginTop: '0.25em', fontSize: '0.9em' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleTranslateComment &&
                                        handleTranslateComment(
                                            comment.id,
                                            clickedTranslateCommentObj && clickedTranslateCommentObj[comment.id]
                                                ? !clickedTranslateCommentObj[comment.id]
                                                : true
                                        );
                                }}
                            >
                                {clickedTranslateCommentObj && clickedTranslateCommentObj[comment.id] ? (
                                    <>
                                        <FontAwesomeIcon icon={faSolidGlobe} className='c-pointer' style={{ paddingRight: '4px' }} />
                                        {t('general.seeOriginal')}
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faGlobe} className='c-pointer pr-2' /> {t('general.translate')}
                                    </>
                                )}
                            </div>
                        </TranslateBtnWrapper>
                    </div>
                </ListGroupItem>
            );
        });
    };

    return (
        <Box data-testid='recent-comments' className={'custom-layout-latest-comments'} sx={sx}>
            <Card className={'latest-comments-list h-100'} style={{ borderRadius: 10, overflow: 'hidden' }}>
                <CardHeader id='latest-comments-header' style={{ backgroundColor: brandingColors.web_navbar_background }}>
                    <h2
                        data-testid='latest-comments-title'
                        className='font-weight-bold mb-0 py-1'
                        style={{ color: brandingColors.web_navbar_foreground }}
                    >
                        {t('feed.LatestComments')}
                    </h2>
                </CardHeader>
                {data?.length === 0 && feedCommentList?.length === 0 ? (
                    <div
                        data-testid='empty-comments-section'
                        className={'h-100'}
                        style={{
                            padding: '0.5rem 1rem',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {t('feed.noCommentsYet')}
                    </div>
                ) : (
                    <Box sx={{ height: '100%', overflow: 'auto' }}>
                        <ListGroup flush>{data.length > 0 ? renderComment(data) : renderComment(feedCommentList)}</ListGroup>
                    </Box>
                )}
            </Card>
        </Box>
    );
};

export default RecentComments;
