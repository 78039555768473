import React, { useState, useEffect } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useWebListQuery } from '../../hooks/query/useWebListQuery';
import { useParams } from 'react-router-dom';
import { ListViewCard } from '../../components/listViewCard/listViewCard';
import { ContentData, ContentType } from '../../../types/types';
import Breadcrumbs from '../../components/customLayout/breadcrumbs';
import { AnchorWrapper } from '../../components/anchorWrapper/anchorWrapper';
import RecentComments from '../../components/customLayout/recentComments';
import feedService from '../../services/feedService';
import { AxiosResponse } from 'axios';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTranslateQuery } from '../../hooks/query/useTranslateQuery';
import SpinnerLoad from '../../components/common/spinnerLoad/spinnerLoad';
import { useQueryClient } from 'react-query';
import FilterPanel, { Sort } from '../../components/customLayout/filterPanel';
import { useSearchHook, SearchHook } from '../../hooks/useSearchHook';
import { useDebounce } from '../../hooks/useDebounceHook';
import { handleTranslationError } from '../../utils/translationErrorHandle';

export const WebListView = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { id: folderID, type } = useParams<{ id: string; type: 'section' | 'category' }>();
    const { searchQuery, handleChange: handleSearch, handleSearchReset: handleSearchReset }: SearchHook = useSearchHook('');
    const { debouncedValue: debouncedSearchValue } = useDebounce(searchQuery);
    const [filterByName, setFilterByName] = useState<string>();
    const [sort, setSort] = useState<Sort>(Sort.default);
    const [commentsData, setComments] = useState<Array<any>>([]);
    const [commentsLoading, setCommentsLoading] = useState(true);
    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [commentToTranslateID, setCommentToTranslateID] = useState<number | null>(null);
    const [clickedTranslateComment, setClickedTranslateComment] = useState<{ [key: number]: boolean | null }>({});
    const [clickedTranslateFolder, setClickedTranslateFolder] = useState<{ [key: number]: boolean | null }>({});
    const [translatedFolder, setTranslatedFolder] = useState<{
        [key: number]: {
            originalText: {
                catName: string;
                subtitle: string;
            };
            translatedText: {
                catName: string;
                subtitle: string;
            };
        };
    }>({});
    const [folderToTranslateID, setFolderToTranslateID] = useState<number | null>(null);
    const [translatedComment, setTranslatedComment] = useState<{
        [key: number]: {
            originalText: string;
            translatedText: string;
        };
    }>({});
    const [clickedTranslateTile, setClickedTranslateTile] = useState<{ [key: number]: boolean | null }>({});
    const [translatedTile, setTranslatedTile] = useState<{
        [key: number]: {
            originalText: {
                title: string;
                preview_text: string;
            };
            translatedText: {
                title: string;
                preview_text: string;
            };
        };
    }>({});
    const [tileToTranslateID, setTileToTranslateID] = useState<number | null>(null);

    const {
        data: webListData,
        fetchNextPage,
        isFetchingNextPage,
        isLoading: loadingData,
        isError: errorFetchingWebListData,
    } = useWebListQuery(Number(folderID), type as 'section' | 'category', debouncedSearchValue);

    const { isLoading: translatingTile } = useTranslateQuery(
        tileToTranslateID,
        'page',
        languagetoTranslate,
        (response?: { data?: { translated?: { title: string; preview_text: string }; original: { title: string; preview_text: string } } }) => {
            if (!response?.data?.translated || !tileToTranslateID) return;
            const translatedObj = response.data.translated;
            const originalContent = response.data.original;
            queryClient.setQueryData(['web-list', Number(folderID), debouncedSearchValue], (prev: any) => ({
                ...prev,
                pages: prev.pages.map((page: any) => {
                    const item = page.data.find((list: ContentData) => list.contentPage.id === tileToTranslateID);
                    if (item) {
                        item.title = translatedObj.title;
                        item.contentPage.preview_text = translatedObj.preview_text;
                    }
                    return page;
                }),
            }));
            setTranslatedTile((prev) => ({
                ...prev,
                [tileToTranslateID]: {
                    originalText: originalContent,
                    translatedText: translatedObj,
                },
            }));
            setTileToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: tileToTranslateID,
                setTranslateID: setTileToTranslateID,
                setClickedTranslate: setClickedTranslateTile,
                dispatch,
                t,
            });
        }
    );

    const { isLoading: translatingFolder } = useTranslateQuery(
        folderToTranslateID,
        'category',
        languagetoTranslate,
        (response?: { data?: { translated?: { catName: string; subtitle: string }; original: { catName: string; subtitle: string } } }) => {
            if (!response?.data?.translated || !folderToTranslateID) return;
            const translatedObj = response.data.translated;
            const originalContent = response.data.original;
            queryClient.setQueryData(['web-list', Number(folderID), debouncedSearchValue], (prev: any) => ({
                ...prev,
                pages: prev.pages.map((page: any) => {
                    const item = page.data.find((list: ContentData) => list.id === folderToTranslateID);
                    if (item) {
                        item.title = translatedObj.catName;
                        item.subtitle = translatedObj.subtitle;
                    }
                    return page;
                }),
            }));
            setTranslatedFolder((prev) => ({
                ...prev,
                [folderToTranslateID as number]: {
                    originalText: originalContent,
                    translatedText: translatedObj,
                },
            }));
            setFolderToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: folderToTranslateID,
                setTranslateID: setFolderToTranslateID,
                setClickedTranslate: setClickedTranslateFolder,
                dispatch,
                t,
            });
        }
    );

    const { isLoading: translatingComment } = useTranslateQuery(
        commentToTranslateID,
        'comment',
        languagetoTranslate,
        (response) => {
            if (!response || !commentToTranslateID) return;
            const translatedText = response.data.translated.value;
            setTranslatedComment((prev) => {
                return {
                    ...prev,
                    [commentToTranslateID]: {
                        originalText: response.data.original.value,
                        translatedText: translatedText,
                    },
                };
            });
            setComments((prev: any) => {
                return prev.map((comment: any) => {
                    if (comment.id === commentToTranslateID) {
                        comment.value = translatedText;
                    }
                    return comment;
                });
            });
            setCommentToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: commentToTranslateID,
                setTranslateID: setCommentToTranslateID,
                setClickedTranslate: setClickedTranslateComment,
                dispatch,
                t,
            });
        }
    );

    useEffect(() => {
        feedService.getLastComments(
            (resp: AxiosResponse) => {
                setComments(resp.data.data);
                setCommentsLoading(false);
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Last Comments`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
                setCommentsLoading(false);
            }
        );
    }, []);

    if (errorFetchingWebListData) {
        dispatch(
            addNotification({
                label: `WebList`,
                text: t('general.errors.errorLoadingData'),
                type: 'danger',
            })
        );
    }

    const handleTranslateComment = (commentID: number, translateComment: boolean) => {
        setClickedTranslateComment((prev) => {
            return { ...prev, [commentID]: translateComment };
        });
        if (!translateComment) {
            setComments((prev: any) => {
                return prev.map((comment: any) => {
                    if (comment.id === commentID && translatedComment[commentID]) {
                        comment.value = translatedComment[commentID].originalText;
                    }
                    return comment;
                });
            });
            return;
        }
        setCommentToTranslateID(commentID);
    };

    const handleTranslateCard = (id: number, translate: boolean) => {
        setClickedTranslateTile((prev) => ({ ...prev, [id]: translate }));
        if (!translate) {
            queryClient.setQueryData(['web-list', Number(folderID), ''], (prev: any) => ({
                ...prev,
                pages: prev.pages.map((page: any) => {
                    const item = page.data.find((list: ContentData) => list.contentPage.id === id);
                    if (item) {
                        item.title = translatedTile[id].originalText.title;
                        item.contentPage.preview_text = translatedTile[id].originalText.preview_text;
                    }
                    return page;
                }),
            }));
            return;
        }
        setTileToTranslateID(id);
    };

    const handleTranslateFolder = (id: number, translate: boolean) => {
        setClickedTranslateFolder((prev) => ({ ...prev, [id]: translate }));
        if (!translate) {
            queryClient.setQueryData(['web-list', Number(folderID), ''], (prev: any) => ({
                ...prev,
                pages: prev.pages.map((page: any) => {
                    const item = page.data.find((list: ContentData) => list.id === id);
                    if (item) {
                        item.title = translatedFolder[id].originalText.catName;
                        item.subtitle = translatedFolder[id].originalText.subtitle;
                    }
                    return page;
                }),
            }));
            return;
        }
        setFolderToTranslateID(id);
    };

    const handleScroll = (e: any) => {
        let bottom = Math.round(e.target.clientHeight + e.target.scrollTop) + 1 >= e.target.scrollHeight;
        if (bottom && webListData?.pages[0].links.next) {
            fetchNextPage();
        }
    };

    const getFilteredContent = (data: ContentData[], sort: Sort | undefined, filterByName: string | undefined) => {
        let result = [...data];
        if (sort !== undefined) {
            return result
                .sort((a, b) => {
                    switch (sort) {
                        case Sort.mostViewed:
                            return b.counts.views - a.counts.views;
                        case Sort.mostLiked:
                            return b.counts.likes - a.counts.likes;
                        case Sort.oldestDate:
                            return a.timestamp - b.timestamp;
                        case Sort.newestDate:
                            return b.timestamp - a.timestamp;
                        case Sort.alphabeticalAscending:
                            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                        case Sort.alphabeticalDescending:
                            return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
                        default:
                            return 0;
                    }
                })
                .filter((content) => {
                    return filterByName ? content.contentPage?.publisher?.name === filterByName : true;
                });
        }
    };

    const handleOnClickResetAliasFilter = () => {
        setFilterByName('');
    };

    const handleOnClickFilterByName = (name: string) => {
        setFilterByName(name);
    };

    const handleOnClickFilterMostViewed = () => {
        setSort(Sort.mostViewed);
    };

    const handleOnClickFilterMostLiked = () => {
        setSort(Sort.mostLiked);
    };

    const handleOnClickFilterOldestFirst = () => {
        setSort(Sort.oldestDate);
    };

    const handleOnClickFilterNewestFirst = () => {
        setSort(Sort.newestDate);
    };

    const handleOnClickFilterAlphabetically = (isAscending: boolean) => {
        setSort(isAscending ? Sort.alphabeticalAscending : Sort.alphabeticalDescending);
    };

    const handleOnClickFilterDefault = () => {
        setSort(Sort.default);
    };

    const filteredData = getFilteredContent(webListData?.pages.flatMap((page) => page.data) || [], sort, filterByName);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: { xs: 'stretch', md: 'center' },
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Breadcrumbs contentType={type === 'section' ? ContentType.Section : ContentType.Folder} contentId={Number(folderID)} />
                <FilterPanel
                    searchQuery={searchQuery}
                    showSearch={true}
                    showFilter={true}
                    showSort={true}
                    handleSearch={handleSearch}
                    handleSearchReset={handleSearchReset}
                    handleOnClickResetAliasFilter={handleOnClickResetAliasFilter}
                    handleOnClickFilterByName={handleOnClickFilterByName}
                    handleOnClickFilterMostViewed={handleOnClickFilterMostViewed}
                    handleOnClickFilterMostLiked={handleOnClickFilterMostLiked}
                    handleOnClickFilterOldestFirst={handleOnClickFilterOldestFirst}
                    handleOnClickFilterNewestFirst={handleOnClickFilterNewestFirst}
                    handleOnClickFilterAlphabetically={handleOnClickFilterAlphabetically}
                    handleOnClickFilterDefault={handleOnClickFilterDefault}
                    contentFiltered={filteredData || []}
                />
            </Box>
            <Grid container sx={{ height: '94%', overflow: 'hidden' }}>
                <Grid item xs={isSmallScreen ? 12 : 9} sx={{ height: '100%', overflowY: 'auto' }} onScroll={handleScroll}>
                    <Box data-testid='web-list-view' sx={{ pb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {errorFetchingWebListData ? (
                            <Box>{t('general.errors.errorLoadingData')}</Box>
                        ) : (
                            <>
                                {loadingData &&
                                    Array.from({ length: 5 }).map((_, index) => (
                                        <ListViewCard key={index} loading={loadingData} type={ContentType.Folder} contentID={1} />
                                    ))}
                                {filteredData &&
                                    filteredData.map((content: ContentData, index: number) => {
                                        const folder = content.type === 'FOLDER';
                                        return (
                                            <AnchorWrapper
                                                anchorEl={content.type === 'PAGE'}
                                                anchorProps={{
                                                    rel: 'noopener noreferrer',
                                                    href:
                                                        !content.contentPage.allow_likes &&
                                                        !content.contentPage.allow_comments &&
                                                        !content.contentPage.allow_share &&
                                                        (content.type_name === 'URL' || content.type_name === 'SSO')
                                                            ? content.contents
                                                            : `/page/${content.contentPage.id}`,
                                                    className: 'react-router-link',
                                                }}
                                                linkProps={{
                                                    to:
                                                        content.web_layout === 'list'
                                                            ? `/list/category/${content.id}`
                                                            : content.web_layout === 'tile'
                                                            ? `/tile/category/${content.id}`
                                                            : `/custom/category/${content.id}`,
                                                    className: 'react-router-link',
                                                }}
                                            >
                                                <ListViewCard
                                                    key={index}
                                                    contentID={folder ? content.id : content?.contentPage?.id}
                                                    loading={loadingData}
                                                    imgSrc={content.image}
                                                    imgAlt={content.title}
                                                    title={content.title}
                                                    description={folder ? content.subtitle : content.contentPage.preview_text}
                                                    likes={content.counts?.likes}
                                                    comments={content.counts?.comments}
                                                    type={content.type}
                                                    publisher={
                                                        {
                                                            name: content.contentPage.publisher?.name,
                                                            image: content.contentPage.publisher?.image,
                                                        } as { name: string; image: string }
                                                    }
                                                    published_at={content.contentPage.published_at}
                                                    handleTranslate={folder ? handleTranslateFolder : handleTranslateCard}
                                                    clickedTranslateObj={folder ? clickedTranslateFolder : clickedTranslateTile}
                                                    translatingTile={folder ? translatingFolder : translatingTile}
                                                    tileToTranslateID={folder ? folderToTranslateID : tileToTranslateID}
                                                    locale={folder ? content.locale : content.contentPage.locale}
                                                />
                                            </AnchorWrapper>
                                        );
                                    })}
                            </>
                        )}
                        {isFetchingNextPage && (
                            <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                        )}
                    </Box>
                </Grid>
                {!isSmallScreen && (
                    <Grid
                        item
                        xs={3}
                        sx={{
                            position: 'sticky',
                            right: 0,
                            top: 0,
                            height: '100%',
                            width: '100%',
                            overflowY: 'auto',
                        }}
                    >
                        {commentsLoading ? (
                            <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                        ) : (
                            <RecentComments
                                feedCommentList={commentsData}
                                handleTranslateComment={handleTranslateComment}
                                clickedTranslateCommentObj={clickedTranslateComment}
                                translatingComment={translatingComment}
                                commentToTranslateID={commentToTranslateID}
                                sx={{ paddingLeft: '10px' }}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
};
