export default {
    "award": "{{text, en-handle-an}}",
    "awards": "Prémios",
    "send": "Enviar {{text, en-handle-an}}",
    "all": "Todos",
    "inbox": "Para Mim",
    "sent": "Enviadas",
    "team": "Equipe",
    "choose": "Escolher",
    "toggleManager": "Alternar mensagens do gerente",
    "manager": "Gestor",
    "received": "recebida {{text, en-handle-an}}",
    "from": "de",
    "message": "Mensagem",
    "managerMessage": "Mensagem ao gestor",
    "setVisibility": "Definir visibilidade",
    "showInFeed": "Mostrado na cronologia de publicação",
    "onlyRecipients": "Visto apenas pelos destinatários",
    "recipients": "Destinatários",
    "for": "por",
    "messagePlaceholder": "Digite sua mensagem aqui",
    "thankYou": "Obrigado"
};