export default {
    "sharePost": "Start sharing...",
    "what do you have to say": "What do you have to say",
    "emptyField": "Can't save an empty social post.",
    "socialPost": "Social Post ",
    "postedFail": "Social Post couldn't be posted, please try again later.",
    "mediaFail": "There was an error during the media upload, please try again later.",
    "close": "Close",
    "mediaTooBig": "Some files exceeded the maximum size and couldn't be uploaded. Maximum file size is ",
    "mentionUsers": "Mention Users",
    "mentionUsersFail": "Unable to fetch mention users, please try again later.",
    "userUnavailable": "User unavailable",
    "postUnavailable": "Oops! We can’t find the post you're looking for.",

    "you": "You",
    "single_you": "You",

    "you_reposted_this": "reposted this",
    "singular_reposted_this": "reposted this",
    "we_reposted_this": "reposted this",
    "they_reposted_this": "reposted this",

    "and": "and",
    "more": "{{dynamicValue}} more",
    "users": "Reposted Users",
    "repostFailMessage": "You have already reposted this",
    "repost": "Repost",
    "repostWithYourThoughts": "Repost with your thoughts",
    "shareYourThoughtsOnThisPost": "Share your thoughts on this post",
    "repostWithoutThoughts": "Share {{dynamicValue}}'s post with your followers",
    "repostSuccessMessage": "Successfully reposted.",
    "editPostTimeLimit": "You can no longer edit this post as the time has expired.",
    "editPostSuccessMessage": "Post edited successfully.",
};