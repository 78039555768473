export default {
  "locations": "Locaties",
  "contacts": "Contacten",
  "favourites": "Favorieten",
  "all": "Alles",
  "noDataFound": "Geen gegevens gevonden",
  "thereAreNoPost": "Er zijn geen sociale berichten, begin met posten om ze hier te zien!",
  "noOrgChart": "Er is geen organigram gekoppeld aan deze gebruiker.",
  "awarded": "Bekroond",
  "expires": "Vervalt",
  "contactDetails": "Contactgegevens",
  "followers": "Volgers",
  "following": "Volgen",
  "follow": "Volg",
  "unfollow": "Niet meer volgen",
  "unsnooze": "Snooze uitzetten",
  "snooze": "Snooze",
  "1Hour": "1 uur",
  "8Hours": "8 uur",
  "24Hours": "24 uur",
  "1Week": "1 week",
  "1Month": "1 maand",
  "1Year": "1 jaar",
  "message": "Bericht",
  "details": "Details",
  "posts": "Berichten",
  "organisation": "Organisatie",
  "profileImageHeader": "Profielafbeelding",
  "headerImageHeader": "Kopafbeelding van het profiel",
  "uploadFile": "Bestand uploaden",
  "zoom": "Inzoomen (%)",
  "rotate": "Roteren",
  "done": "Klaar",
  "ratio": "Beeldverhouding behouden",
  "on": "Inschakelen",
  "off": "Uitschakelen",
  "search": "Zoek",
  "noFavourites": "Begin met het toevoegen van favoriete contacten!",
  "manager": "Manager",
  "location": "Locatie",
  "primaryEmail": "Primair e-mailadres",
  "dob": "Geboortedatum",
  "start": "Startdatum",
  "bio": "Bio",
  "chart": "Organigram",
  "close": "Sluiten",
  "address": "Adres",
  "description": "Omschrijving",
  "fName": "Voornaam",
  "lName": "Achternaam",
  "title": "Titel",
  "department": "Afdeling",
  "save": "Opslaan",
  "error": "Er is een fout opgetreden,",
  "errorLong": "probeer het later opnieuw.",
  "success": "Succes!",
  "successLong": "Gegevens correct opgeslagen",
  "email": "Email",
  "badge": "Badges",
  "skills": "Vaardigheden",
  "phone": "Telefoon",
  "hobbies": "Hobby's",
  "clearAll": "Alles wissen",
  "quickFilters": "Snelle filters",
  "customSearchTextPlaceholder": "Begin met typen en druk op enter...",
  "working hours": "Werktijden",
  "fun facts": "Leuke weetjes",
  "noResultsFound": "Geen resultaten gevonden",
  "charsLimitMsg": "Voer 3 of meer tekens in om te zoeken",
  "errorLoadingFollowers": "Fout bij het laden van volgers",
  "errorLoadingFollowings": "Fout bij het laden van de volgende",
  "followsYou": "Volgt jou",
  "searchContacts": "Contactpersonen zoeken",
  "searchLocations": "Locaties zoeken",
  "allContacts": "Alle contactpersonen",
  "edit": "Bewerken",
  "people": "Mensen",
  "directory": "adresboek",
  "noSearchResultsContactDescription": "Oeps! Het lijkt erop dat we geen contactpersonen konden vinden die aan je zoekopdracht voldoen.",
  "noContactResultsDescription": "Oeps! Het lijkt erop dat we geen contacten konden vinden.",
  "noSearchResultsFavouriteDescription": "Oeps! Het lijkt erop dat we geen favoriete contacten konden vinden die aan je zoekopdracht voldoen.",
  "noFavouriteResultsDescription": "Oeps! Geen favoriete contacten gevonden. Voeg contactpersonen toe aan je favorieten om ze hier te bekijken.",
  "noSearchResultsLocationDescription": "Oeps! Het lijkt erop dat we geen locaties konden vinden die aan je zoekopdracht voldoen.",
  "noLocationResultsDescription": "Oeps! Het lijkt erop dat we geen locaties konden vinden.",
  "clearFilters": "Filters wissen",
  "filters": "Filters",
  "noLocationPermission": "Oeps! Het lijkt erop dat je geen toestemming hebt om dit te zien. Probeer een andere locatie.",
  "groups": "Groepen",
  "joinGroup": "Groep joinen",
  "leaveGroup": "Groep verlaten",
  "mandatoryGroup": "Verplichte groep",
  "noSearchResultsGroupsDescription": "Oeps! Het lijkt erop dat we geen groepen hebben gevonden die aan je zoekopdracht voldoen.",
  "noGroupsResultsDescription": "Oeps! Het lijkt erop dat we geen groepen hebben gevonden.",
  "groupMembers": "Groepsleden",
  "noGroupMembers": "Oeps! het lijkt erop dat er nog geen leden in deze groep zitten.",
  "cannotLeaveGroupTooltip": "Je bent automatisch aan deze groep toegevoegd door een beheerder. Als je wilt vertrekken, neem dan contact op met een groepsbeheerder voor hulp.",
  "restrictPostingGroup": "Je kunt geen berichten plaatsen in deze groep"
};