import { useInfiniteQuery } from 'react-query';
import Client from '../../services/axiosService';

/**
 * @description: Fetches web list from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page
 * @returns
 */
const fetchWebListResults = async (id: number, type: 'category' | 'section', searchQuery: string | null, page: number) => {
    try {
        const response = await Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/custom/content/${id}/${type}?page=${page}&search=${searchQuery}`,
            method: 'get',
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message || 'Unable to fetch data', {});
    }
};

/**
 * @description: Fetches web list results from the API and returns the data
 * @param debouncedSearchQuery
 * @returns
 */
export const useWebListQuery = (id: number, type: 'category' | 'section', searchQuery: string | null) => {
    return useInfiniteQuery(['web-list', id, searchQuery], ({ pageParam = 1 }) => fetchWebListResults(id, type, searchQuery, pageParam), {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage?.links?.next) return pages.length + 1;
            return undefined;
        },
        refetchOnWindowFocus: false,
        retry: 1,
    });
};
