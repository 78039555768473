import React, { useEffect, useState } from 'react';
import type { ThreadListInterface } from '../../../types/interface/ThreadList';
import type { ThreadInterface } from '../../../types/interface/Thread';
import { Thread } from '../../components/thread/thread';
import SpinnerLoad from '../../components/common/spinnerLoad/spinnerLoad';
import { useMarkAsRead } from '../../hooks/useMarkAsReadHook';
import { useQueryClient } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useTranslateQuery } from '../../hooks/query/useTranslateQuery';
import { useLocation, useParams } from 'react-router-dom';
import { handleTranslationError } from '../../utils/translationErrorHandle';
import { useTranslation } from 'react-i18next';

export type ThreadListThreadsProps = {
    data: any;
    selectedThread?: string;
    isArchived?: boolean;
    fetchNextPage: () => void;
    fetchingNextPage: boolean;
    refreshThreadDetail: (isLeaveGroup?: boolean) => void;
    handleArchiveThread: (threadId: string | undefined) => void;
    searchQuery: string;
    displayArchivedChat: boolean;
};

export const ThreadListThreads = React.memo((props: ThreadListThreadsProps) => {
    const {
        data,
        selectedThread,
        fetchNextPage,
        fetchingNextPage,
        isArchived,
        refreshThreadDetail,
        handleArchiveThread,
        searchQuery,
        displayArchivedChat,
    } = props;
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const appState = useSelector((state: any) => state.app.app);
    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const [threadToTranslateID, setThreadToTranslateID] = useState<number | null>(null);
    const [clickedTranslateThread, setClickedTranslateThread] = useState<{ [key: number]: boolean | null }>({});
    const { id: threadID } = useParams<{ id: string }>();
    const [translatedThread, setTranslatedThread] = useState<{
        [key: string]: {
            originalText: {
                title: string;
                description: string;
            };
            translatedText: {
                title: string;
                description: string;
            };
        };
    }>({});
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (threadID && clickedTranslateThread[Number(threadID)]) {
            if (translatedThread[Number(threadID)]) {
                location.state.title = translatedThread[Number(threadID)].translatedText.title;
                location.state.description = translatedThread[Number(threadID)].translatedText.description;
            }
        }
    }, [threadID]);

    //need to handle error for markAsRead
    const { mutate: markAsRead } = useMarkAsRead(() => {
        queryClient.invalidateQueries('threads');
    });

    const handleThreadClick = (id: number, unread_message: boolean) => {
        if (unread_message) {
            markAsRead(id);
            dispatch({
                type: 'SET_APP_STATE',
                payload: {
                    ...appState,
                    mark_as_read_message: true,
                },
            });
        }
    };

    const handleScroll = (e: React.SyntheticEvent) => {
        const target = e.target as HTMLElement;
        const bottom = Math.floor(target.scrollHeight - target.scrollTop) === target.clientHeight;
        if (bottom && !fetchingNextPage) {
            fetchNextPage();
        }
    };

    const { isLoading: translatingThread } = useTranslateQuery(
        threadToTranslateID,
        'thread',
        languagetoTranslate,
        (response?: { data?: { translated?: { title: string; description: string } } }) => {
            if (!response?.data?.translated || !threadToTranslateID) return;
            const translatedObj = response.data.translated;
            queryClient.setQueryData(['threads', [searchQuery, displayArchivedChat]], (prevData: any) => {
                const threadData = cloneDeep(prevData);
                threadData.pages.map((page: any) => {
                    page?.data.map((thread: any) => {
                        if (thread.id == threadToTranslateID) {
                            setTranslatedThread((prev) => ({
                                ...prev,
                                [threadToTranslateID]: {
                                    originalText: {
                                        title: thread.title,
                                        description: thread.description,
                                    },
                                    translatedText: {
                                        title: translatedObj.title,
                                        description: translatedObj.description,
                                    },
                                },
                            }));
                            thread.title = translatedObj.title;
                            thread.description = translatedObj.description;
                            if (threadToTranslateID == Number(threadID)) {
                                location.state.title = translatedObj.title;
                                location.state.description = translatedObj.description;
                            }
                            return;
                        }
                    });
                });
                return threadData;
            });
            setThreadToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: threadToTranslateID,
                setTranslateID: setThreadToTranslateID,
                setClickedTranslate: setClickedTranslateThread,
                dispatch,
                t,
            });
        }
    );

    const handleTranslate = (idToTranslate: number, translate: boolean) => {
        setClickedTranslateThread((prev) => ({ ...prev, [idToTranslate]: translate }));
        if (!translate) {
            queryClient.setQueryData(['threads', [searchQuery, false]], (prevData: any) => {
                const threadData = cloneDeep(prevData);
                threadData.pages.map((page: any) => {
                    page?.data.map((thread: any) => {
                        if (thread.id == idToTranslate) {
                            thread.title = translatedThread[idToTranslate].originalText.title;
                            thread.description = translatedThread[idToTranslate].originalText.description;
                            if (idToTranslate == Number(threadID)) {
                                location.state.title = translatedThread[idToTranslate].originalText.title;
                                location.state.description = translatedThread[idToTranslate].originalText.description;
                            }
                            return;
                        }
                    });
                });
                return threadData;
            });
            return;
        }
        setThreadToTranslateID(idToTranslate);
    };

    return (
        <div className='thread-list' data-testid='thread-list' onScroll={handleScroll}>
            {data?.pages?.map((group: ThreadListInterface, index: number) => {
                return (
                    <React.Fragment key={index}>
                        {group?.data.map((thread: ThreadInterface) => (
                            <Thread
                                image_url={thread.icon_url}
                                title={Number(threadID) === thread.id ? location?.state?.title ?? thread.title : thread.title}
                                updatedAt={thread.updated_at}
                                updatedAtHuman={thread.updated_at_human}
                                post={thread.preview_text}
                                deleted={thread?.deleted}
                                key={thread.id}
                                id={thread.id}
                                description={thread.description}
                                threadType={thread.type}
                                mutedUntil={thread.muted_until}
                                mutedByAdmin={thread.muted_by_admin}
                                unread_count={thread.unread_count}
                                locale={thread.locale}
                                isArchived={isArchived}
                                handleClick={handleThreadClick}
                                refreshThreadDetail={refreshThreadDetail}
                                handleArchiveThread={handleArchiveThread}
                                handleTranslate={handleTranslate}
                                clickedTranslateThreadObj={clickedTranslateThread}
                                translatingThread={translatingThread}
                                threadToTranslateID={threadToTranslateID}
                                translatedThreadObj={translatedThread}
                                sx={{
                                    backgroundColor: selectedThread === thread?.id?.toString() ? '#F5F5F5' : '#FFFFFF',
                                }}
                            />
                        ))}
                        {fetchingNextPage && <SpinnerLoad className='w-100 d-flex align-items-center justify-content-center' size={50} />}
                    </React.Fragment>
                );
            })}
        </div>
    );
});
