import React from 'react';
import { Box, SxProps } from '@mui/material';

export type SvgIconProps = {
    children: React.ReactNode;
    sx?: SxProps;
};

export const SvgIcon = (props: SvgIconProps) => {
    const { children, sx } = props;
    return <Box sx={{ ...sx }}>
        {children}
    </Box>;
};
