import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchNextPageOptions, InfiniteQueryObserverResult, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { UserGroupsType } from '../../../types/interface/Thread';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import { IconCard } from '../IconCard/iconCard';
import './threadParticipantList.scss';
import { AnchorWrapper } from '../anchorWrapper/anchorWrapper';

export type ThreadParticipantListProps = {
    fetchingUserGroups?: boolean;
    hasNextPage?: boolean;
    userGroups?: UserGroupsType;
    fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<any, unknown>>;
};

const ThreadListParticipants: React.FC<ThreadParticipantListProps> = ({ fetchingUserGroups, hasNextPage, userGroups, fetchNextPage }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleCardClick = (contactId: number) => {
        navigate(`/connect/people/${contactId}`);
    };

    const renderParticipantList = useMemo(
        () =>
            userGroups?.map((userGroup) => {
                return userGroup.data.map((user, i) => (
                    <AnchorWrapper
                        anchorEl={false}
                        linkProps={{
                            to: `/connect/people/${user.contact_id}`,
                            className: 'react-router-link',
                        }}
                    >
                        <IconCard
                            key={i}
                            title={user.full_name}
                            icon={user.avatar_url}
                            titleStyle={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            {user.is_admin && <span className='font-16 fa-regular fa-crown' />}
                        </IconCard>
                    </AnchorWrapper>
                ));
            }),
        [userGroups]
    );

    const pageScroll = (e: any) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (!fetchingUserGroups && hasNextPage && Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
            if (fetchNextPage) fetchNextPage();
        }
    };

    return (
        <div
            className='participants-list following-detail-modal-scrollbar'
            style={{
                height: `200px`,
                marginBottom: '10px',
                overflow: 'hidden',
                overflowY: 'scroll',
            }}
            onScroll={pageScroll}
        >
            {userGroups && userGroups[0]?.data?.length === 0 ? (
                <p className='m-0 mt-2 w-100 d-flex justify-content-center'>{t('connect.noResultsFound')}</p>
            ) : (
                <>{renderParticipantList}</>
            )}
            {fetchingUserGroups && <SpinnerLoad className='w-100 d-flex align-items-center justify-content-center mt-3' size={50} />}
        </div>
    );
};

export default ThreadListParticipants;
