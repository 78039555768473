export default {
    "award": "{{text, en-handle-an}}",
    "awards": "Galardones",
    "send": "Enviar {{text, en-handle-an}}",
    "all": "Todos",
    "inbox": "Recibidos",
    "sent": "Enviados",
    "team": "Equipo",
    "choose": "Elige",
    "toggleManager": "Alternar mensajes del gerente",
    "manager": "Gerente",
    "received": "recibidos {{text, en-handle-an}}",
    "from": "de",
    "message": "Mensaje",
    "managerMessage": "Mensaje al gerente",
    "setVisibility": "Configurar visibilidad",
    "showInFeed": "Mostrar en las noticias",
    "onlyRecipients": "Solo visible para destinatarios",
    "recipients": "Destinatarios",
    "for": "por",
    "messagePlaceholder": "Escriba su mensaje aquí",
    "thankYou": "Gracias"
};