import React, { FunctionComponent, useState } from 'react';
import '../customLayout.scss';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { ContentData } from '~/types/types';
import { AnchorWrapper } from '../../anchorWrapper/anchorWrapper';
import { IconText } from '../../iconText/IconText';
import { TranslateBtnWrapper } from '../../translateBtnWrapper/translateBtnWrapper';
import { Typography, Box, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

type FolderTileProps = {
    content: ContentData;
    handleTranslateFolder: (id: number, value: boolean) => void;
    translatingFolder: boolean;
    clickedTranslateFolder: { [key: number]: boolean | null };
    translatedFolder: { [key: number]: { translatedText: { catName: string; subtitle: string } } };
};

const FolderTile: FunctionComponent<FolderTileProps> = ({
    content,
    handleTranslateFolder,
    translatingFolder,
    clickedTranslateFolder,
    translatedFolder,
}) => {
    const { t } = useTranslation();

    return (
        <Card
            id={`folder_${content.id}`}
            data-testid='folder-tile-card'
            style={{
                borderRadius: 10,
                overflow: 'hidden',
            }}
            key={content.id}
        >
            <AnchorWrapper
                anchorEl={false}
                linkProps={{
                    to:
                        content.web_layout === 'list'
                            ? `/list/category/${content.id}`
                            : content.web_layout === 'tile'
                            ? `/tile/category/${content.id}`
                            : `/custom/category/${content.id}`,
                    className: 'react-router-link d-flex flex-column h-100',
                }}
            >
                <Box sx={{ aspectRatio: '16/9', maxWidth: '400px' }}>
                    <img
                        data-testid='content-image'
                        className='card-img-top preview-image'
                        src={content.image}
                        alt='tile-card'
                        height={'100%'}
                        width={'100%'}
                    />
                </Box>
                <CardBody>
                    <CardTitle tag='h4'>
                        <strong data-testid='content-title' className='font-black' data-cy='title-text'>
                            {translatingFolder ? (
                                <Skeleton variant='text' width={'50%'} height={'20px'} />
                            ) : clickedTranslateFolder[content.id] ? (
                                translatedFolder[content.id]?.translatedText?.catName || content.title
                            ) : (
                                content.title
                            )}
                        </strong>
                    </CardTitle>
                    <div style={{ marginBottom: '10px' }}>
                        <i className='fas fa-folder-open fa-2x mb-20 title-tile font-black' title='folder-tile-icon' />
                    </div>
                    <CardText>
                        <small className='font-black' data-testid='content-subtitle'>
                            {translatingFolder ? (
                                <Skeleton variant='text' width={'50%'} height={'20px'} />
                            ) : clickedTranslateFolder[content.id] ? (
                                translatedFolder[content.id]?.translatedText?.subtitle || content.subtitle
                            ) : (
                                content.subtitle
                            )}
                        </small>
                    </CardText>
                </CardBody>
                <TranslateBtnWrapper locale={clickedTranslateFolder?.[content.id] ? 'translated' : content.locale}>
                    <div className='social-bar'>
                        <div className='social-bar-wrapper justify-content-end'>
                            <IconText
                                icon='fa-regular fa-globe'
                                text={''}
                                IconTextContainerSx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}
                                handleClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleTranslateFolder(content.id, clickedTranslateFolder[content.id] ? false : true);
                                }}
                            >
                                <Typography>{clickedTranslateFolder[content.id] ? t('general.seeOriginal') : t('general.translate')}</Typography>
                            </IconText>
                        </div>
                    </div>
                </TranslateBtnWrapper>
            </AnchorWrapper>
        </Card>
    );
};

export default FolderTile;
