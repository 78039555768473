import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import { FeedItem, TYBrandingData, FeedPostActionTypes } from '../../../../types/types';
import FeedPostActions from './socialPost/feedPostActions';
import { useTranslateQuery } from '../../../hooks/query/useTranslateQuery';
import { useSelector } from 'react-redux';

import ItemHeader from './itemHeader';
import { Box, Skeleton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { handleTranslationError } from '../../../utils/translationErrorHandle';

type TyCardsItemTypeProps = {
    item: FeedItem;
    isLastItem?: boolean;
    tyBranding: TYBrandingData;
};

const TyCardsItemType: FunctionComponent<TyCardsItemTypeProps> = ({ item, tyBranding, isLastItem }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const features = useSelector((state: any) => state.app.features);
    const dispatch = useDispatch();
    const displayTranslateBtn = features?.translations?.user_content;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [dotsModalOpen, setDotsOpen] = useState<boolean>(false);
    const [clickedTranslateAward, setClickedTranslateAward] = useState<{ [key: number]: boolean | null }>({});
    const [translatedAward, setTranslatedAward] = useState<{
        [key: number]: {
            originalText: {
                message: string;
                manager_message?: string;
            };
            translatedText: {
                message: string;
                manager_message?: string;
            };
            originalImageTitle: string;
            translatedImageTitle: string;
        };
    }>({});
    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const [awardToTranslateID, setAwardToTranslateID] = useState<number | null>(null);
    const { isLoading: translatingAward } = useTranslateQuery(
        awardToTranslateID,
        'thankyoucard',
        languagetoTranslate,
        (response?: {
            data?: {
                translated?: { message: string; manager_message?: string };
                original: { message: string; manager_message?: string };
                related_translations?: { image: { translated: { title: string }; original: { title: string } } };
            };
        }) => {
            if (!response?.data?.translated || !awardToTranslateID) return;
            const translated = response.data.translated;
            const original = response.data.original;
            const originalImageTitle = response.data.related_translations?.image?.original;
            const translatedImageTitle = response.data.related_translations?.image?.translated;
            setTranslatedAward({
                [awardToTranslateID]: {
                    originalText: { message: original.message, manager_message: original.manager_message },
                    translatedText: { message: translated.message, manager_message: translated.manager_message },
                    originalImageTitle: originalImageTitle?.title || '',
                    translatedImageTitle: translatedImageTitle?.title || '',
                },
            });
            setAwardToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: awardToTranslateID,
                setTranslateID: setAwardToTranslateID,
                setClickedTranslate: setClickedTranslateAward,
                dispatch,
                t,
            });
        }
    );
    const displayEllipsis =
        displayTranslateBtn && item.model.locale && (item.model.locale !== languagetoTranslate || clickedTranslateAward?.[item.model.id]);

    const handleTranslate = (id: number, translate: boolean) => {
        setClickedTranslateAward({ ...clickedTranslateAward, [id]: translate });
        if (!translate) {
            setAnchorEl(null);
            setDotsOpen(false);
            return;
        }
        setAwardToTranslateID(id);
        setAnchorEl(null);
        setDotsOpen(false);
    };

    const openFeedPostActions = Boolean(anchorEl);

    const tyCardNavigation = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/awards/${item.model.id}`);
    };

    const navigateToContact = (e: any, contactId: number) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(`/connect/people/${contactId}`);
    };

    const getIconCallback = (e: any) => {
        if (dotsModalOpen) {
            setDotsOpen(false);
        } else {
            setAnchorEl(e.currentTarget);
            setDotsOpen(true);
        }
    };

    return (
        <Box className={`c-pointer d-block text-decoration-none ${isLastItem ? '' : 'mb-2'}`} data-testid='ty-card-link' onClick={tyCardNavigation}>
            <Card
                className={`p-3 pt-1 item-container ${isLastItem ? '' : 'mb-3'}`}
                style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)', borderRadius: '10px' }}
                data-testid='ty-card-item'
            >
                <div className='c-pointer mb-2' onClick={tyCardNavigation} data-testid='ty-card-item-header'>
                    <ItemHeader
                        imgUrl={item.model.sender.image_url}
                        headText={item.model.sender.full_name}
                        jobTitle={item.model.sender.jobtitle}
                        iconClass={displayEllipsis && 'fal fa-ellipsis'}
                        time={item.model.created_at_humans || ''}
                        contact_id={item.model.sender.contact_id || ''}
                        iconClickCallback={getIconCallback}
                    />
                    <FeedPostActions
                        item={item}
                        removeItem={() => {}}
                        updateItem={() => {}}
                        removeContentByUser={() => {}}
                        canDeletePost={false}
                        clickedTranslateTextObj={clickedTranslateAward}
                        handleTranslate={handleTranslate}
                        open={openFeedPostActions}
                        type={FeedPostActionTypes.P2P}
                        anchorEl={anchorEl}
                        handleClose={() => {
                            setAnchorEl(null);
                            setDotsOpen(false);
                        }}
                    />
                </div>

                {item.model && item.model.image_url && (
                    <div className='media media-news mt-1'>
                        <div className='float-left me-3 d-flex justify-content-center align-items-flex-start w-auto h-auto'>
                            <img
                                src={item.model.image_url}
                                alt='Image'
                                className='media-object img-rad'
                                style={{ maxHeight: '200px' }}
                                data-testid='ty-card-img'
                            />
                        </div>
                        <div className='media-body clearfix ms-20'>
                            <h3 className='mb-2 mt-3 font-primary-text'>
                                {translatingAward ? (
                                    <Skeleton width={300} height={30} variant='text' />
                                ) : (
                                    <>
                                        {Array.isArray(item.model.recipients) ? (
                                            item.model.recipients.map((element: any) => {
                                                return (
                                                    <span
                                                        key={element.id}
                                                        className='sender-name c-pointer'
                                                        onClick={(e: any) => navigateToContact(e, element.contact_id)}
                                                        data-testid='ty-card-recipients-name'
                                                    >
                                                        @{element.full_name || ''}&nbsp;
                                                    </span>
                                                );
                                            })
                                        ) : (
                                            <span
                                                className='sender-name c-pointer'
                                                onClick={(e: any) => navigateToContact(e, item.model.recipients.contact_id)}
                                                data-testid='ty-card-recipient-name'
                                            >
                                                @{item.model.recipients.full_name || ''}&nbsp;
                                            </span>
                                        )}
                                        {t('feed.received', { text: item.model.tyc_label })} {t('awards.for')}&nbsp;
                                        {clickedTranslateAward[item.model.id]
                                            ? translatedAward[item.model.id]?.translatedImageTitle
                                            : item.model.image_title}
                                    </>
                                )}
                            </h3>
                            <span className='m0 fw-normal font-secondary-text' data-testid='ty-card-message'>
                                {translatingAward ? (
                                    <Skeleton width={300} height={100} variant='text' />
                                ) : clickedTranslateAward[item.model.id] ? (
                                    translatedAward[item.model.id]?.translatedText.message
                                ) : (
                                    item.model.message
                                )}
                            </span>
                            {item.model.manager_name !== '' ? (
                                <div className={'mt-3'}>
                                    <h3 className={'font-primary-text'}>{t('feed.messageToManager')}</h3>
                                    <div className='mt-2 border-bottom' />
                                    <div className={'mt-2'}>
                                        <span className='sender-name font-secondary-text' data-testid='ty-card-manager-message'>
                                            {translatingAward ? (
                                                <Skeleton width={300} height={100} variant='rectangular' />
                                            ) : clickedTranslateAward[item.model.id] ? (
                                                translatedAward[item.model.id]?.translatedText.manager_message
                                            ) : (
                                                item.model.manager_message
                                            )}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                )}
            </Card>
        </Box>
    );
};

export default TyCardsItemType;
