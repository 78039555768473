export default {
  "locations": "Localizações",
  "contacts": "Contactos",
  "favourites": "Favoritos",
  "all": "Todos",
  "noDataFound": "Nenhum dado encontrado",
  "thereAreNoPost": "Não há postagens sociais, comece a postar para vê-las aqui!",
  "noOrgChart": "Não há organograma associado a este usuário.",
  "awarded": "Premiado/a",
  "expires": "Expira",
  "contactDetails": "Dados de Contacto",
  "followers": "Seguidores",
  "following": "Seguindo",
  "follow": "Seguir",
  "unfollow": "Deixar de seguir",
  "unsnooze": "Reativar",
  "snooze": "Domar",
  "1Hour": "1 Hora",
  "8Hours": "8 Horas",
  "24Hours": "24 Horas",
  "1Week": "1 Semana",
  "1Month": "1 Mês",
  "1Year": "1 Ano",
  "message": "Mensagem",
  "details": "Detalhes",
  "posts": "Postagens",
  "organisation": "organização",
  "profileImageHeader": "Imagem de perfil",
  "headerImageHeader": "Imagem do cabeçalho do perfil",
  "uploadFile": "Subir arquivo",
  "zoom": "Ampliação (%): ",
  "rotate": "Rodar: ",
  "done": "Feito",
  "ratio": "Manter proporção  ",
  "on": "Permitir",
  "off": "Desativar",
  "search": "Pesquisar",
  "noFavourites": "Comece a adicionar contatos favoritos!",
  "manager": "Gestor",
  "location": "Localização",
  "primaryEmail": "Email Principal",
  "dob": "Data de Nascimento",
  "start": "Data de Início",
  "bio": "Biografia",
  "chart": "Quadro organizacional",
  "close": "Fechar",
  "address": "Endereço",
  "description": "Descrição",
  "fName": "Nome",
  "lName": "Sobrenomes",
  "title": "Cargo",
  "department": "Departamento",
  "save": "Salve",
  "error": "Houve um erro,",
  "errorLong": "por favor tente novamente mais tarde.",
  "success": "Sucesso!",
  "successLong": "Detalhes salvos corretamente",
  "email": "Email",
  "badge": "Distintivos",
  "skills": "Habilidades",
  "phone": "Telefone",
  "hobbies": "Hobbies",
  "clearAll": "Limpar tudo",
  "quickFilters": "Filtros rápidos",
  "customSearchTextPlaceholder": "Comece a digitar e aperte enter...",
  "working hours": "Jornada de trabalho",
  "fun facts": "curiosidades",
  "noResultsFound": "Nenhum resultado encontrado",
  "charsLimitMsg": "Digite 3 ou mais caracteres para pesquisar",
  "errorLoadingFollowers": "Erro ao carregar seguidores",
  "errorLoadingFollowings": "Erro ao carregar seguidores",
  "followsYou": "Segue você",
  "searchContacts": "Pesquisar contatos",
  "searchLocations": "Pesquisar locais",
  "allContacts": "Todos os contatos",
  "edit": "Editar",
  "people": "Pessoas",
  "directory": "Diretório",
  "noSearchResultsContactDescription": "Ops! Parece que não conseguimos encontrar nenhum contato correspondente à sua pesquisa.",
  "noContactResultsDescription": "Ops! Parece que não conseguimos encontrar nenhum contato.",
  "noSearchResultsFavouriteDescription": "Ops! Parece que não conseguimos encontrar nenhum contato favorito correspondente à sua pesquisa.",
  "noFavouriteResultsDescription": "Ops! Nenhum contato favorito encontrado. Adicione contatos aos seus favoritos para vê-los aqui.",
  "noSearchResultsLocationDescription": "Ops! Parece que não encontramos nenhuma localização correspondente à sua pesquisa.",
  "noLocationResultsDescription": "Ops! Parece que não encontramos nenhuma localização.",
  "clearFilters": "Limpar filtros",
  "filters": "Filtros",
  "noLocationPermission": "Ops! Parece que você não tem permissão para ver isso. Tente outro local.",
  "groups": "Grupos",
  "joinGroup": "Entrar no grupo",
  "leaveGroup": "Sair do grupo",
  "mandatoryGroup": "Grupo obrigatório",
  "noSearchResultsGroupsDescription": "Ops! Parece que não conseguimos encontrar grupos que correspondam à sua pesquisa.",
  "noGroupsResultsDescription": "Ops! Parece que não conseguimos encontrar grupos.",
  "groupMembers": "Membros do grupo",
  "noGroupMembers": "Ops! parece que ainda não há membros neste grupo.",
  "cannotLeaveGroupTooltip": "Você foi adicionado automaticamente a este grupo por um administrador. Se você deseja sair, entre em contato com um administrador do grupo para obter assistência.",
  "restrictPostingGroup": "Você não pode postar neste grupo"
};