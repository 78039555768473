import '../customLayout.scss';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardGroup, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import FolderTile from './folderTile';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoad from '../../common/spinnerLoad/spinnerLoad';
import { ContentData, ContentType } from '../../../../types/types';
import Breadcrumbs from '../breadcrumbs';
import FilterPanel, { Sort } from '../../../components/customLayout/filterPanel';
import customLayoutService from '../../../services/customLayoutService';
import { AxiosResponse } from 'axios';
import { Box, Skeleton, Typography } from '@mui/material';
import { LikeBtnHeart, LikeBtnHeartFilled, ShareArrowIcon, CommentCloudIcon } from '../../../../icons/index';
import { TranslateBtnWrapper } from '../../translateBtnWrapper/translateBtnWrapper';
import { IconText } from '../../../components/iconText/IconText';
import { useTranslation } from 'react-i18next';
import { useTranslateQuery } from '../../../hooks/query/useTranslateQuery';
import { cloneDeep } from 'lodash';
import { SearchHook, useSearchHook } from '../../../hooks/useSearchHook';
import { useDebounce } from '../../../hooks/useDebounceHook';
import { useDispatch } from 'react-redux';
import { handleTranslationError } from '../../../utils/translationErrorHandle';

type PageTileProps = {
    contentType: ContentType;
    contentId: number;
    showFilter: boolean;
    showSearch: boolean;
    showSort: boolean;
    fixedContainer?: boolean;
};

const PageTile: FunctionComponent<PageTileProps> = ({ contentType, contentId, showFilter, showSearch, showSort, fixedContainer }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filterByName, setFilterByName] = useState<string>();
    const [sort, setSort] = useState<Sort>(Sort.default);
    const initialContentData = useRef<ContentData[]>([]);
    const [contentData, setContentData] = useState<ContentData[]>([]);
    const [hasMoreContent, setHasMoreContent] = useState<boolean>(false);
    const { searchQuery, handleChange: handleSearch, handleSearchReset: handleSearchReset }: SearchHook = useSearchHook('');
    const { debouncedValue: debouncedSearchValue } = useDebounce(searchQuery);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [clickedTranslateTile, setClickedTranslateTile] = useState<{ [key: number]: boolean | null }>({});
    const [translatedTile, setTranslatedTile] = useState<{
        [key: number]: {
            originalText: {
                title: string;
                preview_text: string;
            };
            translatedText: {
                title: string;
                preview_text: string;
            };
        };
    }>({});
    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const [tileToTranslateID, setTileToTranslateID] = useState<number | null>(null);

    const { isLoading: translatingTile } = useTranslateQuery(
        tileToTranslateID,
        'page',
        languagetoTranslate,
        (response?: { data?: { translated?: { title: string; preview_text: string } } }) => {
            if (!response?.data?.translated || !tileToTranslateID) return;
            const translatedObj = response.data.translated;
            const updatedContentData = contentData.map((content) =>
                content.contentPage.id === tileToTranslateID
                    ? {
                          ...content,
                          contentPage: {
                              ...content.contentPage,
                              ...translatedObj,
                          },
                      }
                    : content
            );
            const originalContent = contentData.find((content) => content.contentPage.id === tileToTranslateID)?.contentPage ?? {
                title: '',
                preview_text: '',
            };
            setTranslatedTile((prev) => ({
                ...prev,
                [tileToTranslateID]: {
                    originalText: originalContent,
                    translatedText: translatedObj,
                },
            }));
            setContentData(updatedContentData);
            setTileToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: tileToTranslateID,
                setTranslateID: setTileToTranslateID,
                setClickedTranslate: setClickedTranslateTile,
                dispatch,
                t,
            });
        }
    );

    const loadContentData = (currentPage: number, searchQuery: string) => {
        if (contentData) {
            customLayoutService.getContent(contentType, contentId, currentPage, searchQuery, (response: AxiosResponse) => {
                setHasMoreContent(response.data.links.next != null);

                if (contentData.length === 0) {
                    initialContentData.current = response.data.data;
                }
                if (currentPage === 1) {
                    setContentData(response.data.data);
                } else {
                    setContentData((data) => [...data, ...response.data.data]);
                }
            });
        }
    };

    const handleTranslate = (tileID: number, translateTile: boolean) => {
        setClickedTranslateTile((prev) => ({ ...prev, [tileID]: translateTile }));
        if (!translateTile) {
            const updatedContentData = cloneDeep(contentData);
            updatedContentData.forEach((content) => {
                if (content.contentPage.id === tileID) {
                    content.contentPage.title = translatedTile[tileID].originalText.title;
                    content.contentPage.preview_text = translatedTile[tileID].originalText.preview_text;
                }
            });
            setContentData(updatedContentData);
            return;
        }
        setTileToTranslateID(tileID);
    };

    useEffect(() => {
        setContentData([]);
        setHasMoreContent(false);
        setCurrentPage(1);
        loadContentData(1, debouncedSearchValue);
    }, [contentId]);

    useEffect(() => {
        if (currentPage > 1) {
            loadContentData(currentPage, debouncedSearchValue);
        }
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        loadContentData(1, debouncedSearchValue);
    }, [debouncedSearchValue]);

    const getFilteredContent = (data: ContentData[], sort: Sort | undefined, filterByName: string | undefined) => {
        let result = [...data];
        if (sort !== undefined) {
            return result
                .sort((a, b) => {
                    switch (sort) {
                        case Sort.mostViewed:
                            return b.counts.views - a.counts.views;
                        case Sort.mostLiked:
                            return b.counts.likes - a.counts.likes;
                        case Sort.oldestDate:
                            return a.timestamp - b.timestamp;
                        case Sort.newestDate:
                            return b.timestamp - a.timestamp;
                        case Sort.alphabeticalAscending:
                            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                        case Sort.alphabeticalDescending:
                            return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
                        default:
                            return 0;
                    }
                })
                .filter((content) => {
                    return filterByName ? content.contentPage?.publisher?.name === filterByName : true;
                });
        }
    };

    const filteredContent = getFilteredContent(contentData, sort, filterByName) || [];

    const handleOnClickResetAliasFilter = () => {
        setFilterByName('');
    };

    const handleOnClickFilterByName = (name: string) => {
        setFilterByName(name);
    };

    const handleOnClickFilterMostViewed = () => {
        setSort(Sort.mostViewed);
    };

    const handleOnClickFilterMostLiked = () => {
        setSort(Sort.mostLiked);
    };

    const handleOnClickFilterOldestFirst = () => {
        setSort(Sort.oldestDate);
    };

    const handleOnClickFilterNewestFirst = () => {
        setSort(Sort.newestDate);
    };

    const handleOnClickFilterAlphabetically = (isAscending: boolean) => {
        setSort(isAscending ? Sort.alphabeticalAscending : Sort.alphabeticalDescending);
    };

    const handleOnClickFilterDefault = () => {
        setSort(Sort.default);
    };

    const customPageScroll = (e: any) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (Math.round(scrollTop + clientHeight) >= scrollHeight) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const [clickedTranslateFolder, setClickedTranslateFolder] = useState<{ [key: number]: boolean | null }>({});
    const [translatedFolder, setTranslatedFolder] = useState<{
        [key: number]: {
            originalText: {
                catName: string;
                subtitle: string;
            };
            translatedText: {
                catName: string;
                subtitle: string;
            };
        };
    }>({});
    const [folderToTranslateID, setFolderToTranslateID] = useState<number | null>(null);

    const { isLoading: translatingFolder } = useTranslateQuery(
        folderToTranslateID,
        'category',
        languagetoTranslate,
        (response?: { data?: { translated?: { catName: string; subtitle: string }; original: { catName: string; subtitle: string } } }) => {
            if (!response?.data?.translated || !folderToTranslateID) return;
            const translatedObj = response.data.translated;
            const originalContent = response.data.original;
            setTranslatedFolder((prev) => ({
                ...prev,
                [folderToTranslateID as number]: {
                    originalText: originalContent,
                    translatedText: translatedObj,
                },
            }));
            setFolderToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: folderToTranslateID,
                setTranslateID: setFolderToTranslateID,
                setClickedTranslate: setClickedTranslateFolder,
                dispatch,
                t,
            });
        }
    );

    const handleTranslateFolder = (id: number, translate: boolean) => {
        setClickedTranslateFolder((prev) => ({ ...prev, [id]: translate }));
        if (!translate) return;
        setFolderToTranslateID(id);
    };

    const RenderCard = () => {
        return (
            <CardGroup>
                {filteredContent &&
                    filteredContent.map((content: any) => {
                        const firstAlias = content.contentPage.publisher;
                        return (
                            <React.Fragment key={content.id}>
                                {content.type === 'PAGE' ? (
                                    <Card
                                        id={`page_${content.id}`}
                                        data-testid='page-tile-card'
                                        style={{
                                            borderRadius: 10,
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <a
                                            href={
                                                !content.contentPage.allow_likes &&
                                                !content.contentPage.allow_comments &&
                                                !content.contentPage.allow_share &&
                                                (content.type_name === 'URL' || content.type_name === 'SSO')
                                                    ? content.contents
                                                    : `/page/${content.contentPage.id}`
                                            }
                                            className='react-router-link d-flex flex-column h-100'
                                        >
                                            <Box sx={{ aspectRatio: '16/9', maxWidth: '400px' }}>
                                                <img
                                                    className='card-img-top preview-image'
                                                    src={content.image}
                                                    alt='tile-card'
                                                    height={'100%'}
                                                    width={'100%'}
                                                />
                                            </Box>
                                            <CardBody
                                                style={{
                                                    padding: '1rem 1rem 0 1rem',
                                                }}
                                            >
                                                <CardTitle className='page-tile-title mb-2' tag='h4'>
                                                    {translatingTile && content.contentPage.id === tileToTranslateID ? (
                                                        <Skeleton variant='text' width={'75%'} height={20} />
                                                    ) : (
                                                        <strong>{content.contentPage.title}</strong>
                                                    )}
                                                </CardTitle>
                                                <CardSubtitle className='mb-2'>
                                                    <small className='font-black'>{content.contentPage.published_at}</small>
                                                </CardSubtitle>
                                                <CardText className='mb-2 card-text-trucate'>
                                                    {translatingTile && content.contentPage.id === tileToTranslateID ? (
                                                        <Skeleton variant='text' width={'75%'} height={30} />
                                                    ) : (
                                                        <Typography
                                                            dangerouslySetInnerHTML={{
                                                                __html: content.contentPage.preview_text,
                                                            }}
                                                        />
                                                    )}
                                                </CardText>
                                            </CardBody>
                                            {firstAlias && (
                                                <Box
                                                    className='mb-2'
                                                    sx={{
                                                        p: '0 1rem',
                                                    }}
                                                >
                                                    <img src={firstAlias.image} alt='alias-group' className='search-card-profile-image' />
                                                    <strong className='font-black'>{firstAlias.name}</strong>
                                                </Box>
                                            )}
                                            <div className='social-bar'>
                                                <div className='social-bar-wrapper'>
                                                    {content.contentPage.allow_likes && (
                                                        <div className='font-black d-flex align-items-center'>
                                                            {content.contentPage.is_liked ? (
                                                                <LikeBtnHeartFilled height={16} width={16} />
                                                            ) : (
                                                                <LikeBtnHeart height={16} width={16} />
                                                            )}
                                                            &nbsp;
                                                            {content.counts.likes}
                                                        </div>
                                                    )}
                                                    {content.contentPage.allow_comments && (
                                                        <div className='font-black d-flex align-items-center'>
                                                            <CommentCloudIcon height={16} width={16} />
                                                            &nbsp;
                                                            {content.counts.comments}
                                                        </div>
                                                    )}
                                                    <TranslateBtnWrapper
                                                        locale={
                                                            clickedTranslateTile?.[content.contentPage.id] ? 'translated' : content.contentPage.locale
                                                        }
                                                    >
                                                        <IconText
                                                            icon='fa-regular fa-globe'
                                                            text={''}
                                                            IconTextContainerSx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '5px',
                                                            }}
                                                            handleClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleTranslate(
                                                                    content.contentPage.id,
                                                                    clickedTranslateTile[content.contentPage.id] ? false : true
                                                                );
                                                            }}
                                                        >
                                                            <Typography>
                                                                {clickedTranslateTile[content.contentPage.id]
                                                                    ? t('general.seeOriginal')
                                                                    : t('general.translate')}
                                                            </Typography>
                                                        </IconText>
                                                    </TranslateBtnWrapper>
                                                    {content.contentPage.allow_share && (
                                                        <div className='font-black d-flex align-items-center'>
                                                            <ShareArrowIcon height={16} width={16} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </a>
                                    </Card>
                                ) : (
                                    <FolderTile
                                        content={content}
                                        handleTranslateFolder={handleTranslateFolder}
                                        translatingFolder={translatingFolder}
                                        clickedTranslateFolder={clickedTranslateFolder}
                                        translatedFolder={translatedFolder}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
            </CardGroup>
        );
    };

    return (
        <div data-testid='page-tile' className='custom-layout'>
            {fixedContainer ? (
                <Box sx={{ height: '100%', position: 'relative', overflow: 'auto', margin: '0 auto' }} onScroll={customPageScroll}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: { xs: 'stretch', md: 'center' },
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <Breadcrumbs contentType={contentType} contentId={contentId} />
                        <FilterPanel
                            searchQuery={searchQuery}
                            showSearch={showSearch}
                            showFilter={showFilter}
                            showSort={showSort}
                            handleSearch={handleSearch}
                            handleSearchReset={handleSearchReset}
                            handleOnClickResetAliasFilter={handleOnClickResetAliasFilter}
                            handleOnClickFilterByName={handleOnClickFilterByName}
                            handleOnClickFilterMostViewed={handleOnClickFilterMostViewed}
                            handleOnClickFilterMostLiked={handleOnClickFilterMostLiked}
                            handleOnClickFilterOldestFirst={handleOnClickFilterOldestFirst}
                            handleOnClickFilterNewestFirst={handleOnClickFilterNewestFirst}
                            handleOnClickFilterAlphabetically={handleOnClickFilterAlphabetically}
                            handleOnClickFilterDefault={handleOnClickFilterDefault}
                            contentFiltered={contentData}
                        />
                    </Box>
                    <RenderCard />
                </Box>
            ) : (
                <InfiniteScroll
                    dataLength={filteredContent.length}
                    next={() => {
                        setCurrentPage(currentPage + 1);
                    }}
                    hasMore={hasMoreContent}
                    scrollableTarget='page-frame-contents'
                    loader={<SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: { xs: 'stretch', md: 'center' },
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <Breadcrumbs contentType={contentType} contentId={contentId} />
                        <FilterPanel
                            searchQuery={searchQuery}
                            showSearch={showSearch}
                            showFilter={showFilter}
                            showSort={showSort}
                            handleSearch={handleSearch}
                            handleSearchReset={handleSearchReset}
                            handleOnClickResetAliasFilter={handleOnClickResetAliasFilter}
                            handleOnClickFilterByName={handleOnClickFilterByName}
                            handleOnClickFilterMostViewed={handleOnClickFilterMostViewed}
                            handleOnClickFilterMostLiked={handleOnClickFilterMostLiked}
                            handleOnClickFilterOldestFirst={handleOnClickFilterOldestFirst}
                            handleOnClickFilterNewestFirst={handleOnClickFilterNewestFirst}
                            handleOnClickFilterAlphabetically={handleOnClickFilterAlphabetically}
                            handleOnClickFilterDefault={handleOnClickFilterDefault}
                            contentFiltered={contentData}
                        />
                    </Box>
                    <RenderCard />
                </InfiniteScroll>
            )}
        </div>
    );
};

export default PageTile;
