import translationDE from './de/translation';
import translationEN from './en/translation';
import translationES from './es/translation';
import translationFR from './fr/translation';
import translationIT from './it/translation';
import translationNL from './nl/translation';
import translationPT from './pt/translation';

export const Languages = ['de', 'en', 'es', 'fr', 'it', 'nl', 'pt'];



export default {
    'translations': {
        'de': {translation: translationDE},
        'en': {translation: translationEN},
        'es': {translation: translationES},
        'fr': {translation: translationFR},
        'it': {translation: translationIT},
        'nl': {translation: translationNL},
        'pt': {translation: translationPT}
    },
    'languages': [
        {
            code: 'de',
            name: 'Deutsch'
        },
        {
            code: 'en',
            name: 'English'
        },
        {
            code: 'es',
            name: 'Español'
        },
        {
            code: 'fr',
            name: 'Français'
        },
        {
            code: 'it',
            name: 'Italiano'
        },
        {
            code: 'nl',
            name: 'Nederlands'
        },
        {
            code: 'pt',
            name: 'Português'
        }
    ]
}
