import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Paper, Typography, Box, Avatar } from '@mui/material';
import { ParticipantUserInterface } from '../../../types/interface/ThreadList';
import { SearchField } from '../../components/searchField/searchField';
import { useTranslation } from 'react-i18next';
import { ThreadListThreads } from '../ThreadListThreads/ThreadListThreads';
import { ThreadListContacts } from '../ThreadListContacts/ThreadListContacts';
import { IconCard } from '../../components/IconCard/iconCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { updateGroupThreadHook, useNewGroupThread } from '../../hooks/useNewGroupThreadHook';
import { useNavigate } from 'react-router-dom';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerLoad from '../../components/common/spinnerLoad/spinnerLoad';
import { GroupDetailsType } from '../../../types/interface/Thread';
import { isWhite } from '../../../web/utils/colourCheck';
import { ReactComponent as CircleXMarkIcon } from '../../../icons/circle-xmark-solid.svg';

const initialGroupDetails = {
    name: '',
    description: '',
    type: 'multiple',
};

export type ThreadListProps = {
    data: any;
    handleSearch?: React.ChangeEventHandler<HTMLInputElement>;
    handleResetThreadSearch?: ((value: string | number | readonly string[] | undefined) => void) | undefined;
    handleResetContactSearch?: ((value: string | number | readonly string[] | undefined) => void) | undefined;
    loading?: boolean;
    selectedThread?: string;
    searchQuery: string | number | readonly string[] | undefined;
    handleKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
    setDisplayCreateChat: React.Dispatch<React.SetStateAction<boolean>>;
    setDisplayArchivedChat: React.Dispatch<React.SetStateAction<boolean>>;
    displayCreateChat: boolean;
    showCreateChatBtn: boolean;
    displayArchivedChat: boolean;
    isGroupSetting: boolean;
    displayCreateNewGroup: boolean;
    groupEditMode: boolean;
    groupEditDetails: GroupDetailsType;
    fetchNextPage: () => void;
    fetchingNextPage: boolean;
    setIsResetThreadList: React.Dispatch<React.SetStateAction<boolean>>;
    setDisplayCreateNewGroup: React.Dispatch<React.SetStateAction<boolean>>;
    setIsGroupSetting: React.Dispatch<React.SetStateAction<boolean>>;
    refreshThreadDetail: (isLeaveGroup?: boolean) => void;
    setGroupEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    handleArchiveThread: (threadId: string | undefined) => void;
};

export const ThreadList = (props: ThreadListProps) => {
    const {
        data,
        handleSearch,
        handleResetThreadSearch,
        handleResetContactSearch,
        loading,
        selectedThread,
        searchQuery,
        handleKeyDown,
        displayCreateChat,
        setDisplayCreateChat,
        showCreateChatBtn,
        setDisplayArchivedChat,
        displayArchivedChat,
        isGroupSetting,
        displayCreateNewGroup,
        groupEditMode,
        groupEditDetails,
        fetchNextPage,
        fetchingNextPage,
        setIsGroupSetting,
        setDisplayCreateNewGroup,
        setIsResetThreadList,
        refreshThreadDetail,
        setGroupEditMode,
        handleArchiveThread,
    } = props;
    const dispatch = useDispatch();
    const [displaySettings, setDisplaySettings] = useState<boolean>(false);
    const [participantUsers, setParticipantUsers] = useState<ParticipantUserInterface[]>([]);
    const [filesInfo, setFilesInfo] = useState<File[]>([]);
    const [base64ImgURL, setBase64ImgURL] = useState<string | undefined>();
    const [groupDetails, setGroupDetails] = useState<{ name: string; description: string; type: string }>(initialGroupDetails);
    const [containerHeight, setContainerHeight] = useState<string>('');

    const tenantBranding = useSelector((state: any) => state.app.branding);
    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const newChatBtnBg = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    const newChatBtnFg = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const fileButton = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (groupEditMode) {
            setGroupDetails({
                name: groupEditDetails.title,
                description: groupEditDetails.description,
                type: 'multiple',
            });
            setBase64ImgURL(groupEditDetails.image as string);
        }
    }, [groupEditMode]);

    const { mutate: newGroupThreadMutate, isLoading } = useNewGroupThread(
        (resp) => {
            setFilesInfo([]);
            setParticipantUsers([]);
            setGroupDetails(initialGroupDetails);
            setDisplayCreateNewGroup(false);
            setIsGroupSetting(false);
            setDisplayCreateChat(false);
            refreshThreadDetail();
            navigate(`/chat/${resp.data.data.id}`, {
                state: {
                    title: resp.data.data.title,
                    description: resp.data.data.description,
                    icon_url: resp.data.data.icon_url,
                },
            });
        },
        (error) => {
            let errorData = error.response.data;
            Object.keys(errorData).map((key) => {
                dispatch(
                    addNotification({
                        label: t('chat.newGroup'),
                        text: errorData[key],
                        type: 'danger',
                    })
                );
            });
        }
    );

    const { mutate: updateGroupThreadMutate } = updateGroupThreadHook(
        (resp) => {
            setFilesInfo([]);
            setGroupDetails(initialGroupDetails);
            setDisplayCreateChat(false);
            setIsGroupSetting(false);
            setGroupEditMode(false);
            refreshThreadDetail();
            navigate(`/chat/${resp.data.data.id}`, {
                state: {
                    title: resp.data.data.title,
                    description: resp.data.data.description,
                    icon_url: resp.data.data.icon_url,
                },
            });
        },
        (error) => {
            let errorData = error.response.data;
            Object.keys(errorData).map((key) => {
                dispatch(
                    addNotification({
                        label: t('chat.updateGroup'),
                        text: errorData[key],
                        type: 'danger',
                    })
                );
            });
        }
    );

    useEffect(() => {
        const calculateHeight = () => {
            let totalHeight = displayCreateNewGroup ? 139 : 239; // Fix Elements height
            let selectedParticipantSectionHeight = document.getElementById('selected-participant-section')?.offsetHeight; // Description box height
            let nextBtnSectionHeight = document.getElementById('next-btn-section')?.offsetHeight; // ThreadInfo avatar box height
            if (selectedParticipantSectionHeight) {
                totalHeight = totalHeight + selectedParticipantSectionHeight;
                if (nextBtnSectionHeight) {
                    totalHeight = totalHeight + nextBtnSectionHeight + 16; // 16px for margin bottom of next button section
                }
            }
            setContainerHeight(`calc(87vh - ${totalHeight}px)`);
        };
        window.addEventListener('resize', calculateHeight);
        calculateHeight();
        const selectedParticipantSection = document.getElementById('selected-participant-section');
        if (selectedParticipantSection) {
            selectedParticipantSection.scrollTop = selectedParticipantSection.scrollHeight;
        }
    }, [displayCreateNewGroup, participantUsers.length]);

    const removeParticipantUser = (userId: number) => {
        setParticipantUsers(participantUsers.filter((user) => user.id !== userId));
    };

    const renderParticipantUsers = useMemo(() => {
        return participantUsers.map((user: ParticipantUserInterface) => (
            <IconCard
                title={user.full_name}
                className='participant-users-section mt-1'
                titleClassName='participant-title mx-2'
                titleStyle={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                icon={<Avatar className='participant-user-avatar' src={user.image_url} alt='avatar' />}
                children={
                    <svg
                        data-testid={`user-${user.id}-remove-btn`}
                        className='c-pointer'
                        onClick={() => removeParticipantUser(user.id)}
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <g clip-path='url(#clip0_12_3132)'>
                            <path
                                d='M10.6665 5.33337C10.5415 5.20839 10.3719 5.13818 10.1951 5.13818C10.0184 5.13818 9.84882 5.20839 9.7238 5.33337L7.9998 7.05737L6.2758 5.33337C6.15007 5.21194 5.98167 5.14474 5.80687 5.14626C5.63207 5.14778 5.46486 5.21789 5.34126 5.3415C5.21765 5.4651 5.14754 5.63231 5.14602 5.80711C5.1445 5.98191 5.2117 6.15031 5.33314 6.27604L7.05714 8.00004L5.33314 9.72404C5.2117 9.84978 5.1445 10.0182 5.14602 10.193C5.14754 10.3678 5.21765 10.535 5.34126 10.6586C5.46486 10.7822 5.63207 10.8523 5.80687 10.8538C5.98167 10.8553 6.15007 10.7881 6.2758 10.6667L7.9998 8.94271L9.7238 10.6667C9.84954 10.7881 10.0179 10.8553 10.1927 10.8538C10.3675 10.8523 10.5347 10.7822 10.6583 10.6586C10.782 10.535 10.8521 10.3678 10.8536 10.193C10.8551 10.0182 10.7879 9.84978 10.6665 9.72404L8.94247 8.00004L10.6665 6.27604C10.7915 6.15102 10.8617 5.98148 10.8617 5.80471C10.8617 5.62793 10.7915 5.45839 10.6665 5.33337Z'
                                fill='black'
                            />
                            <path
                                d='M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0V0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.35361 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z'
                                fill='black'
                            />
                        </g>
                        <defs>
                            <clipPath id='clip0_12_3132'>
                                <rect width='16' height='16' fill='white' />
                            </clipPath>
                        </defs>
                    </svg>
                }
            />
        ));
    }, [participantUsers]);

    const handleImageUpload = (event: any) => {
        const file = event.target.files[0];
        setFilesInfo([file]);
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const base64Url = e?.target?.result; // Base64 data URL
                setBase64ImgURL(base64Url as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const triggerInputFile = () => {
        if (fileButton && fileButton.current) {
            fileButton.current.click();
        }
    };

    const getNewGroupDetail = () => {
        let formData = new FormData();
        formData.append('title', groupDetails.name);
        formData.append('description', groupDetails.description);
        if (filesInfo.length > 0) formData.append('image', filesInfo[0]);
        if (!groupEditMode) {
            formData.append('type', groupDetails.type);
            participantUsers.forEach((user, i) => {
                formData.append(`users[${i}]`, user.id.toString());
            });
        } else {
            formData.append('id', groupEditDetails.id as string);
        }
        return formData;
    };

    const renderCreateGroupDetail = () => (
        <Paper className='group-setting-container' data-testid='group-setting-container' elevation={3}>
            <Box sx={{ mb: '15px' }}>
                <Box sx={{ display: 'flex' }}>
                    <button
                        className='unstyled-btn'
                        onClick={() => {
                            setFilesInfo([]);
                            setGroupDetails(initialGroupDetails);
                            if (groupEditMode) {
                                setDisplayCreateChat(false);
                            } else {
                                setDisplayCreateChat(true);
                            }
                            setIsGroupSetting(false);
                            setGroupEditMode(false);
                            if (handleResetContactSearch) handleResetContactSearch('');
                        }}
                        style={{ lineHeight: '0', opacity: isLoading ? 0.5 : 1 }}
                        disabled={isLoading ? true : false}
                    >
                        <span className='fa-solid fa-angle-left' style={{ fontSize: '28px' }}></span>
                    </button>
                    <Typography className='thread-list-title' sx={{ ml: '10px' }}>
                        {t('chat.groupSettings')}
                    </Typography>
                </Box>
            </Box>
            <Box className='group-image-upload-section'>
                <input
                    type='file'
                    id='file'
                    name={'upfiles'}
                    ref={fileButton}
                    style={{ display: 'none' }}
                    accept='image/*'
                    onChange={handleImageUpload}
                    data-testid='img-upload-btn'
                />
                <div className='group-image-upload c-pointer' style={{ backgroundColor: newChatBtnBg }} onClick={triggerInputFile}>
                    {filesInfo.length === 0 && !groupEditMode ? (
                        <div className='w-100'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <g clip-path='url(#clip0_12_3532)'>
                                        <path
                                            d='M28.5 0H7.5C5.51161 0.00238179 3.60534 0.793323 2.19933 2.19933C0.793323 3.60534 0.00238179 5.51161 0 7.5L0 28.5C0.00238179 30.4884 0.793323 32.3947 2.19933 33.8007C3.60534 35.2067 5.51161 35.9976 7.5 36H28.5C30.4884 35.9976 32.3947 35.2067 33.8007 33.8007C35.2067 32.3947 35.9976 30.4884 36 28.5V7.5C35.9976 5.51161 35.2067 3.60534 33.8007 2.19933C32.3947 0.793323 30.4884 0.00238179 28.5 0V0ZM7.5 3H28.5C29.6935 3 30.8381 3.47411 31.682 4.31802C32.5259 5.16193 33 6.30653 33 7.5V28.5C32.9974 29.1682 32.8436 29.8272 32.55 30.4275L18.8055 16.683C18.109 15.9864 17.2822 15.4337 16.3721 15.0567C15.462 14.6797 14.4866 14.4856 13.5015 14.4856C12.5164 14.4856 11.541 14.6797 10.6309 15.0567C9.72085 15.4337 8.89397 15.9864 8.1975 16.683L3 21.879V7.5C3 6.30653 3.47411 5.16193 4.31802 4.31802C5.16193 3.47411 6.30653 3 7.5 3ZM7.5 33C6.30653 33 5.16193 32.5259 4.31802 31.682C3.47411 30.8381 3 29.6935 3 28.5V26.121L10.317 18.804C10.7349 18.3858 11.2311 18.0541 11.7773 17.8278C12.3234 17.6015 12.9088 17.485 13.5 17.485C14.0912 17.485 14.6766 17.6015 15.2227 17.8278C15.7689 18.0541 16.2651 18.3858 16.683 18.804L30.4275 32.55C29.8272 32.8436 29.1682 32.9974 28.5 33H7.5Z'
                                            fill={newChatBtnFg}
                                        />
                                        <path
                                            d='M24 15.75C25.0384 15.75 26.0534 15.4421 26.9167 14.8652C27.7801 14.2883 28.453 13.4684 28.8504 12.5091C29.2477 11.5498 29.3517 10.4942 29.1491 9.47578C28.9466 8.45738 28.4465 7.52192 27.7123 6.78769C26.9781 6.05347 26.0426 5.55345 25.0242 5.35088C24.0058 5.14831 22.9502 5.25227 21.9909 5.64963C21.0316 6.04699 20.2117 6.7199 19.6348 7.58326C19.0579 8.44662 18.75 9.46165 18.75 10.5C18.75 11.8924 19.3031 13.2277 20.2877 14.2123C21.2723 15.1969 22.6076 15.75 24 15.75ZM24 8.25C24.445 8.25 24.88 8.38196 25.25 8.6292C25.62 8.87643 25.9084 9.22783 26.0787 9.63897C26.249 10.0501 26.2936 10.5025 26.2068 10.939C26.12 11.3754 25.9057 11.7763 25.591 12.091C25.2763 12.4057 24.8754 12.62 24.439 12.7068C24.0025 12.7936 23.5501 12.749 23.139 12.5787C22.7278 12.4084 22.3764 12.12 22.1292 11.75C21.882 11.38 21.75 10.945 21.75 10.5C21.75 9.90327 21.9871 9.33097 22.409 8.90901C22.831 8.48706 23.4033 8.25 24 8.25Z'
                                            fill={newChatBtnFg}
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id='clip0_12_3532'>
                                            <rect width='36' height='36' fill='white' />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className='group-image-placeholder' style={{ color: newChatBtnFg }}>
                                {t('chat.uploadGroupIcon')}
                            </div>
                        </div>
                    ) : (
                        <img className='w-100 h-100 group-profile-image' src={base64ImgURL ? base64ImgURL : ''} alt='group-profile-image' />
                    )}
                </div>
            </Box>
            <Box className='w-100'>
                <input
                    data-testid='group-name-input-field'
                    type='text'
                    className='user-group-name w-100'
                    value={groupDetails.name}
                    placeholder={`${t('chat.groupNamePlaceholder')}`}
                    onChange={(e) => {
                        setGroupDetails((prev) => {
                            return {
                                ...prev,
                                name: e.target.value,
                            };
                        });
                    }}
                />
                <textarea
                    data-testid='group-description-input-field'
                    className='user-group-description w-100'
                    placeholder={`${t('chat.groupDescriptionPlaceholder')}`}
                    rows={7}
                    value={groupDetails.description}
                    onChange={(e) => {
                        setGroupDetails((prev) => {
                            return {
                                ...prev,
                                description: e.target.value,
                            };
                        });
                    }}
                />
            </Box>
            <Box className='next-btn-section'>
                {isLoading ? (
                    <SpinnerLoad size={52} />
                ) : (
                    <button
                        data-testid='next-btn'
                        className='next-btn'
                        onClick={() => {
                            if (groupEditMode) {
                                updateGroupThreadMutate(
                                    filesInfo.length > 0
                                        ? {
                                              id: groupEditDetails.id,
                                              formData: getNewGroupDetail(),
                                              isFormData: true,
                                          }
                                        : {
                                              id: groupEditDetails.id,
                                              formData: {
                                                  title: groupDetails.name,
                                                  description: groupDetails.description,
                                              },
                                              isFormData: false,
                                          }
                                );
                            } else {
                                newGroupThreadMutate(getNewGroupDetail());
                            }
                        }}
                        style={{
                            opacity: groupDetails.name.length > 0 ? 1 : 0.5,
                            backgroundColor: newChatBtnBg,
                        }}
                        disabled={groupDetails.name.length > 0 ? false : true}
                    >
                        <FontAwesomeIcon className='next-btn-icon' icon={faArrowRight} color={newChatBtnFg} />
                    </button>
                )}
            </Box>
        </Paper>
    );

    const renderCreateChatDetail = () => (
        <Paper className='thread-list-container' data-testid='thread-list-container' elevation={3}>
            <Box sx={{ mb: '10px' }}>
                {!displayCreateChat ? (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex' }}>
                                {displayArchivedChat ? (
                                    <>
                                        <button
                                            className='unstyled-btn'
                                            onClick={() => {
                                                setDisplaySettings(false);
                                                setIsResetThreadList(true);
                                                setDisplayArchivedChat(false);
                                                if (handleResetThreadSearch) handleResetThreadSearch('');
                                            }}
                                            style={{ lineHeight: '0.4' }}
                                        >
                                            <span className='fa-solid fa-angle-left' style={{ fontSize: '28px' }}></span>
                                        </button>
                                        <Typography className='thread-list-title' sx={{ ml: '10px' }}>
                                            {t('chat.archived')}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography
                                        className='thread-list-title'
                                        sx={{
                                            padding: '0 5px',
                                        }}
                                    >
                                        {t('chat.inbox')}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ lineHeight: '1' }}>
                                {!displayArchivedChat && (
                                    <button
                                        className='unstyled-btn'
                                        style={{ marginRight: showCreateChatBtn ? '10px' : '0px' }}
                                        onClick={() => setDisplaySettings(!displaySettings)}
                                        data-testid='chat-settings-popup-btn'
                                    >
                                        <Box
                                            className={'d-flex justify-content-center align-items-center'}
                                            sx={{
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                height: '30px',
                                                width: '30px',
                                            }}
                                        >
                                            <span className='fa-regular fa-ellipsis fa-lg'></span>
                                        </Box>
                                    </button>
                                )}
                                {showCreateChatBtn && (
                                    <button
                                        className='unstyled-btn'
                                        onClick={() => {
                                            setDisplayCreateChat(true);
                                            if (handleResetContactSearch) handleResetContactSearch('');
                                            if (handleResetThreadSearch) handleResetThreadSearch('');
                                        }}
                                        data-testid='create-new-chat-btn'
                                    >
                                        <Box
                                            className={'d-flex justify-content-center align-items-center'}
                                            sx={{
                                                backgroundColor: newChatBtnBg,
                                                borderRadius: '10px',
                                                height: '30px',
                                                width: '30px',
                                            }}
                                        >
                                            <span className='fa-regular fa-message-pen' style={{ fontSize: '15px', color: newChatBtnFg }} />
                                        </Box>
                                    </button>
                                )}
                            </Box>
                        </Box>
                        {displaySettings && (
                            <Paper elevation={3} sx={{ display: 'flex', justifyContent: 'flex-end' }} data-testid='chat-settings-popup'>
                                <Paper
                                    sx={{
                                        width: '250px',
                                        borderRadius: '8px',
                                        position: 'absolute',
                                        boxShadow: '0px 1px 4px 0px #00000099',
                                        px: '5px',
                                        mr: showCreateChatBtn ? '45px' : '5px',
                                        zIndex: 1,
                                    }}
                                >
                                    <Typography sx={{ margin: '10px 10px', fontSize: '16px', fontWeight: 600, lineHeight: 1 }}>
                                        {t('chat.chatSettings')}
                                    </Typography>
                                    <hr style={{ margin: 0, backgroundColor: '#00000033' }}></hr>
                                    <button
                                        className='unstyled-btn popup-btn'
                                        onClick={() => {
                                            setIsResetThreadList(true);
                                            setDisplayArchivedChat(true);
                                            setDisplaySettings(false);
                                            if (handleResetThreadSearch) handleResetThreadSearch('');
                                        }}
                                    >
                                        <span className='fa-solid fa-box-archive' style={{ fontSize: '18px', marginRight: '5px' }}></span>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: 1 }}>
                                            {t('chat.showArchivedChats')}
                                        </Typography>
                                    </button>
                                </Paper>
                            </Paper>
                        )}
                    </>
                ) : (
                    <Box sx={{ display: 'flex' }}>
                        <button
                            className='unstyled-btn'
                            onClick={() => {
                                setParticipantUsers([]);
                                displayCreateNewGroup ? setDisplayCreateChat(true) : setDisplayCreateChat(false);
                                setDisplayCreateNewGroup(false);
                                if (handleResetContactSearch) handleResetContactSearch('');
                                if (handleResetThreadSearch) handleResetThreadSearch('');
                            }}
                            style={{ lineHeight: '0.4' }}
                        >
                            <span className='fa-solid fa-angle-left' style={{ fontSize: '28px' }}></span>
                        </button>
                        <Typography className='thread-list-title' sx={{ ml: '10px' }}>
                            {displayCreateNewGroup ? t('chat.newGroupChat') : t('chat.newChat')}
                        </Typography>
                    </Box>
                )}
            </Box>
            {participantUsers.length > 0 && (
                <Box data-testid='selected-participant-users' id='selected-participant-section' className='selected-participant-section mb-3'>
                    {renderParticipantUsers}
                </Box>
            )}
            <SearchField
                handleSearch={handleSearch}
                searchQuery={searchQuery}
                handleSearchReset={displayCreateChat ? handleResetContactSearch : handleResetThreadSearch}
                handleKeyDown={handleKeyDown}
                placeholder={
                    t(displayCreateChat ? 'chat.searchContacts' : !displayArchivedChat ? 'chat.searchChats' : 'chat.searchArchivedChats') as string
                }
                displayClearButton={true}
                icon={<CircleXMarkIcon />}
                iconSx={{ width: '15px', height: '15px', color: '#898989' }}
            />
            {loading ? (
                <Box sx={{ height: '65vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <SpinnerLoad size={50} />
                </Box>
            ) : !displayCreateChat ? (
                <ThreadListThreads
                    data={data}
                    handleArchiveThread={handleArchiveThread}
                    isArchived={displayArchivedChat}
                    selectedThread={selectedThread}
                    fetchNextPage={fetchNextPage}
                    fetchingNextPage={fetchingNextPage}
                    refreshThreadDetail={refreshThreadDetail}
                    searchQuery={searchQuery as string}
                    displayArchivedChat={displayArchivedChat}
                />
            ) : (
                <ThreadListContacts
                    data={data}
                    displayCreateNewGroup={displayCreateNewGroup}
                    setDisplayCreateChat={setDisplayCreateChat}
                    setDisplayCreateNewGroup={setDisplayCreateNewGroup}
                    fetchNextPage={fetchNextPage}
                    fetchingNextPage={fetchingNextPage}
                    participantUsers={participantUsers}
                    setParticipantUsers={setParticipantUsers}
                    sx={{ height: containerHeight }}
                    handleResetContactSearch={handleResetContactSearch}
                />
            )}
            {displayCreateNewGroup && participantUsers.length > 0 && (
                <Box id='next-btn-section' className='next-btn-section'>
                    <button
                        data-testid='new-group-next-btn'
                        className='next-btn'
                        style={{ backgroundColor: newChatBtnBg, color: newChatBtnFg }}
                        onClick={() => {
                            setDisplayCreateChat(false);
                            setIsGroupSetting(true);
                        }}
                    >
                        <FontAwesomeIcon className='next-btn-icon' icon={faArrowRight} />
                    </button>
                </Box>
            )}
        </Paper>
    );

    return isGroupSetting ? renderCreateGroupDetail() : renderCreateChatDetail();
};
