import { useQuery } from "react-query";
import Client from "../../services/axiosService";

/**
 * @description: Fetches translated text from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
export const fetchTranslated = async (id: number | null, type: string, language: string) => {
    try {
        const response = await Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/translate/${type}/${id}?locale=${language}`,
            method: "get",
        })
        return response.data;
    } catch (error: any) {
        console.error("Error fetching translated text", error)
        throw new Error(error?.message || 'Unable to fetch data', {});
    }
}

/**
 * @description: fetches the translated text from the API for different components
 * @param debouncedSearchQuery 
 * @returns 
 */
export const useTranslateQuery = (id: number | null, type: string, language: string, onSuccess: (data: any) => void, onError: (err: Error) => void) => {
    return useQuery(
        [`translate-text-${id}`, id, type, language],
        () => fetchTranslated(id, type, language),
        {
            refetchOnWindowFocus: false,
            retry: 0,
            enabled: !!id,
            onSuccess: onSuccess,
            onError: onError
        }
    );
}