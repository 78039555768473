import { SxProps, Box } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';

export type CountdownTimerProps = {
    initialTime: number;
    onComplete?: () => void;
    displayHours?: boolean;
    containerSx?: SxProps;
};

export const CountdownTimer = (props: CountdownTimerProps) => {
    const { initialTime, onComplete, displayHours, containerSx } = props;
    const [timeRemaining, setTimeRemaining] = useState<number>(initialTime);
    const intervalRef = useRef<any>(null);

    useEffect(() => {
        // Start the countdown
        intervalRef.current = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    // Clear interval when we reach zero
                    clearInterval(intervalRef.current);
                    onComplete?.(); // Call completion handler if provided
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        // Cleanup function to clear interval when component unmounts
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const hours = Math.floor(timeRemaining / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;
    return (
        <Box sx={containerSx}>
            {displayHours && hours.toString().padStart(2, '0') + 'h'} {minutes.toString().padStart(2, '0')}m {seconds.toString().padStart(2, '0')}s
        </Box>
    );
};
