export default {
  "repliedToYourComment": "Replied to your comment",
  "likedYourComment": "Liked your comment",
  "sentYou": "Sent you {{text, en-handle-an}}",
  "received": "Received {{text, en-handle-an}}",
  "sendEmail": "Email",
  "sendMessage": "Message",
  "createThread": "Create New Thread",
  "attachments": "Attachments",
  "done": "Done",
  "expand": "Expand",
  "hide": "Hide",
  "showMoreComments": "Show More Comments",
  "hideComments": "Hide Comments",
  "showComments": "Show Comments",
  "hideReplies": "Hide Replies",
  "showReplies": "Show Replies",
  "deleteComment": "Delete Comment",
  "deleteCommentDesc": "Are you sure you want to remove this comment?",
  "noCommentsYet": "There are no comments yet...",
  "chooseAction": "Choose an action",
  "deletePost": "Delete",
  "deletePostDesc": "Are you sure you want to remove this post?",
  "unfollow": "Unfollow",
  "snooze": "Snooze",
  "reportPost": "Report Post",
  "report": "Report",
  "reportReason": "Reason for reporting this post ",
  "messageToManager": "Message to manager",
  "delete": "Delete",
  "emptyAwardsMessage": "There isn’t anything to show here right now as you haven’t received anything yet!",
  "Followers": "Followers",
  "Following": "Following",
  "SendAThankYou": "Send {{label, en-handle-an}}",
  "SeeAll": "See All",
  "SentToYou": "Sent to You",
  "CreateASocialPost": "Create a social post",
  "EditASocialPost": "Edit a social post",
  "Photo": "Photo",
  "Video": "Video",
  "Files": "Files",
  "Post": "Post",
  "LatestComments": "Latest Comments",
  "SentBy": "Sent By",
  "AllPosts": "All",
  "MyGroups": "My Groups",
  "Search": "Search...",
  "SpecificGroup" : "Specific Group",
  "selectGroupToPost": "Select a group to post to...",
  "cannotPostAlert": "It seems you don't have permission to post in this group. Please select another group.",
  "translateDescription": "Translate into your preferred language",
  "seeOriginalDescription": "View Original Text",
  "snoozeDescription": "Temporarily stop seeing posts from {{name}}",
  "unfollowDescription": "Stop seeing posts from {{name}}",
  "reportDescription": "Have an issue with this post?",
  "deleteDescription": "Permanently remove this post",
  "editPost": "Edit",
  "editDescription": "Change this post for up to"
};
