import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Popover, PopoverBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FeedItem, FeedPageFooterData } from '../../../../../types/types';
import sectionService from '../../../../services/sectionService';
import { getLikesStr, getCommentsStr } from '../../feedUtils';
import { addNotification } from '../../../../../shared/reducers/notifications/actionTypes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCommentsAlt } from '@fortawesome/pro-light-svg-icons';
import { LikeBtnHeart, CommentCloudIcon, LikeBtnHeartFilled, ShareArrowIcon } from '../../../../../icons/index';

type PagesFooterProps = {
    item: FeedItem;
    feedPageFooterData: FeedPageFooterData;
    setSharePage: Function;
    likePage: Function;
};

const PagesFooter: FunctionComponent<PagesFooterProps> = ({ item, feedPageFooterData, setSharePage, likePage }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [shareOpen, setShareOpen] = useState(false);

    const commentNavigation = (e: any) => {
        e.preventDefault();
        window.location.href = `/page/${item.model.id}`;
    };

    const handleLikePage = () => {
        const newVal = !item.model.is_liked;
        likePage(item.model.id, newVal);
        sectionService.likePage(
            item.model.id,
            newVal,
            () => {},
            () => {
                dispatch(addNotification({ label: `Like/Dislike`, text: t('general.errors.errorLoadingData'), type: 'danger' }));
                likePage(item.model.id, !newVal);
            }
        );
    };

    const pageUrl = `${window.location.origin}/page/${item.model.id}`;
    const getShareBody = () => {
        return feedPageFooterData?.bodyTemplate?.replace('{{**PageUrl**}}', pageUrl);
    };

    return (
        <>
            <Row data-testid='page-footer-container' className='mt-2'>
                <Col lg={6} xs={6} className='d-flex align-items-center'>
                    {item.model.likes && item.model.likes > 0 ? (
                        <span data-testid='like-section' className='me-0 d-flex align-items-center'>
                            <i data-testid='like-icon' className='fa-solid fa-circle-heart like-btn me-2'></i>
                            <span
                                data-testid='like-count'
                                className='c-pointer font-secondary-text mr0 d-inline'
                                style={{ textAlign: 'center' }}
                                onClick={commentNavigation}
                            >
                                {`${item.model.likes} ${getLikesStr(item.model.likes)}`}
                            </span>
                        </span>
                    ) : (
                        ''
                    )}
                </Col>
                <Col lg={6} xs={6} className='d-flex justify-content-end'>
                    {item.model.comments && item.model.comments > 0 ? (
                        <span data-testid='comment-section' className='d-flex align-items-center'>
                            <i data-testid='comment-icon' className='fa-solid fa-message-lines comment-btn-primary comment-btn me-2'></i>
                            <span
                                data-testid='comment-count'
                                className={`c-pointer font-secondary-text me-0 ${item.model.comments > 0 ? 'd-inline' : 'hide'}`}
                                style={{ textAlign: 'center' }}
                                onClick={commentNavigation}
                            >
                                {`${item.model.comments} ${getCommentsStr(item.model.comments)}`}
                            </span>
                        </span>
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
            <Row data-testid='social-options-section' className='mt-2 border-top py-2'>
                <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                    {item.model.allow_likes && (
                        <div data-testid='like-btn' onClick={handleLikePage}>
                            {item.model.is_liked ? (
                                <LikeBtnHeartFilled fill={'#d9534f'} height={'18px'} width={'18px'} data-testid='like-btn-icon' />
                            ) : (
                                <LikeBtnHeart height={'18px'} width={'18px'} data-testid='like-btn-icon' />
                            )}
                            <span className='ms-2 me-0 c-pointer text-light-black page-footer-option' style={{ fontWeight: 'bold' }}>
                                {t('general.like')}
                            </span>
                        </div>
                    )}
                </Col>
                <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                    {item.model.allow_comments && (
                        <a
                            data-testid='comment-btn'
                            href={`/page/${item.model.id}`}
                            className='d-block text-dark text-decoration-none'
                            onClick={commentNavigation}
                        >
                            <CommentCloudIcon
                                className='fa-regular fa-message-lines c-pointer comment-btn page-footer-option'
                                height={'18px'}
                                width={'18px'}
                            />
                            <span className='ms-2 me-0 c-pointer text-light-black page-footer-option' style={{ fontWeight: 'bold' }}>
                                {t('general.comment')}
                            </span>
                        </a>
                    )}
                </Col>
                <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                    {item.model.allow_share && (
                        <span
                            data-testid='share-btn'
                            className='ms-1 me-0 c-pointer feed-share-btn d-flex justify-content-center align-items-center'
                            id={`Popover${item.model.id}${new Date(item.model.updated_at).getTime()}`}
                        >
                            <ShareArrowIcon className='c-pointer page-footer-option' height={'18px'} width={'18px'} />
                            <span className='page-footer-option ms-2 text-light-black' style={{ fontWeight: 'bold' }}>
                                {t('general.share')}
                            </span>
                            <Popover
                                data-testid='share-popup'
                                placement='bottom'
                                isOpen={shareOpen}
                                target={`Popover${item.model.id}${new Date(item.model.updated_at).getTime()}`}
                                toggle={() => setShareOpen(!shareOpen)}
                                popperClassName='mt-2'
                                trigger='legacy'
                            >
                                <PopoverBody>
                                    <a
                                        data-testid='email-btn'
                                        href={`mailto:?body=${getShareBody()}&subject=${feedPageFooterData.shareSubject}`}
                                        onClick={() => setShareOpen(false)}
                                        className='share-btn w-100 d-block btn btn-block text-left'
                                    >
                                        <FontAwesomeIcon icon={faEnvelope} className='float-left mt-1 mr-2' />
                                        <strong>{t('feed.sendEmail')}</strong>
                                    </a>
                                    {feedPageFooterData.messagesEnabled ? (
                                        <button
                                            data-testid='message-btn'
                                            className='share-btn w-100 btn btn-block mt-1 text-left'
                                            data-page-url={pageUrl}
                                            data-page-name={item.model.title}
                                            onClick={() => {
                                                setShareOpen(false);
                                                setSharePage(item);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCommentsAlt} className='float-left mt-1 me-3' />
                                            <strong>{t('feed.sendMessage')}</strong>
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                </PopoverBody>
                            </Popover>
                        </span>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default PagesFooter;
