import React from 'react';
import { Grid, Box } from '@mui/material';

export type CustomTemplateSixProps = {
    components: any[];
};

export const CustomTemplateSix = (props: CustomTemplateSixProps) => {
    const { components } = props;

    return (
        <Grid container data-template={6} spacing={2} data-testid='custom-template-6'>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box
                    data-pos={1}
                    sx={{
                        maxHeight: components[1]?.key === 'banner' ? '100%' : '45vh',
                    }}
                >
                    {components[1]}
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Box
                        data-pos={2}
                        sx={{
                            maxHeight: '43vh',
                            overflow: 'auto',
                            position: 'relative',
                            flexGrow: 1,
                            flexBasis: 0,
                        }}
                    >
                        {components[2]}
                    </Box>
                    <Box data-pos={3} sx={{ maxHeight: '43vh', overflow: 'auto', flexGrow: 1, flexBasis: 0 }}>
                        {components[3]}
                    </Box>
                    <Box
                        data-pos={4}
                        sx={{
                            height: '43vh',
                            overflow: 'auto',
                            flexGrow: 1,
                            flexBasis: 0,
                        }}
                    >
                        {components[4]}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};
