import React, { useState } from 'react';
import { Box, Skeleton, SxProps, Typography, Tooltip } from '@mui/material';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from '../../hooks/useResizeObserverHook';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faGlobe as faSolidGlobe } from '@fortawesome/pro-solid-svg-icons';
import { TranslateBtnWrapper } from '../translateBtnWrapper/translateBtnWrapper';
import { useLocation } from 'react-router-dom';

export type CommunityCardProps = {
    title: string;
    description: string;
    members: number;
    containersSx?: SxProps;
    img: string;
    imgAlt?: string;
    id: number;
    handleClick: () => void;
    membersClick: (id: number) => void;
    loading: boolean;
    can_post: boolean;
    handleTranslate: (groupID: number, translateGroup: boolean) => void;
    clickedTranslateGroup: {
        [key: number]: boolean | null;
    };
    locale?: string | null;
    translatingGroup?: boolean;
    groupToTranslateID?: number | null;
    skeletonSx?: string;
};

export const CommunityCard = (props: CommunityCardProps) => {
    const {
        title,
        description,
        members,
        containersSx,
        img,
        imgAlt,
        handleClick,
        membersClick,
        id,
        loading,
        can_post,
        handleTranslate,
        clickedTranslateGroup,
        locale,
        translatingGroup,
        groupToTranslateID,
        skeletonSx,
    } = props;
    const { t } = useTranslation();
    const [containerRef, containerObj] = useResizeObserver();
    const extraSmallContainerSize: number = 450;
    const extraSmallContainer: boolean = containerObj?.width && containerObj?.width < extraSmallContainerSize;
    const { pathname } = location;
    const connectPage = pathname.includes('/connect/groups');

    if (loading) {
        return (
            <Box
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: '0px 4px 4px 0px #00000026',
                    height: '184px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    ...containersSx,
                    marginBottom: '18px',
                    color: '#000',
                }}
                data-testid='community-group-card-loading'
            >
                <Box
                    sx={{
                        margin: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Skeleton variant='text' width={skeletonSx === 'connect-skeleton' ? 400 : 300} height={40} />
                        <Skeleton variant='text' width={skeletonSx === 'connect-skeleton' ? 400 : 300} height={40} />
                        <Skeleton variant='text' width={skeletonSx === 'connect-skeleton' ? 400 : 300} height={40} />
                        <Skeleton
                            variant='text'
                            width={100}
                            height={40}
                            sx={{
                                marginTop: '10px',
                            }}
                        />
                    </Box>
                </Box>
                <Box>
                    <Skeleton variant='rectangular' width={300} height={184} />
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                backgroundColor: '#fff',
                boxShadow: '0px 4px 4px 0px #00000026',
                height: '184px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: '8px',
                ...containersSx,
                marginBottom: '18px',
                color: '#000',
            }}
            onClick={(e) => {
                e.stopPropagation();
                handleClick();
            }}
            data-testid='community-group-card'
            ref={containerRef}
        >
            <Box
                sx={{
                    margin: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        marginX: '4px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '5px',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {translatingGroup && id === groupToTranslateID ? (
                            <Skeleton variant='text' width={skeletonSx === 'connect-skeleton' ? 400 : 250} height={40} />
                        ) : (
                            <Box sx={{ fontSize: '20px', fontWeight: '600', lineHeight: '1.2' }}>{title}</Box>
                        )}
                        {!connectPage && (
                            <TranslateBtnWrapper locale={clickedTranslateGroup?.[id] ? 'translated' : locale}>
                                <div
                                    onClick={() => {
                                        handleTranslate(id, clickedTranslateGroup?.[id] ? false : true);
                                    }}
                                >
                                    {clickedTranslateGroup && clickedTranslateGroup?.[id] ? (
                                        <Tooltip title={t('general.seeOriginal')}>
                                            <FontAwesomeIcon icon={faSolidGlobe} className='c-pointer' />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={t('general.translate')}>
                                            <FontAwesomeIcon icon={faGlobe} className='c-pointer' />
                                        </Tooltip>
                                    )}
                                </div>
                            </TranslateBtnWrapper>
                        )}
                    </Box>
                    {!can_post && (
                        <Box
                            sx={{
                                fontWeight: '300',
                                WebkitLineClamp: 2,
                                fontSize: '14px',
                                lineHeight: '1.2',
                            }}
                        >
                            <i className='fa-regular fa-lock me-2'></i>
                            {t('connect.restrictPostingGroup')}
                        </Box>
                    )}
                    {translatingGroup && id === groupToTranslateID ? (
                        <Skeleton variant='text' width={skeletonSx === 'connect-skeleton' ? 400 : 250} height={60} />
                    ) : (
                        <Box
                            sx={{
                                fontWeight: '300',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                marginTop: '6px',
                            }}
                        >
                            {description}
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        marginY: '4px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginX: '4px',
                            cursor: 'pointer',
                            width: 'fit-content',
                            ':hover': {
                                borderBottom: '0.5px solid #000000',
                            },
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            membersClick(id);
                        }}
                    >
                        <FontAwesomeIcon icon={faUsers} fontSize={'16px'} />
                        <Typography sx={{ fontSize: '14px' }}>
                            {members} {t('general.members').toLocaleLowerCase()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    aspectRatio: '16/9',
                    display: extraSmallContainer ? 'none' : 'block',
                }}
            >
                <img
                    src={img}
                    alt={imgAlt}
                    style={{
                        height: '100%',
                        width: '100%',
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                    }}
                />
            </Box>
        </Box>
    );
};
