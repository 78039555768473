export default {
  "sharePost": "Begin met delen...",
  "what do you have to say": "Wat heb je te zeggen",
  "emptyField": "Kan een lege sociale post niet opslaan.",
  "socialPost": "Sociale post",
  "postedFail": "Social Post kon niet worden geplaatst. Probeer het later opnieuw.",
  "mediaFail": "Er is een fout opgetreden tijdens het uploaden van de media. Probeer het later opnieuw.",
  "close": "Sluiten",
  "mediaTooBig": "Sommige bestanden hebben de maximale grootte overschreden en konden niet worden geüpload. De maximale bestandsgrootte is",
  "mentionUsers": "Gebruikers vermelden",
  "mentionUsersFail": "Kan de vermelde gebruikers niet ophalen. Probeer het later opnieuw.",
  "userUnavailable": "Gebruiker niet beschikbaar",
  "postUnavailable": "Oeps! We kunnen het bericht dat je zoekt niet vinden.",

  "you": "Jij",
  "single_you": "Je",
  
  "you_reposted_this": "hebt dit opnieuw gepost",
  "singular_reposted_this": "heeft dit opnieuw gepost",
  "we_reposted_this": "hebben dit opnieuw gepost",
  "they_reposted_this": "hebben dit opnieuw gepost",

  "and": "en",
  "more": "{{dynamicValue}} anderen",
  "users": "Opnieuw geposte gebruikers",
  "repostFailMessage": "Je hebt dit al opnieuw gepost",
  "repost": "Opnieuw posten",
  "repostWithYourThoughts": "Repost met uw gedachten",
  "shareYourThoughtsOnThisPost": "Deel uw mening over dit bericht",
  "repostWithoutThoughts": "Deel het bericht van {{dynamicValue}} met je volgers",
  "repostSuccessMessage": "Succesvol opnieuw geplaatst.",
  "editPostTimeLimit": "Je kunt dit bericht niet meer bewerken omdat de tijd is verstreken.",
  "editPostSuccessMessage": "Bericht succesvol bewerkt.",
};