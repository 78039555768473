import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box } from '@mui/material';
import { SearchField } from '../../../../components/searchField/searchField';
import { useSearchHook } from '../../../../hooks/useSearchHook';
import { useDebounce } from '../../../../hooks/useDebounceHook';
import { usePeopleFilteredList } from '../../../../hooks/usePeopleFilteredListHook';
import { useTranslation } from 'react-i18next';
import { PeopleList } from '../../../../modules/PeopleList/PeopleList';
import SpinnerLoad from '../../../../components/common/spinnerLoad/spinnerLoad';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ContactDetails from '../../../../components/connectPage/contactPage/contactDetails';
import { useUpdateFavourite } from '../../../../hooks/useUpdateFavouriteHook';
import { NoResultsWidget } from '../../../../components/noResultWidget/noResultWidget';
import { PeopleFilters } from '../../../../modules/PeopleFilters/PeopleFilters';
import { useFilterOptionsQuery } from '../../../../hooks/query/useFilterOptionsQuery';
import { cloneDeep } from 'lodash';
import { useConnectFilterAttrQuery } from '../../../../hooks/query/useConnectFilterAttrQuery';
import { useCustomFilterOptionsQuery } from '../../../../hooks/query/useCustomFilterOptionsQuery';
import { Favourites } from '../Favourites/Favourites';
import { AlphabetList } from '../../../../components/alphabetList/alphabetList';

export const People = () => {
    const { searchQuery: searchPeople, handleChange: handleSearchPeople, handleSearchReset: handleSearchResetPeople } = useSearchHook('');
    const {
        searchQuery: filtersSearch,
        handleChange: handleFiltersSearch,
        handleSearchReset: handleFiltersReset,
    } = useSearchHook({
        badge: '',
        location: '',
        manager: '',
        department: '',
        email: '',
    });
    const [selectedFilters, setSelectedFilters] = useState<any>({
        department: '',
        location: [],
        email: '',
        manager: [],
        badge: [],
    });
    const [customSelectedFilters, setCustomSelectedFilters] = useState<any>(null);
    const [customFilters, setCustomFilters] = useState<any>(null);
    const { searchQuery: customFilterSearch, handleChange: handleCustomFilterSearch, handleSearchReset: handleCustomFilterReset } = useSearchHook({});
    const { debouncedValue: debouncedSearchValue } = useDebounce(searchPeople);
    const { debouncedValue: debouncedBadgeValue } = useDebounce(filtersSearch.badge);
    const { debouncedValue: debouncedLocationValue } = useDebounce(filtersSearch.location);
    const { debouncedValue: debouncedManagerValue } = useDebounce(filtersSearch.manager);
    const [page, setPage] = useState<number>(1);
    const [openAdditionalFilters, setOpenAdditionalFilters] = useState<boolean>(false);
    const [filterID, setFilterID] = useState<string>('');
    const [contacts, setContacts] = useState<any>(null);
    const [alphabetFilter, setAlphabetFilter] = useState<string>('');
    const { mutate: getPeople, isLoading: contactsLoading } = usePeopleFilteredList((data: any) => {
        const { data: contactsData } = data;
        const cotactsDataCopied = cloneDeep(contactsData);
        if (cotactsDataCopied?.meta?.current_page > 1 && contacts) {
            setContacts((prev: any) => {
                return { ...cotactsDataCopied, data: cloneDeep([...prev?.data, ...cotactsDataCopied.data]) };
            });
        } else {
            setPage(1);
            setContacts(cotactsDataCopied);
        }
    });
    const { mutate: updateFavourite } = useUpdateFavourite((data: any) => {
        const id = data.config.url.split('/').filter((item: string) => +item)[0];
        const userObj = contacts.data.find((contact: any) => contact.id === +id);
        userObj.is_favorite = !userObj.is_favorite;
        setContacts({
            ...contacts,
            data: contacts.data.map((contact: any) => {
                if (contact.id === +id) {
                    return userObj;
                }
                return contact;
            }),
        });
    });

    const badgeQuery = useFilterOptionsQuery({
        type: 'badge',
        searchQuery: debouncedBadgeValue as string,
    });
    const locationQuery = useFilterOptionsQuery({
        type: 'location',
        searchQuery: debouncedLocationValue as string,
    });
    const managerQuery = useFilterOptionsQuery({
        type: 'manager',
        searchQuery: debouncedManagerValue as string,
    });
    const customFilterOptions = useCustomFilterOptionsQuery(filterID);
    const {
        data: attributesData,
        isLoading: attributesLoading,
        fetchNextPage: fetchNextAttributes,
        isFetchingNextPage: fetchingNextAtrributes,
    } = useConnectFilterAttrQuery();

    const { t } = useTranslation();
    const { id: personID } = useParams();
    const navigate = useNavigate();
    const scrollRef = React.useRef<any>(null);
    const isBatchOperationRef = useRef<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        if (location.state?.locationId) {
            setSelectedFilters({ ...selectedFilters, location: [location.state.locationId] });
        }
    }, [location.state]);

    const handleContactCardClick = (id: string) => {
        navigate(`/connect/people/${id}`);
    };

    const addFilters = () => {
        const filters: any = {};
        for (const key in selectedFilters) {
            const value = selectedFilters[key];
            if (Array.isArray(value) ? value.length > 0 : value.length > 0) {
                filters[key] = value;
            }
        }
        return filters;
    };

    const handleAlphabetClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const char = e.currentTarget.getAttribute('data-char') || '';
        setAlphabetFilter((prev: string) => (prev === char ? '' : char));
    };

    useEffect(() => {
        const customFilter: any = {};
        attributesData?.pages?.forEach((page) => {
            page?.data.forEach((attr: { id: number; title: string; type_id: number }) => {
                const { title, type_id, id } = attr;
                customFilter[id] = {
                    value: type_id === 10 || type_id === 9 ? [] : '',
                    id: id.toString(),
                    multiple: type_id === 10,
                    title: title,
                };
            });
        });

        setCustomSelectedFilters(customFilter);
    }, [attributesData]);

    useEffect(() => {
        if (isBatchOperationRef.current) {
            return;
        }
        const filters: any = {};
        for (const key in customSelectedFilters) {
            const value = customSelectedFilters[key];
            if (value.value.length > 0) {
                const isArray = Array.isArray(value.value);
                const isMultiple = customSelectedFilters[key].multiple;

                filters[key] = isArray ? (isMultiple ? value.value : value.value[0]) : value.value;
            }
        }
        if (JSON.stringify(filters) === JSON.stringify(customFilters)) {
            return;
        } else {
            setCustomFilters(filters);
        }
    }, [customSelectedFilters]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
        const attribute_templates = customFilters
            ? Object.keys(customFilters).map((id) => ({
                  id: parseInt(id),
                  value: customFilters[id],
              }))
            : [];
        getPeople({
            page: 1,
            data: {
                search: {
                    text: debouncedSearchValue,
                    prefix: alphabetFilter,
                },
                filter: {
                    attributes: {
                        ...addFilters(),
                    },
                    attribute_templates: attribute_templates,
                },
            },
        });
    }, [debouncedSearchValue, selectedFilters, customFilters, alphabetFilter]);

    useEffect(() => {
        if (page === 1) {
            if (scrollRef.current) {
                scrollRef.current!.scrollTop = 0;
            }
        }
        if (page > 1) {
            const attribute_templates = customFilters
                ? Object.keys(customFilters).map((id) => ({
                      id: parseInt(id),
                      value: customFilters[id],
                  }))
                : [];
            getPeople({
                page: page,
                data: {
                    search: {
                        text: debouncedSearchValue,
                        prefix: alphabetFilter,
                    },
                    filter: {
                        attributes: {
                            ...addFilters(),
                        },
                        attribute_templates: attribute_templates,
                    },
                },
            });
        }
    }, [page]);

    const handleScroll = (e: any) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (Math.round(scrollTop + clientHeight) + 1 >= scrollHeight && !contactsLoading) {
            if (contacts?.links?.next) {
                setPage((prev) => prev + 1);
            }
        }
    };

    const handleClearFilter = () => {
        isBatchOperationRef.current = true;
        setSelectedFilters({
            department: '',
            location: [],
            email: '',
            manager: [],
            badge: [],
        });
        setCustomSelectedFilters((prev: any) => {
            const newFilters = { ...prev };
            for (const key in newFilters) {
                newFilters[key].value = Array.isArray(newFilters[key].value) ? [] : '';
            }
            return newFilters;
        });
        setCustomFilters({});
        handleFiltersReset && handleFiltersReset();
        handleCustomFilterReset && handleCustomFilterReset();
        setTimeout(() => {
            isBatchOperationRef.current = false;
        }, 0);
    };

    const checkFiltersAreEmpty = (selectedFilters: any, customSelectedFilters: any) => {
        for (const key in selectedFilters) {
            if (selectedFilters[key].length > 0) {
                return true;
            }
        }
        for (const key in customSelectedFilters) {
            if (customSelectedFilters[key].value.length > 0) {
                return true;
            }
        }
        return false;
    };

    if (location.search.includes('starred')) {
        return <Favourites />;
    }

    return (
        <Grid container data-testid='people-page' sx={{ margin: 0, flexDirection: 'column', gap: '15px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={4} xl={4} sx={{ paddingRight: personID ? '14px' : '4px' }}>
                    <SearchField
                        searchQuery={searchPeople}
                        displayClearButton={true}
                        handleSearchReset={handleSearchResetPeople}
                        handleSearch={(e) => {
                            handleSearchPeople && handleSearchPeople(e);
                        }}
                        placeholder={t('connect.search') as string}
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            borderRadius: '8px',
                            border: '1px solid rgba(0, 0, 0, 0.2)',
                            height: '35px',
                        }}
                        iconStyle={{
                            padding: '0 10px',
                            fontSize: '16px',
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={8} xl={8}>
                    <PeopleFilters
                        badgeOptions={badgeQuery}
                        customSelectedFilters={customSelectedFilters}
                        customFilterOptions={customFilterOptions}
                        locationOptions={locationQuery}
                        managerOptions={managerQuery}
                        filtersSearch={filtersSearch}
                        customFilterSearch={customFilterSearch}
                        setCustomSelectedFilters={setCustomSelectedFilters}
                        setFilterID={setFilterID}
                        handleClearFilter={handleClearFilter}
                        handleFiltersReset={(name: string, selectType: boolean) => {
                            handleFiltersReset && handleFiltersReset(name);
                            if (!selectType) {
                                setSelectedFilters({
                                    ...selectedFilters,
                                    [name]: '',
                                });
                            }
                        }}
                        handleCustomFilterReset={(name: string, selectType: boolean) => {
                            handleCustomFilterReset && handleCustomFilterReset(name);
                            if (!selectType) {
                                setSelectedFilters({
                                    ...selectedFilters,
                                    [name]: '',
                                });
                            }
                        }}
                        handleFiltersSearchChange={(e: React.ChangeEvent<HTMLInputElement>, selectType: boolean) => {
                            handleFiltersSearch && handleFiltersSearch(e);
                            if (!selectType) {
                                setSelectedFilters({
                                    ...selectedFilters,
                                    [e.target.name]: e.target.value,
                                });
                            }
                        }}
                        handleCustomFilterSearchChange={(e: React.ChangeEvent<HTMLInputElement>, selectType: boolean) => {
                            handleCustomFilterSearch && handleCustomFilterSearch(e);
                            if (!selectType) {
                                setCustomSelectedFilters({
                                    ...customSelectedFilters,
                                    [e.target.name]: {
                                        ...customSelectedFilters[e.target.name],
                                        value: e.target.value,
                                    },
                                });
                            }
                        }}
                        displayClearAllFilters={checkFiltersAreEmpty(selectedFilters, customSelectedFilters)}
                        loadingAttributes={attributesLoading}
                        selectedFilters={selectedFilters}
                        fetchNextAttributes={fetchNextAttributes}
                        fetchingNextAtrributes={fetchingNextAtrributes}
                        setSelectedFilters={setSelectedFilters}
                        openAdditionalFilters={openAdditionalFilters}
                        setOpenAdditionalFilters={setOpenAdditionalFilters}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {contacts?.data?.length === 0 ? (
                    <Grid item xs={12} sx={{ height: '65vh', display: 'flex', justifyContent: 'center' }}>
                        <NoResultsWidget
                            title={t('connect.noResultsFound')}
                            containerSx={{
                                flexGrow: 1,
                            }}
                            description={
                                t(
                                    debouncedSearchValue ? 'connect.noSearchResultsContactDescription' : 'connect.noContactResultsDescription'
                                ) as string
                            }
                            descriptionSx={{
                                textAlign: 'center',
                                maxWidth: '450px',
                            }}
                        />
                        <AlphabetList
                            containerProps={{
                                sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '75vh',
                                    alignItems: 'center',
                                    paddingRight: '10px',
                                    margin: '5px 0 0',
                                },
                            }}
                            selectedAlphabet={alphabetFilter}
                            handleClick={handleAlphabetClick}
                        />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={personID ? 4 : 12} sx={{ display: 'flex' }}>
                            <Box
                                sx={{ height: '75vh', overflow: 'hidden', overflowY: 'scroll', flexGrow: 1, paddingBottom: '5px' }}
                                onScroll={handleScroll}
                                ref={scrollRef}
                            >
                                <Grid container spacing={2} sx={{ paddingRight: '16px' }}>
                                    <PeopleList
                                        contacts={contacts?.data}
                                        loading={page > 1 ? false : contactsLoading}
                                        containerProps={{
                                            xs: 12,
                                            md: personID ? 12 : 6,
                                            lg: personID ? 12 : 4,
                                        }}
                                        updateFavourite={updateFavourite}
                                        handleContactCardClick={handleContactCardClick}
                                    />
                                    <Grid item xs={12}>
                                        {page > 1 && contactsLoading && <SpinnerLoad className='my-2' />}
                                    </Grid>
                                </Grid>
                            </Box>
                            <AlphabetList
                                containerProps={{
                                    sx: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '75vh',
                                        alignItems: 'center',
                                        paddingRight: '10px',
                                        margin: '5px 0 0',
                                    },
                                }}
                                selectedAlphabet={alphabetFilter}
                                handleClick={handleAlphabetClick}
                            />
                        </Grid>
                        {personID && (
                            <Grid item xs={8}>
                                <ContactDetails hideContactList />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    );
};
