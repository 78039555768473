import './banner.scss';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { CarouselControl, Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';
import { BannerData } from '~/types/types';
import { AnchorWrapper } from '../../components/anchorWrapper/anchorWrapper';

type BannerProps = {
    data: BannerData;
};

const Banner: FunctionComponent<BannerProps> = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [animating, setAnimating] = useState<boolean>(false);
    const [displayTitle, setDisplayTitle] = useState<boolean>(false);

    const slides = data.slides;
    const itemLength = slides.length - 1;

    useEffect(() => {
        if (slides) {
            let hasTitle: boolean = false;
            slides.some((slide) => {
                if (slide.title) {
                    hasTitle = true;
                    setDisplayTitle(true);
                    return;
                }
            });

            if (!hasTitle) {
                setDisplayTitle(false);
            }

            const root = document.documentElement;
            root?.style.setProperty('--banner-indices-bottom-value', hasTitle ? '30px' : '0px');
        }
    }, [slides]);

    const previousButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? itemLength : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const nextButton = () => {
        if (animating) return;
        const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const carouselItemData = slides.map((item) => {
        const targetURL = item.path !== '' ? new URL(item.path) : null;
        const hasTitle = item.title != null && item.title != '';
        return (
            <CarouselItem key={item.id} onExited={() => setAnimating(false)} onExiting={() => setAnimating(true)}>
                {targetURL !== null ? (
                    <div className={`${hasTitle ? 'banner-item' : 'banner-item-no-title'}`}>
                        <AnchorWrapper
                            anchorEl={true}
                            anchorProps={{
                                href: item.id != null && item.id != '' ? targetURL?.pathname ?? '/' : targetURL?.toString(),
                                target: targetURL?.toString() && !targetURL?.pathname ? '_blank' : '_self',
                                className: 'react-router-link',
                            }}
                        >
                            <img
                                src={item.img}
                                alt={hasTitle ? item.title : 'Banner Image'}
                                className='d-block w-100 banner-image'
                                style={{ borderRadius: hasTitle ? '10px 10px 0 0' : '10px' }}
                            />
                        </AnchorWrapper>
                    </div>
                ) : null}
                {displayTitle && (
                    <div className={'banner-title'}>
                        <div className={'banner-title-text'}>{item.title}</div>
                    </div>
                )}
            </CarouselItem>
        );
    });

    return (
        <div className='customLayoutBanner'>
            <Carousel activeIndex={activeIndex} next={nextButton} previous={previousButton} style={{ height: '100%' }}>
                <CarouselIndicators
                    activeIndex={activeIndex}
                    className={'banner-indices'}
                    items={slides}
                    onClickHandler={(newIndex) => {
                        if (animating) return;
                        setActiveIndex(newIndex);
                    }}
                />
                {carouselItemData}
                <CarouselControl directionText='Previous' direction='prev' onClickHandler={previousButton} />
                <CarouselControl directionText='Next' direction='next' onClickHandler={nextButton} />
            </Carousel>
        </div>
    );
};

export default Banner;
