import React, { AnchorHTMLAttributes, FunctionComponent } from 'react';
import { Link, LinkProps, To } from 'react-router-dom';


type AnchorWrapperProps = {
    anchorEl: boolean;
    linkProps?: LinkProps;
    anchorProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
    children: React.ReactNode;
    testId?: string;
};

export const AnchorWrapper: FunctionComponent<AnchorWrapperProps> = ({ anchorEl, linkProps, anchorProps, children, testId }) => {
    if (anchorEl) {
        return <a {...anchorProps} data-testid={testId}>{children}</a>;
    } else {
        return <Link to={linkProps?.to as To} {...linkProps} data-testid={testId}>{children}</Link>;
    }
}
