import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import Scrollbar from '../common/scrollbar/scrollbar';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { FollowingUserDetail } from '../../../types/interface/FollowingDetailModal';
import { getFollowers, getFollowings, followUserRequest, unfollowUserRequest } from './followingDetailModalProvider';
import './followingDetailModal.scss';
import { IconCard } from '../IconCard/iconCard';
import { Box } from '@mui/material';
import { AnchorWrapper } from '../anchorWrapper/anchorWrapper';

const firstPage = 1;

const followingType = {
    direct: 'direct',
    Null: null,
};

interface FollowingDetailModalProps {
    isOpen: boolean;
    userId: number;
    isLoggedInUser?: boolean;
    defaultTab: number;
    totalFollowing: number;
    totalFollowers: number;
    handleDialogClose: () => void;
}

export type FollowingDetailModalContentProps = {
    isOpen: boolean;
    totalCount: {
        followers: number;
        following: number;
    };
    activeTab: number;
    userList: any[] | undefined;
    fetchingFollowers: boolean;
    fetchingFollowings: boolean;
    pageScroll: (e: { scrollTop: number; clientHeight: number; scrollHeight: number }) => void;
    renderFollowerButtons: (user: FollowingUserDetail) => JSX.Element[];
    handleDialogClose: () => void;
    handleTabChange: (tabNumber: number) => void;
};

export const FollowingDetailModalContent: React.FC<FollowingDetailModalContentProps> = ({
    isOpen,
    totalCount,
    activeTab,
    pageScroll,
    userList,
    fetchingFollowers,
    fetchingFollowings,
    renderFollowerButtons,
    handleDialogClose,
    handleTabChange,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userInfo = useSelector((state: any) => state.app.user);

    const renderUsers = () =>
        userList &&
        userList.length > 0 &&
        userList?.map((users) => {
            return users.data.map((user: any, i: number) => (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <AnchorWrapper
                        anchorEl={false}
                        linkProps={{
                            to: `/connect/people/${user.contact_id}`,
                            className: 'react-router-link',
                        }}
                    >
                        <IconCard key={i} title={user.full_name} icon={user.image_url} className='d-flex c-pointer' titleClassName='user-name'>
                            <div>
                                <div>
                                    <h4 className='mt-1 m-0'>{user.job_title}</h4>
                                    {!activeTab && user.following_me && (
                                        <button data-testid='follows-you-btn' className='followed-button mt-2' disabled>
                                            {t('connect.followsYou')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </IconCard>
                    </AnchorWrapper>
                    <div style={{ paddingTop: '10px' }}>{userInfo.id != user.id ? renderFollowerButtons(user) : null}</div>
                </Box>
            ));
        });

    return (
        <Modal data-testid='following-detail-modal' className='following-detail-modal' isOpen={isOpen} toggle={handleDialogClose}>
            <ModalHeader className='following-detail-modal-header' toggle={handleDialogClose}>
                <div className='row d-flex h-100'>
                    <button
                        data-testid='following-tab'
                        className={`col-6 d-flex justify-content-center align-items-center social-tab ${!activeTab ? 'active-social-tab' : ''}`}
                        onClick={() => {
                            handleTabChange(0);
                        }}
                        disabled={activeTab === 0 ? true : false}
                    >
                        {totalCount.following} {t('connect.following')}
                    </button>
                    <button
                        data-testid='followers-tab'
                        className={`col-6 d-flex justify-content-center align-items-center social-tab ${activeTab ? 'active-social-tab' : ''}`}
                        onClick={() => {
                            handleTabChange(1);
                        }}
                        disabled={activeTab === 1 ? true : false}
                    >
                        {totalCount.followers} {t('connect.followers')}
                    </button>
                </div>
            </ModalHeader>
            <ModalBody className='following-detail-modal-body'>
                <Scrollbar onScroll={pageScroll} className='following-detail-modal-scrollbar' rightScroll={true} noScrollX>
                    {renderUsers()}
                    {(fetchingFollowers || fetchingFollowings) && (
                        <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                    )}
                    {!fetchingFollowers && !fetchingFollowings && userList?.length === 0 && (
                        <div data-testid='empty-result-msg' className='d-flex justify-content-center'>
                            {t('connect.noResultsFound')}
                        </div>
                    )}
                </Scrollbar>
            </ModalBody>
        </Modal>
    );
};

const FollowingDetailModal: React.FC<FollowingDetailModalProps> = ({
    isOpen,
    userId,
    isLoggedInUser = true,
    defaultTab,
    totalFollowers,
    totalFollowing,
    handleDialogClose,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const [isFetchUserList, setIsFetchUserList] = useState(false); // if true, call an API to fetch user list
    const [activeTab, setIsActiveTab] = useState<number>(defaultTab);
    const [currentPage, setCurrentPage] = useState<number>(firstPage);
    const [hasListChanged, setHasListChanged] = useState({
        followers: false,
        following: false,
    }); // if true refetch list on tab change
    const [totalCount, setTotalCount] = useState<{
        followers: number;
        following: number;
    }>({
        followers: totalFollowers,
        following: totalFollowing,
    });

    const followersQueryKey = ['getFollowers', userId];
    const followingsQueryKey = ['getFollowings', userId];

    let {
        data: followerUserList,
        isFetching: fetchingFollowers,
        refetch: refetchFollowers,
        hasNextPage: followersHasNextPage,
        fetchNextPage: fetchFollowersNextPage,
    } = useInfiniteQuery(followersQueryKey, ({ pageParam = 1 }) => getFollowers(userId, pageParam), {
        enabled: activeTab === 1 && isFetchUserList,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage) {
                if (lastPage.meta.current_page === lastPage.meta.last_page) return undefined;
                return allPages.length + 1;
            }
        },
        onSuccess: () => {
            setIsFetchUserList(false);
        },
        onError: () => {
            if (isFetchUserList) setIsFetchUserList(false);
            dispatch(
                addNotification({
                    label: `Get Followers`,
                    text: t('connect.errorLoadingFollowers'),
                    type: 'danger',
                })
            );
        },
    });

    let {
        data: followingUserList,
        isFetching: fetchingFollowings,
        refetch: refetchFollowings,
        hasNextPage: followingsHasNextPage,
        fetchNextPage: fetchFollowingsNextPage,
    } = useInfiniteQuery(followingsQueryKey, ({ pageParam = 1 }) => getFollowings(userId, pageParam), {
        enabled: activeTab === 0 && isFetchUserList,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage) {
                if (lastPage.meta.current_page === lastPage.meta.last_page) return undefined;
                return allPages.length + 1;
            }
        },
        onSuccess: () => {
            setIsFetchUserList(false);
        },
        onError: () => {
            if (isFetchUserList) setIsFetchUserList(false);
            dispatch(
                addNotification({
                    label: `Get Followings`,
                    text: t('connect.errorLoadingFollowings'),
                    type: 'danger',
                })
            );
        },
    });

    const { mutate: followUserMutation } = useMutation((formData: any) => {
        return followUserRequest(formData);
    });

    const { mutate: unfollowUserMutation } = useMutation((formData: any) => {
        return unfollowUserRequest(formData);
    });

    useEffect(() => {
        if (userId) {
            setIsFetchUserList(true);
            fetchUserList(defaultTab, currentPage);
        }
    }, [userId]);

    const fetchUserList = (tabNumber: number, page: number = currentPage) => {
        if (tabNumber) {
            refetchFollowers({
                queryKey: ['getFollowers', userId, page],
            });
        } else {
            refetchFollowings({
                queryKey: ['getFollowings', userId, page],
            });
        }
    };

    const handlePageScroll = (e: { scrollTop: number; clientHeight: number; scrollHeight: number }) => {
        if (
            !fetchingFollowers &&
            !fetchingFollowings &&
            (followersHasNextPage || followingsHasNextPage) &&
            Math.ceil(e.scrollTop + e.clientHeight) >= e.scrollHeight
        ) {
            setIsFetchUserList(true);
            setCurrentPage(currentPage + 1);
            if (activeTab) {
                if (fetchFollowersNextPage) fetchFollowersNextPage();
            } else {
                if (fetchFollowingsNextPage) fetchFollowingsNextPage();
            }
        }
    };

    const resetUserList = (tabNumber: number) => {
        queryClient.setQueryData(tabNumber ? followersQueryKey : followingsQueryKey, (prev: any) => {
            let pageList = prev?.pages;
            const updatedData = pageList.map((page: any) => {
                return {
                    ...page,
                    data: [],
                };
            });
            return { ...prev, pages: updatedData };
        });
    };

    const updateUserList = (updateUser: FollowingUserDetail) => {
        queryClient.setQueryData(followingsQueryKey, (prev: any) => {
            let pageList = prev?.pages;
            const updatedData = pageList.map((page: any) => {
                const updatedUserData = page.data.filter((user: FollowingUserDetail) => user.id !== updateUser.id);
                return {
                    ...page,
                    data: updatedUserData,
                };
            });

            return { ...prev, pages: updatedData };
        });
    };

    const followRequest = async (updateUser: FollowingUserDetail) => {
        let formData = new FormData();
        formData.append('user_id', updateUser.id.toString());
        await followUserMutation(formData, {
            onSuccess: () => {
                if (isLoggedInUser && activeTab) {
                    setTotalCount((prev) => {
                        return {
                            ...prev,
                            following: prev.following + 1,
                        };
                    });
                }
                setHasListChanged(() => {
                    return {
                        following: true,
                        followers: true,
                    };
                });
                updateUser.following_type = followingType.direct;
            },
        });
    };

    const unfollowRequest = async (updateUser: FollowingUserDetail) => {
        let formData = new FormData();
        formData.append('user_id', updateUser.id.toString());

        await unfollowUserMutation(formData, {
            onSuccess: () => {
                if (isLoggedInUser) {
                    if (!activeTab) {
                        setTotalCount((prev) => {
                            return {
                                ...prev,
                                following: prev.following - 1,
                            };
                        });
                        updateUser.following_type = followingType.Null;
                        updateUserList(updateUser);
                    } else {
                        setTotalCount((prev) => {
                            return {
                                ...prev,
                                following: prev.following - 1,
                            };
                        });
                        updateUser.following_type = followingType.Null;
                    }
                } else {
                    updateUser.following_type = followingType.Null;
                }
                setHasListChanged(() => {
                    return {
                        following: true,
                        followers: true,
                    };
                });
            },
        });
    };

    const renderFollowerButtons = (user: FollowingUserDetail) => {
        let button = [];
        if (user.following_type === followingType.direct) {
            button.push(
                <button data-testid={`user-${user.id}-unfollow-btn`} className='followed-button' onClick={() => unfollowRequest(user)}>
                    {t('connect.unfollow')}
                </button>
            );
        } else if (user.following_type === followingType.Null) {
            button.push(
                <button data-testid={`user-${user.id}-follow-btn`} className='follow-button' onClick={() => followRequest(user)}>
                    {t('connect.follow')}
                </button>
            );
        } else {
            button.push(
                <button
                    data-testid={`user-${user.id}-unfollow-disable-btn`}
                    className='followed-button'
                    onClick={() => unfollowRequest(user)}
                    disabled
                >
                    {t('connect.unfollow')}
                </button>
            );
        }
        return button;
    };

    const handleTabChange = (tabNumber: number) => {
        setCurrentPage(firstPage);
        setIsActiveTab(tabNumber);
        if (tabNumber && (!followerUserList || hasListChanged.followers)) {
            if (followerUserList?.pages[0].data.length > 0) resetUserList(tabNumber);
            setIsFetchUserList(true);
            setHasListChanged((prev) => {
                return {
                    ...prev,
                    followers: false,
                };
            });
        } else if (!tabNumber && (!followingUserList || hasListChanged.following)) {
            if (followingUserList?.pages[0].data.length > 0) resetUserList(tabNumber);
            setIsFetchUserList(true);
            setHasListChanged((prev) => {
                return {
                    ...prev,
                    following: false,
                };
            });
        }
    };

    return (
        <>
            <FollowingDetailModalContent
                isOpen={isOpen}
                totalCount={totalCount}
                userList={activeTab ? followerUserList?.pages : followingUserList?.pages}
                activeTab={activeTab}
                pageScroll={handlePageScroll}
                fetchingFollowers={fetchingFollowers}
                fetchingFollowings={fetchingFollowings}
                renderFollowerButtons={renderFollowerButtons}
                handleDialogClose={handleDialogClose}
                handleTabChange={handleTabChange}
            />
        </>
    );
};

export default FollowingDetailModal;
