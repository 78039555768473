import { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { addNotification } from '../../../../shared/reducers/notifications/actionTypes';
import notificationService from '../../../services/notificationService';
import './notificationPermissionModal.scss';
import { isWhite } from '../../../utils/colourCheck';

type notificationModalProps = {
    setPermission: Function
}

enum CustomNotificationPermission {
    Granted = 'granted',
    Denied = 'denied',
    Default = 'default'
}

const NotificationPermissionModal: FunctionComponent<notificationModalProps> = ({ setPermission }) => {

    const [modalOpen, setModal] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user, tenant, branding } = useSelector((state: any) => state.app);
    
    const isHeaderWhite = isWhite(branding.web_navbar_background.toLowerCase());
    const buttonBgColor = isHeaderWhite ? branding.web_navbar_foreground : branding.web_navbar_background;
    const buttonFgColor = isHeaderWhite ? branding.web_navbar_background : branding.web_navbar_foreground;

    const updateUserNotificationPermission = (permission = '') => {
        notificationService.saveNotificationPermission({
            ...user,
            notification_permission: permission.length > 0 ? permission : Notification.permission
        },
            (resp: AxiosResponse) => { },
            () => {
                dispatch(
                    addNotification({
                        label: `Save User Notification Preference`,
                        text: t("general.errors.errorLoadingData"),
                        type: "danger",
                    })
                );
            }
        )
    }

    const setPermissionToDenied = () => {
        setPermission(CustomNotificationPermission.Denied);
        localStorage.setItem('userNotificationPermission', CustomNotificationPermission.Denied);
        updateUserNotificationPermission(CustomNotificationPermission.Denied);
    }

    useEffect(() => {
        if (user.id > 0) {
            if (!("Notification" in window)) {
                console.log("Browser does not support desktop notification");
            } else {
                if (Notification.permission !== localStorage.getItem('userNotificationPermission')) {
                    if (!(Notification.permission === CustomNotificationPermission.Default && localStorage.getItem('userNotificationPermission') === CustomNotificationPermission.Denied)) {
                        localStorage.setItem('userNotificationPermission', Notification.permission);
                        updateUserNotificationPermission();
                    }
                }

                if (Notification.permission === CustomNotificationPermission.Default && 
                    (localStorage.getItem("userNotificationPermission") !== CustomNotificationPermission.Denied || 
                    !localStorage.getItem('userNotificationPermission'))) {
                    setModal(true);
                }
            }
        }
    }, [user.id])

    const notifyUser = async () => {
        try {
            // Using `.then` lets this work in Edge. Using await seemed to cause it to fail
            const permissionPromise = window.Notification.requestPermission(); // Get the permission from the browser API
            permissionPromise.then(permission => {
                setPermission(permission ?? Notification.permission);
    
                if (permission === 'granted') {
                    localStorage.setItem('userNotificationPermission', CustomNotificationPermission.Granted);
                    new Notification(t('notifications.thanksForEnabling'));
                    updateUserNotificationPermission(CustomNotificationPermission.Granted);
                } else {
                    setPermissionToDenied();
                }
            });

        } catch (error) {
            console.error("Notification permission request failed:", error);
            setPermissionToDenied();
        } finally {
            setModal(false);
        }
    }

    const setNotificationPermission = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        await notifyUser();
    }

    const setInCookie = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setPermissionToDenied();
        setModal(false);
    }

    const promptLogo = tenant.feedLogo ?? "/assets/img/logo.png";
    return (
        <Modal isOpen={modalOpen} data-testid="permission-modal">
            <div className="row m-0">
                <div className="col-2 d-flex justify-content-center pe-0">
                    <img src={promptLogo} data-testid="ESG-profile-image" alt={tenant.title + ' Logo'} style={{ height: "110px", padding: "10px" }} />
                </div>
                <div className="col-10 ps-0">
                    <ModalHeader className='notification-header'>
                        <div data-testid="notification-title" className="fw-bold text-light-black">{t('notifications.promptTitle', {platform: tenant.title})}</div>
                        <div data-testid="notification-msg" className="text-light-black notification-msg">
                            {t('notifications.promptBody')}
                        </div>
                    </ModalHeader>
                    <div className="p-3 d-flex justify-content-end pt-2">
                        <Button className="me-2 reject-btn" data-testid="reject" onClick={setInCookie} style={{ borderColor: buttonBgColor, backgroundColor: buttonFgColor, color: buttonBgColor}}>{t('notifications.reject')}</Button>
                        <Button className="accept-btn" data-testid="accept" onClick={setNotificationPermission} style={{ borderColor: buttonBgColor, backgroundColor: buttonBgColor, color: buttonFgColor}}>{t('notifications.accept')}</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default NotificationPermissionModal;
