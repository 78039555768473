export default {
  "yes": "sì",
  "no": "No",
  "ok": "OK",
  "cancel": "Annulla",
  "hour": "Ora",
  "hours": "Ore",
  "week": "Settimana",
  "month": "Mese",
  "year": "Anno",
  "tyCard": "Biglietto di ringraziamento",
  "tyCards": "Biglietti di ringraziamento",
  "send": "Invia",
  "public": "Pubblico",
  "private": "Privato",
  "like": "Mi piace",
  "likes": "Mi piace",
  "comment": "Commento",
  "comments": "Commenti",
  "reply": "Rispondi",
  "share": "Condividi",
  "person": "persona",
  "people": "persone",
  "selected": "selezionato",
  "search": "Cerca",
  "errors": {
    "errorLoadingData": "Errore durante il caricamento dei dati",
    "errorLoadingPage": "Errore durante il caricamento della pagina",
    "errorReload": "Errore durante il caricamento del menu, ricarica la pagina",
    "translationError": "Errore nella traduzione del testo, riprova",
    "translationErrorLabel": "Errore di traduzione",
    "translationErrorText": "Impossibile tradurre il testo"
  },
  "success": {
    "general": "L'azione ha avuto successo"
  },
  "noData": "Sembra che qui non ci sia ancora niente!",
  "noDataDescription": "Ricontrolla più tardi per vedere gli aggiornamenti.",
  "members": "Membri",
  "translate": "Tradurre",
  "seeOriginal": "Vedi originale",
  "sort": "Ordinare",
  "aToZ": "Da A a Z",
  "zToA": "Da Z a A",
  "mostViewed": "I più visti",
  "mostLiked": "I più apprezzati",
  "oldestFirst": "Dal più vecchio al più nuovo",
  "newestFirst": "Dal più nuovo al più vecchio",
  "edited": "Modificato",
  "publishedBy": "Pubblicato da",
  "default": "Predefinito",
  "folder": "Cartella"
};