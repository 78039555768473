import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Grid, Typography, Box } from '@mui/material';
import { useSearchQuery } from '../../hooks/query/useSearchQuery';
import { SearchCard } from '../../components/searchCard/searchCard';
import Scrollbar from '../../components/common/scrollbar/scrollbar';
import SpinnerLoad from '../../components/common/spinnerLoad/spinnerLoad';
import { useTranslation } from 'react-i18next';
import { SearchResultsData } from '../../../types/interface/SearchResultsData';

export const Search = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const searchQuery = searchParams.get('search');
    const { data: searchResults, isLoading: loadingSearchResults, fetchNextPage, isFetchingNextPage } = useSearchQuery(searchQuery);
    const { t } = useTranslation();

    // Redirect to home if no search query
    if (!searchQuery) {
        navigate(-1);
    }

    const handleScroll = (e: any) => {
        let bottom = Math.round(e.scrollTop + e.clientHeight) >= e.scrollHeight;
        if (bottom && !isFetchingNextPage) {
            fetchNextPage();
        }
    };
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }} data-testid='search-page'>
            <Box>
                <Typography sx={{ fontSize: '20px', fontWeight: 400 }} data-testid='search-results-count'>
                    {searchResults?.pages[0].meta.total} {t('search.searchResultsFor')}: <b>{searchQuery}</b>
                </Typography>
            </Box>
            <Box sx={{ height: '83vh' }}>
                <Scrollbar className='me-2' onScroll={handleScroll} noScrollX={true}>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        {loadingSearchResults &&
                            Array.from({ length: 5 }).map((_, index) => <SearchCard key={index} loading={loadingSearchResults} />)}
                        {searchResults?.pages.map((page: any) => (
                            <>
                                {page.data.map((card: SearchResultsData) => (
                                    <Grid item sx={{ paddingRight: '8px', borderRadius: '10px', backgroundColor: '#f3f3f3', width: '100%' }}>
                                        <SearchCard
                                            key={card.id}
                                            loading={loadingSearchResults}
                                            imgSrc={card.preview_image}
                                            imgAlt={card.title}
                                            title={card.title}
                                            subtitle={card.breadcrumb}
                                            description={card.preview}
                                            publisher={card.publisher}
                                            published_at={card.published_at}
                                            tags={card.tags}
                                            url={card.url}
                                        />
                                    </Grid>
                                ))}
                            </>
                        ))}
                        {isFetchingNextPage && (
                            <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                        )}
                    </Grid>
                </Scrollbar>
            </Box>
        </Box>
    );
};
