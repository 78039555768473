import './loginPage.scss';

import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AxiosResponse, AxiosError } from 'axios';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';

import authService from '../../services/authService';

import { LoginConfigs, LoginFormModel, UserAuthModel } from '../../../types/types';

import LoginUserScreen from './loginUserScreen/loginUserScreen';
import LocaleSelector from '../common/localeSelector/localeSelector';

const LoginCompanyScreen = React.lazy(() => import('./loginCompanyScreen/loginCompanyScreen'));

const LoginPage: FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => {
        return state.app.user;
    });
    const matchParams: any = useParams();

    const [isLoading, setLoading] = useState(false);
    const [formNumber, setFormNumber] = useState(1);

    const [domainInfo, setDomainInfo] = useState<LoginConfigs>(new LoginConfigs());

    const [userLoginErrors, setLoginErrors] = useState<string[]>([]);

    const setNameAndPassword = (creds: LoginFormModel) => {
        if (domainInfo) {
            loginUser(new UserAuthModel(domainInfo.id, '', creds.username, creds.password));
        } else {
            alert('DomainInfo is null');
        }
    };

    const loginUser = (authModel: UserAuthModel) => {
        setLoading(true);
        authService.login(
            authModel,
            domainInfo.reference,
            () => {
                let redirectUrl = '/';
                const paramsList = window.location.href.split('?')[1];
                paramsList
                    ? paramsList.split('&').forEach((param) => {
                          const [name, value] = param.split('=');
                          if (name === 'return') {
                              redirectUrl = decodeURIComponent(value);
                          }
                      })
                    : (window.location.href = redirectUrl);
                window.location.href = redirectUrl;
                // Fill data to indicate that user is logged in
                authService.storeUserInfo(JSON.stringify(userInfo));
            },
            (err: AxiosError) => {
                let message: any = err.response;

                if (message && message.data.message === 'LOCKED') {
                    setLoginErrors([t('login.errors.account locked')]);
                    dispatch(
                        addNotification({
                            label: `Login`,
                            text: t('login.errors.account locked'),
                            type: 'danger',
                        })
                    );
                } else {
                    setLoginErrors([t('login.errors.incorrect user id or password')]);
                    dispatch(
                        addNotification({
                            label: `Login`,
                            text: t('login.errors.incorrect user id or password'),
                            type: 'danger',
                        })
                    );
                }
                setLoading(false);
            }
        );
    };

    const redirectToSso = () => {
        authService.storeUserInfo(JSON.stringify(userInfo));
        window.location.href = `${window.location.origin}/api/sso${window.location.search}`;
    };

    const shouldRedirectToSso = (data: any) => {
        if (data.is_sso) {
            let urlParams = new URLSearchParams(window.location.search);
            // If we have a SAMLRequest parameter, this is a SAML request
            if (!urlParams.get('SAMLRequest')) {
                return true;
            }
        }
        return false;
    };

    // If URL has tenant specified - load tenant and show second form
    const [urlTenantRef] = useState(matchParams?.tenantRef);

    const loginAuthTenent = async (hideLoader: boolean = false, language: string) => {
        if (urlTenantRef) {
            setLoading(true);
            await authService.authTenant(
                urlTenantRef,
                false,
                (resp: AxiosResponse) => {
                    const data = resp.data.data;
                    localStorage.setItem('is_sso_user', data.is_sso);
                    if (shouldRedirectToSso(data)) {
                        redirectToSso();
                    } else {
                        setDomainInfo(data);
                        setFormNumber(2);
                        if (hideLoader) {
                            setLoading(false);
                        }
                    }
                },
                () => {
                    setLoading(false);
                },
                language
            );
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        authService.locale(i18n.language, (success: AxiosResponse) => {});
        loginAuthTenent(true, i18n.language);
    }, [i18n.language]);

    return (
        <div id='login-page' data-testid='login-page'>
            <LocaleSelector labelColour={domainInfo.label_colour} className={'position-absolute me-4 mt-3 right0'} isDisabled={isLoading} />
            {matchParams?.tenantRef?.length > 0 && (
                <div className='row'>
                    <div className='col-lg-8 col-md-7 col-sm-12'></div>
                    <div className='col-lg-4 col-md-5 col-sm-12'>
                        <a
                            onClick={() => {
                                document.cookie = 'tenant_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                                window.location.href = '/login';
                            }}
                            data-testid='back-button'
                            className={`fa-solid fa-angle-left mt-3 ms-3 back-btn ${isLoading ? 'disable-right-panel' : ''}`}
                            style={{
                                color: domainInfo.label_colour,
                            }}
                        ></a>
                    </div>
                </div>
            )}

            {formNumber === 1 ? (
                <div className={'login-wrapper d-flex justify-content-center align-items-center h-100'}>
                    <LoginCompanyScreen setForm={setFormNumber} setFormLoading={setLoading} setDomain={setDomainInfo} redirectToSso={redirectToSso} />
                </div>
            ) : (
                <div className={'login-wrapper d-flex align-items-center h-100'}>
                    <LoginUserScreen
                        setForm={setFormNumber}
                        setNameAndPassword={setNameAndPassword}
                        userLoginErrors={userLoginErrors}
                        loginData={domainInfo}
                        loginAuthTenent={loginAuthTenent}
                        isDisabled={isLoading}
                    />
                </div>
            )}
        </div>
    );
};

export default LoginPage;
