import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import converter from 'camelcase-keys';
import authService from './authService';
import moment from 'moment';

function createClient(token: string | null = null, domain: string | null = null, stringifyRequest: boolean = true){
    const store = require('../../store');
    const state = store.default.getState();

    const onSuccess = function (response: AxiosResponse) {
        if (response.data) {
            return converter(response.data, { deep: false });
        }
        else {
            converter(response, { deep: true });
        }
    };

    const onError = function (error: AxiosError) {
        if (error.response) {
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
        } else {
            console.error('Error Message:', error.message);
        }

        return Promise.reject(
            error.response ? (error.response as any).data.message : error.message
        );
    };

    let config: AxiosRequestConfig = {
        baseURL: process.env.CMS_API_URL,
        timeout: 0, // This is the default across all requests, so we'll explicitly set to unlimited
        headers: {
            "Accept": "application/json",
            "Cache-Control": "no-cache",
            "Content-Type": "application/json"
        },
        responseType: 'json',
        transformRequest: stringifyRequest ? [
            function (data: AxiosResponse) {
                return JSON.stringify(data);
            }
        ] : []
    };

    if (token != null && config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (domain != null && domain !== '' && config.headers)
    {
        config.headers['TenantId'] = domain;
        // config.headers['tenantId'] = domain;
    }

    const axiosInstance = axios.create(config);
    // Check if user is authenticated and cookie time not expired. If so - log out 
    axiosInstance.interceptors.request.use(function (config) {
        if(token !== null) {
            const lastRequestTime = localStorage.getItem('lastRequestTime');
            if(lastRequestTime) {
                const sessionTimeout = state.app.tenant.session.session_timeout;
                const endTime = moment(lastRequestTime).add(Number(sessionTimeout), 'minutes');
                
                if(authService.getUserFromStore() && moment(lastRequestTime).isBefore(endTime)) { // token is in storage and time is okay
                    localStorage.setItem('lastRequestTime', moment().format());
                } else {
                    logoutUser(state.app.tenant.ref);
                    throw new axios.Cancel('Request canceled: user token expired.');
                }
            } else {
                localStorage.setItem('lastRequestTime', moment().format());
            }
        }
        return config;
    }, function (error) {
        console.log('Request error:', error);
        return Promise.reject(error);
    });

    // Log user out on 401 error
    axiosInstance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if(error.response && error.response.status === 401) {
            console.log('Request error 401, logging out');
            logoutUser(state.app.tenant.ref);
        }
        return Promise.reject(error);
    });

    return axiosInstance;
}

export default function Client(auth = true, stringifyRequest = true) {
    if (auth === true) {
        let userInfo = localStorage.getItem('userInfo');
        let token = '';
        let domain = '';

        if (userInfo) {
            let userInfoObj = JSON.parse(userInfo);

            token = userInfoObj.token;
            domain = userInfoObj.tenantId;
        }
        return createClient(token, domain, stringifyRequest);
    }

    return createClient(null, null, stringifyRequest);
};

function logoutUser(tenantRef: string) {
    if (tenantRef) {
        window.location.href = `/${tenantRef}/logout`;
    } else {
        window.location.href = `/login`;
    }
    authService.logout();
}