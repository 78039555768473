import { UserAuthModel } from '../../types/types';
import authProvider from '../providers/authProvider';
import { AxiosResponse, AxiosError } from 'axios';

import brandingService from './brandingService';

const authService = {
    login(loginModel: UserAuthModel, tenantRef: string, success?: Function, error?: Function) {
        authProvider.authenticateUser(
            loginModel,
            tenantRef,
            (resp: AxiosResponse) => {
                let data = resp.data.data;
                if (success) {
                    success(data);
                }
            },
            (err: AxiosError) => {
                if (error) {
                    error(err);
                }
            }
        );
    },

    logout() {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('tenantInfo');
        localStorage.removeItem('lastRequestTime');
        for (let key of Object.keys(localStorage)) {
            if (key.startsWith('formio') || key.startsWith('setUserPreferredLanguage')) {
                localStorage.removeItem(key);
            }
        }
        brandingService.removeStyleInfo();
    },

    storeUserInfo(data: string) {
        localStorage.setItem('userInfo', data);
    },

    getUserId() {
        let info = localStorage.getItem('userInfo');
        if (info) {
            return JSON.parse(info).id;
        }
        return null;
    },

    getUserFromStore() {
        let info = localStorage.getItem('userInfo');
        if (info) {
            return JSON.parse(info);
        }
        return null;
    },

    authTenant(target: string, getByDomain: boolean, success: Function, error?: Function, language: string = 'en') {
        authProvider.authTenant(target, getByDomain, success, error, language);
    },

    getUserTenantAndBrandingInfo(success: Function, error?: Function) {
        authProvider.getUserTenantAndBrandingInfo(success, error);
    },

    getTenantData(success: Function, error?: Function) {
        authProvider.getTenantData(success, error);
    },

    storeTenantInfo(data: string) {
        localStorage.setItem('tenantInfo', data);
    },

    getTenantFromStore() {
        let info = localStorage.getItem('tenantInfo');
        if (info) {
            return JSON.parse(info);
        }
        return null;
    },

    locale(langCode: string, success: Function, error?: Function) {
        authProvider.locale(langCode, success, error);
    },
};

export default authService;
