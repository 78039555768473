import React from 'react';
import { Box, List, ListItem, SxProps } from '@mui/material';
import { IconText } from '../../components/iconText/IconText';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { canNavigateToUrl, navigateToUrl, checkReactRoute } from '../../utils/linkHandler';
import {AnchorWrapper} from '../../components/anchorWrapper/anchorWrapper';

export type SubNavMenuProps = {
    linksData: {
        id: number;
        title: string;
        is_folder: boolean;
        url: string;
    }[];
    onMouseLeave?: (e: React.MouseEvent) => void;
    drawerSx?: SxProps;
};

export const SubNavMenu = (props: SubNavMenuProps) => {
    const navigate = useNavigate();
    const { linksData, drawerSx } = props;
    const branding = useSelector((state: any) => state.app.branding);
    const hoverColor = branding.web_menubar_active_background;
    const textColor = branding.web_menubar_foreground;
    const hoverTextColor = branding.web_menubar_active_foreground;

    return (
        <Box
            className={'sub-nav-menu'}
            sx={{
                width: '275px',
                filter: 'contrast(0.9)',
                height: '100%',
                position: 'fixed',
                top: 0,
                ...drawerSx,
            }}
            onMouseLeave={props.onMouseLeave}
        >
            <List>
                {linksData.map((linkData, index) => {
                    return (
                        <AnchorWrapper key={index} anchorEl={!checkReactRoute(linkData.url)} anchorProps={{ href: `${linkData.url}`, className: 'react-router-link' }} >
                            <ListItem key={index}>
                                <IconText
                                    icon={linkData.is_folder ? 'fas fa-folder-open' : ''}
                                    text={linkData.title}
                                    IconTextContainerSx={{
                                        padding: '10px',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        width: '100%',
                                        color: textColor,
                                        '&:hover': {
                                            color: hoverTextColor,
                                            backgroundColor: hoverColor,
                                        },
                                    }}
                                    handleClick={(e) => {
                                        e.stopPropagation();
                                        if (canNavigateToUrl(linkData.url)) {
                                            navigateToUrl(linkData.url);
                                        } else {
                                            const isChat = linkData.url === '/messages' || linkData.url === '/chat';
                                            navigate(isChat ? '/chat' : linkData.url);
                                        }
                                    }}
                                    textProps={{
                                        sx: {
                                            fontSize: '14px',
                                        },
                                    }}
                                    iconStyle={{
                                        fontWeight: 600,
                                        fontSize: '14px',
                                    }}
                                />
                            </ListItem>
                        </AnchorWrapper>
                    );
                })}
            </List>
        </Box>
    );
};
