import '@fortawesome/fontawesome-svg-core/styles.css';
import React, { useState } from 'react';
import { Box, Typography, SxProps, Skeleton, Tooltip } from '@mui/material';
import { IconCard } from '../IconCard/iconCard';
import { IconText } from '../iconText/IconText';
import { useSelector } from 'react-redux';
import { UseMutateFunction } from 'react-query';
import { AxiosResponse } from 'axios';
import { isWhite } from '../../utils/colourCheck';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useCopy, useCopyType } from '../../hooks/useCopy';
import { useTranslation } from 'react-i18next';
import connectService from '../../services/connectService';

export type ContactCardProps = {
    containerSx?: SxProps;
    img_url?: string;
    full_name?: string;
    job_title?: string;
    email?: string;
    phone?: string;
    location?: string;
    awards?: any[];
    manager_id?: string | null;
    loading?: boolean;
    is_favourite?: boolean;
    id?: string;
    user_id?: string;
    thread_id?: string;
    handleClick?: (id: string) => void;
    updateFavourite?: UseMutateFunction<
        AxiosResponse<any, any>,
        unknown,
        {
            id: string;
            data: any;
        },
        unknown
    >;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
};

export const ContactCard = (props: ContactCardProps) => {
    const {
        containerSx,
        img_url,
        full_name,
        job_title,
        email,
        phone,
        location,
        awards,
        loading,
        is_favourite,
        manager_id,
        handleClick,
        thread_id,
        id,
        user_id,
        updateFavourite,
    } = props;
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    const tenantBranding = useSelector((state: any) => state.app.branding);
    const highlightColor = isWhite(tenantBranding.web_navbar_background.toLowerCase())
        ? tenantBranding.web_navbar_foreground
        : tenantBranding.web_navbar_background;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [isHovered, setIsHovered] = useState({
        email: false,
        phone: false,
        location: false,
    });
    const { copyAttribute, copy }: useCopyType = useCopy();

    const handleFavouriteClick = (e: any, value: string) => {
        e.stopPropagation();
        e.preventDefault();
        let data = new FormData();
        data.append('is_favorite', value);
        updateFavourite &&
            updateFavourite({
                id: id as string,
                data,
            });
    };

    const sendMessage = (e: any, user_id: string | undefined) => {
        e.stopPropagation();
        e.preventDefault();
        if (!user_id) return;
        connectService.getThreadByUserId(parseInt(user_id), (resp: AxiosResponse) => {
            //window.location.href = '/messages/thread/' + resp.data.threadId;
            if (resp.data.threadId) {
                navigate('/chat/' + resp.data.threadId);
            } else {
                navigate('/chat/new', {
                    state: {
                        title: full_name,
                        description: job_title,
                        icon_url: img_url,
                        id: id,
                    },
                });
            }
        });
    };

    const handleOrgChartClick = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        let url = pathname.includes('favourites') ? '/connect/favourites' : '/connect/people';
        navigate(`${url}/${id}`, { state: { showOrgChart: true } });
    };

    const handleHover = (attribute: string, value: boolean) => {
        setIsHovered((prev) => ({
            ...prev,
            [attribute]: value,
        }));
    };

    if (loading) {
        return (
            <Box
                sx={{
                    height: '206.04px',
                    borderRadius: '12px',
                    background: '#FFFFFF',
                    boxShadow: '0px 5px 5px 0px #00000026',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    ...containerSx,
                }}
                data-testid='contact-card-loading'
            >
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Skeleton variant='circular' width={75} height={75} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginTop: '5px' }}>
                        <Box>
                            <Skeleton variant='text' width={100} height={20} />
                            <Skeleton variant='text' width={100} height={20} />
                            <Skeleton variant='text' width={100} height={20} />
                        </Box>
                        <Box>
                            <Skeleton variant='text' width={100} height={20} />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ padding: '0 10px', display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                    <Skeleton variant='rectangular' height={20} />
                    <Skeleton variant='rectangular' height={20} />
                    <Skeleton variant='rectangular' height={20} />
                </Box>
            </Box>
        );
    }

    return (
        <Link
            to={is_favourite ? `/connect/people/${id}?starred` : `/connect/people/${id}`}
            rel='noopener noreferrer'
            style={{
                textDecoration: 'none',
                color: 'inherit',
            }}
        >
            <Box
                sx={{
                    width: '438.6px',
                    height: '206.04px',
                    borderRadius: '12px',
                    background: '#FFFFFF',
                    boxShadow: '0px 5px 5px 0px #00000026',
                    padding: '14px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    cursor: 'pointer',
                    ...containerSx,
                }}
                data-testid='contact-card'
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconCard
                        sx={{
                            padding: 0,
                            direction: 'ltr',
                            fontSize: '16px',
                            fontWeight: 600,
                            fontFamily: 'Gilroy',
                        }}
                        icon={img_url}
                        iconStyle={{
                            width: '75px',
                            height: '75px',
                        }}
                        className='d-flex'
                    >
                        <Box sx={{ paddingTop: '6px' }}>
                            <Typography sx={{ fontWeight: 600, fontSize: '18px', lineHeight: '1.2' }}>{full_name}</Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '1.2' }}>{job_title}</Typography>
                            <Box sx={{ pt: '3px', display: 'flex', gap: '7px' }}>
                                {awards?.slice(0, 6).map((award: any) => {
                                    return (
                                        <div key={award?.id}>
                                            <Tooltip
                                                title={award?.title}
                                                slotProps={{
                                                    popper: {
                                                        modifiers: [
                                                            {
                                                                name: 'offset',
                                                                options: {
                                                                    offset: [0, 1],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                }}
                                                arrow
                                            >
                                                <img
                                                    data-testid='contact-user-award-image'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                    src={award.badge_image ?? award.preview_image}
                                                    className='thumb22'
                                                    alt={award.badge_image ? 'award badge image' : 'award preview image'}
                                                    id={`tooltip_${award.id}`}
                                                    data-tooltip-content={award.title}
                                                    data-iscapture='true'
                                                />
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                                {awards && awards.length > 6 && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '25px',
                                            height: '25px',
                                            borderRadius: '50%',
                                            backgroundColor: '#1E3050',
                                        }}
                                    >
                                        <span style={{ fontSize: '12px', color: '#fff', fontWeight: 600, letterSpacing: '1px' }}>
                                            +{awards.length - 6}
                                        </span>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </IconCard>
                    <Box sx={{ display: 'flex', gap: '10px', paddingTop: '2px', i: { lineHeight: '1.2', fontSize: '18px', cursor: 'pointer' } }}>
                        {is_favourite ? (
                            <i
                                className='fa-solid fa-star'
                                onClick={(e) => {
                                    handleFavouriteClick(e, '0');
                                }}
                                style={{ color: highlightColor }}
                            />
                        ) : (
                            <i
                                className='fa-regular fa-star'
                                onClick={(e) => {
                                    handleFavouriteClick(e, '1');
                                }}
                                style={{ color: highlightColor }}
                            />
                        )}
                        {tenantInfo && tenantInfo.messagesEnabled && (
                            <i
                                className='fas fa-paper-plane'
                                style={{ color: highlightColor }}
                                onClick={(e) => {
                                    sendMessage(e, user_id);
                                }}
                            />
                        )}
                        {manager_id && (
                            <Tooltip title={t('connect.chart')} placement='top-end'>
                                <i className='fa-light fa-sitemap' style={{ color: highlightColor }} onClick={handleOrgChartClick} />
                            </Tooltip>
                        )}
                    </Box>
                </Box>
                <Box sx={{ padding: '0 10px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                        onMouseEnter={() => handleHover('email', true)}
                        onMouseLeave={() => handleHover('email', false)}
                    >
                        {email ? (
                            <a
                                href={`mailto:${email}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <IconText
                                    icon='fa-light fa-envelope'
                                    text={email ?? ''}
                                    iconStyle={{
                                        width: '20px',
                                    }}
                                    textProps={{
                                        sx: {
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                        },
                                    }}
                                />
                            </a>
                        ) : (
                            <IconText
                                icon='fa-light fa-envelope'
                                text={email ?? ''}
                                iconStyle={{
                                    width: '20px',
                                }}
                            />
                        )}
                        {copyAttribute === 'email' ? (
                            <i className='fa-light fa-check' />
                        ) : (
                            <i
                                className='fa-light fa-copy'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    copy(email ?? '', 'email');
                                }}
                                style={{ display: 'inline-flex', alignItems: 'center', visibility: email && isHovered.email ? 'visible' : 'hidden' }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                        onMouseEnter={() => handleHover('phone', true)}
                        onMouseLeave={() => handleHover('phone', false)}
                    >
                        {phone ? (
                            <a
                                href={`tel:${phone}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <IconText
                                    icon='fa-light fa-phone'
                                    text={phone ?? ''}
                                    iconStyle={{
                                        width: '20px',
                                    }}
                                    textProps={{
                                        sx: {
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                        },
                                    }}
                                />
                            </a>
                        ) : (
                            <IconText
                                icon='fa-light fa-phone'
                                text={phone ?? ''}
                                iconStyle={{
                                    width: '20px',
                                }}
                            />
                        )}
                        {copyAttribute === 'phone' ? (
                            <i className='fa-light fa-check' />
                        ) : (
                            <i
                                className='fa-light fa-copy'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    copy(phone ?? '', 'phone');
                                }}
                                style={{ display: 'inline-flex', alignItems: 'center', visibility: phone && isHovered.phone ? 'visible' : 'hidden' }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                        onMouseEnter={() => handleHover('location', true)}
                        onMouseLeave={() => handleHover('location', false)}
                    >
                        <IconText
                            icon='fa-light fa-location-dot'
                            text={location ?? ''}
                            iconStyle={{
                                width: '20px',
                            }}
                            textProps={{
                                sx: {
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                },
                            }}
                            IconTextContainerSx={{
                                maxWidth: '95%',
                            }}
                        />
                        {copyAttribute === 'location' ? (
                            <i className='fa-light fa-check' />
                        ) : (
                            <i
                                className='fa-light fa-copy'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    copy(location ?? '', 'location');
                                }}
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    visibility: location && isHovered.location ? 'visible' : 'hidden',
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Link>
    );
};
