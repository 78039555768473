export default {
    "sharePost": "Comienza a compartir...",
    "what do you have to say": "Que tienes que decir",
    "emptyField": "No se puede guardar una publicación vacía.",
    "socialPost": "Publicación ",
    "postedFail": "La publicación no ha podido ser guardada, inténtelo de nuevo más tarde.",
    "mediaFail": "Se ha producido un error al subir los archivos, por favor inténtelo de nuevo más tarde.",
    "close": "Cerrar",
    "mediaTooBig": "Algunos ficheros sobrepasaron el máximo permitido y no pudieron ser subidos. Tamaño máximo por fichero ",
    "mentionUsers": "Mencionar usuarios",
    "mentionUsersFail": "No se pudieron encontrar los usuarios mencionados, por favor, inténtalo de nuevo más tarde.",
    "userUnavailable": "Usuario no disponible",
    "postUnavailable": "¡Vaya! No podemos encontrar la publicación que estás buscando.",

    "you": "Tú",
    "single_you": "Volviste",
    
    "you_reposted_this": "a publicar esto",
    "singular_reposted_this": "volvió a publicar esto",
    "we_reposted_this": "volvieron a publicar esto",
    "they_reposted_this": "volvieron a publicar esto",

    "and": "y",
    "more": "{{dynamicValue}} más",
    "users": "Usuarios publicados nuevamente",
    "repostFailMessage": "Ya has vuelto a publicar esto",
    "repost": "Volver a publicar",
    "repostWithYourThoughts": "Vuelve a publicar con tus pensamientos",
    "shareYourThoughtsOnThisPost": "Comparte tus pensamientos sobre esta publicación",
    "repostWithoutThoughts": "Comparte la publicación de {{dynamicValue}} con tus seguidores",
    "repostSuccessMessage": "Reposteado exitosamente.",
    "editPostTimeLimit": "Ya no puedes editar esta publicación, ya que el tiempo ha expirado.",
    "editPostSuccessMessage": "Publicación editada con éxito."
};