import general from "./general";
import sessionTimeout from "./sessionTimeout";
import navigation from "./navigation";
import cards from "./cards";
import partials from "./partials";
import login from "./login";
import registration from "./registration";
import feed from "./feed";
import awards from "./awards";
import socialPost from "./socialPost";
import connect from "./connect";
import chat from "./chat";
import search from "./search";
import notifications from "./notifications";

export default {
    general: general,
    sessionTimeout: sessionTimeout,
    navigation: navigation,
    cards: cards,
    partials: partials,
    login: login,
    registration: registration,
    feed: feed,
    awards: awards,
    socialPost: socialPost,
    connect: connect,
    chat: chat,
    search: search,
    notifications: notifications,
};
