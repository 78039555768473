import React from 'react';
import { Grid, Box } from '@mui/material';

export type CustomTemplateSevenProps = {
    components: any[];
};

export const CustomTemplateSeven = (props: CustomTemplateSevenProps) => {
    const { components } = props;

    return (
        <Grid container data-template={7} spacing={2} data-testid='custom-template-7'>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box
                    data-pos={1}
                    sx={{
                        maxHeight: components[1]?.key === 'banner' ? '100%' : '45vh',
                    }}
                >
                    {components[1]}
                </Box>
                <Box data-pos={2} sx={{ maxHeight: '10vh', overflow: 'auto' }}>
                    {components[2]}
                </Box>
                <Box
                    data-pos={3}
                    sx={{
                        height: '100%',
                    }}
                >
                    {components[3]}
                </Box>
            </Grid>
        </Grid>
    );
};
