import React, { FunctionComponent, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Card, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FeedPostActionTypes, FeedSocialPost, TYBrandingData } from '../../../../../types/types';
import classnames from 'classnames';
import socialPostsService from '../../../../services/socialPostsService';
import { getLikesStr, getCommentsStr } from '../../feedUtils';
import { addNotification } from '../../../../../shared/reducers/notifications/actionTypes';
import { LikeBtnHeart, LikeBtnHeartFilled, ShareArrowIcon, CommentCloudIcon, TotalLikesIcon, TotalCommentsIcon } from '../../../../../icons/index';
import ItemHeader from '../itemHeader';
import SocialPostLikesModal from './socialPostLikesModal';
import SocialPostComments from './socialPostComments';
import Collapse from '../../../common/collapse/collapse';
import FilesDisplay from '../../../common/filesDisplay/filesDisplay';
import FeedPostActions from '../../../../../web/components/feedPage/itemTypes/socialPost/feedPostActions';
import { SharePopover } from '../../../sharePopover/sharePopover';
import { Box, Skeleton } from '@mui/material';
import { IconText } from '../../../../components/iconText/IconText';
import { Link } from '@mui/material';
import { RepostedUsersModal } from '../../../repostedUsersModal/repostedUsersModal';
import CreateSocialPost from '../../createSocialPost';
import { useTranslateQuery } from '../../../../hooks/query/useTranslateQuery';
import { handleTranslationError } from '../../../../utils/translationErrorHandle';
import { RepostItem } from '../../../repostItem/repostItem';
import { cloneDeep } from 'lodash';
import { Link as ReactRouterLink } from 'react-router-dom';

const renderHtml = require('html-react-parser');

type SocialPostItemProps = {
    item: FeedSocialPost;
    tyBranding: TYBrandingData;
    setItem: Function;
    setLiked: Function;
    removeItem: Function;
    removeContentByUser?: Function;
    addRepostToFeed?: Function;
    isLastItem?: boolean;
    socialPostPage?: boolean;
    isLoading?: boolean;
    feedData?: Array<any>;
    setFeedData?: Function;
    repostWithThoughts?: boolean;
    updateItem?: Function;
    postsPage?: boolean;
};

enum TyCardTypes {
    public = 'TYC',
    private = 'P2P',
}

const MaxHeight = 300;

const SocialPostItem: FunctionComponent<SocialPostItemProps> = ({
    item,
    isLastItem,
    tyBranding,
    setItem,
    setLiked,
    removeItem,
    removeContentByUser,
    addRepostToFeed,
    socialPostPage,
    isLoading,
    feedData,
    setFeedData,
    repostWithThoughts,
    updateItem,
    postsPage,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => state.app.user);

    const [likesModalOpen, setLikesModal] = useState(false);
    const [repostModalOpen, setRepostModalOpen] = useState(false);
    const [replyOpen, setReplyOpen] = useState(false);
    const [dotsModalOpen, setDotsOpen] = useState(false);
    const [isTooTall, setTooTall] = useState(false);
    const [isCollapsed, setCollapsed] = useState(false);
    const [originalHeight, setOriginalHeight] = useState(MaxHeight);
    const [openReshareWithThoughtsModal, setOpenReshareWithThoughtsModal] = useState<boolean>(false);
    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const features = useSelector((state: any) => state.app.features);
    const displayTranslateBtn = features?.translations?.user_content;
    const [spToTranslateID, setSpToTranslateID] = useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [translatedSPText, setTranslatedSPText] = useState<{
        [key: number]: {
            originalText: string;
            translatedText: string;
        };
    }>({});
    const [clickedTranslateText, setclickedTranslateText] = useState<{
        [key: number]: boolean | null;
    }>({});
    const containerRef = useRef<any>(null);
    const textRef = useRef<any>(null);
    const [shareAnchorEl, setShareAnchorEl] = useState<any>(null);
    const openSharePopover = Boolean(shareAnchorEl);
    const [isHovered, setIsHovered] = useState(false);
    const translateType = item?.type === 'repost' ? 'repost' : 'socialpost';
    const { isLoading: translatingText } = useTranslateQuery(
        spToTranslateID,
        translateType,
        languagetoTranslate,
        (response) => {
            if (!response || !spToTranslateID) return;
            const newPost = cloneDeep(item);
            const translatedText = response.data.translated.message;
            setTranslatedSPText((prev) => {
                return {
                    ...prev,
                    [item.model.id]: {
                        originalText: response.data.original.message,
                        translatedText: translatedText,
                    },
                };
            });
            newPost.model.message = translatedText;
            setItem(newPost);
            setSpToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: spToTranslateID,
                setTranslateID: setSpToTranslateID,
                setClickedTranslate: setclickedTranslateText,
                dispatch,
                t,
            });
        }
    );
    const [clickedTranslateAward, setClickedTranslateAward] = useState<{ [key: number]: boolean | null }>({});
    const [translatedAward, setTranslatedAward] = useState<{
        [key: number]: {
            originalText: {
                message: string;
                manager_message?: string;
            };
            translatedText: {
                message: string;
                manager_message?: string;
            };
            originalImageTitle: string;
            translatedImageTitle: string;
        };
    }>({});
    const [awardToTranslateID, setAwardToTranslateID] = useState<number | null>(null);
    const [textHeight, setTextHeight] = useState<number>(50);
    const openFeedPostActions = Boolean(anchorEl);
    const { isLoading: translatingAward } = useTranslateQuery(
        awardToTranslateID,
        'thankyoucard',
        languagetoTranslate,
        (response?: {
            data?: {
                translated?: { message: string; manager_message?: string };
                original: { message: string; manager_message?: string };
                related_translations?: { image: { translated: { title: string }; original: { title: string } } };
            };
        }) => {
            if (!response?.data?.translated || !awardToTranslateID) return;
            const translated = response.data.translated;
            const original = response.data.original;
            const originalImageTitle = response.data.related_translations?.image?.original;
            const translatedImageTitle = response.data.related_translations?.image?.translated;
            setTranslatedAward({
                [awardToTranslateID]: {
                    originalText: { message: original.message, manager_message: original.manager_message },
                    translatedText: { message: translated.message, manager_message: translated.manager_message },
                    originalImageTitle: originalImageTitle?.title ?? '',
                    translatedImageTitle: translatedImageTitle?.title ?? '',
                },
            });
            setAwardToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: awardToTranslateID,
                setTranslateID: setAwardToTranslateID,
                setClickedTranslate: setClickedTranslateAward,
                dispatch,
                t,
            });
        }
    );

    const displayEllipsis =
        displayTranslateBtn &&
        item?.model?.locale &&
        (item?.model?.locale !== languagetoTranslate || clickedTranslateAward?.[item?.model?.thank_you_card_id]);

    const handleTranslate = (id: number, translateText: boolean) => {
        setclickedTranslateText((prev) => {
            return {
                ...prev,
                [id]: translateText,
            };
        });
        if (!translateText) {
            const currentPost = cloneDeep(item);
            const originalText = translatedSPText[item.model.id].originalText;
            currentPost.model.message = originalText;
            setItem(currentPost);
            setDotsOpen(false);
            setAnchorEl(null);
            return;
        }
        setSpToTranslateID(id);
        setAnchorEl(null);
        setDotsOpen(false);
    };

    const handleTranslateAward = (id: number, translate: boolean) => {
        setClickedTranslateAward({ ...clickedTranslateAward, [id]: translate });
        if (!translate) {
            return;
        }
        setAwardToTranslateID(id);
    };

    useEffect(() => {
        if (textRef.current && !translatingText) {
            setTextHeight(textRef.current.offsetHeight);
        }
    }, [translatingText]);

    useLayoutEffect(() => {
        // Check if container is too tall
        if (!isLoading) {
            const el: any = containerRef.current;
            if (el.clientHeight <= MaxHeight) return;

            setOriginalHeight(el.clientHeight);
            setTooTall(true);
        }
    }, []);

    const getMessageHtml = () => {
        if (!item.model.message) {
            return '';
        }
        return renderHtml(
            isTYcard() && clickedTranslateAward[item.model.thank_you_card_id]
                ? translatedAward[item.model.thank_you_card_id].translatedText.message
                : item.model.message
        );
    };

    const renderText = () => {
        if (!isTooTall) return getMessageHtml();

        const heightData = {
            minHeight: MaxHeight,
            maxHeight: originalHeight,
        };
        return (
            <React.Fragment>
                <Collapse isOpen={isCollapsed} className={'article-body'} heightData={heightData}>
                    {getMessageHtml()}
                </Collapse>
                <span
                    className='c-pointer text-primary text-hover'
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setCollapsed(!isCollapsed);
                    }}
                >
                    {isCollapsed ? t('feed.hide') : t('feed.expand')}
                </span>
            </React.Fragment>
        );
    };

    const handleLike = () => {
        const liked = !item.model.is_liked;

        setLiked(item.model.id, liked);
        socialPostsService.likePost(
            item.model.id,
            liked,
            () => {},
            () => {
                dispatch(
                    addNotification({
                        label: `Post Like/Dislike`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
                setLiked(item.model.id, !liked);
            }
        );
    };

    const isTYcard = () => {
        return item.model.post_type === TyCardTypes.public || item.model.post_type === TyCardTypes.private;
    };

    const getIconCallback = (e: any) => {
        if (!isTYcard() && dotsModalOpen) {
            setDotsOpen(false);
        } else {
            setAnchorEl(e.currentTarget);
            isTYcard() ? undefined : setDotsOpen(true);
        }
    };

    const navigateToUser = (e: any, userId: number) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/connect/people/${userId}`);
    };

    const handleClickShare = (e: any) => {
        e.stopPropagation();
        setShareAnchorEl(e.currentTarget);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const renderPost = () => {
        return (
            <Box
                data-testid='social-post-card'
                sx={{ cursor: 'default', overflowX: 'hidden' }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (isTYcard() || socialPostPage) {
                        e.preventDefault();
                    }
                }}
            >
                <ItemHeader
                    communityGroup={item.model.community_group}
                    imgUrl={item.model.author.image_url}
                    headText={item.model.author.full_name}
                    jobTitle={item.model.author.jobtitle}
                    time={item.model.created_at_humans ?? ''}
                    updatedTime={item.model.updated_at ?? ''}
                    createdTime={item.model.created_at ?? ''}
                    iconClass={isTYcard() && !displayEllipsis ? '' : 'fal fa-ellipsis'}
                    iconClickCallback={getIconCallback}
                    contact_id={item.model.author ? item.model.author.contact_id : null}
                    id={item.model.id}
                />
                <FeedPostActions
                    item={item}
                    removeItem={removeItem}
                    updateItem={socialPostPage || postsPage ? updateItem : addRepostToFeed}
                    removeContentByUser={removeContentByUser}
                    canDeletePost={item.model.author.id === userInfo.id}
                    clickedTranslateTextObj={isTYcard() ? clickedTranslateAward : clickedTranslateText}
                    handleTranslate={isTYcard() ? handleTranslateAward : handleTranslate}
                    translatedText={isTYcard() ? translatedAward : translatedSPText}
                    open={openFeedPostActions}
                    isTYCard={isTYcard()}
                    type={isTYcard() ? FeedPostActionTypes.P2P : FeedPostActionTypes.SOCIAL_POST}
                    anchorEl={anchorEl}
                    handleClose={() => {
                        setAnchorEl(null);
                        setDotsOpen(false);
                    }}
                />
                <div
                    data-testid='social-post-message'
                    className='social-post-message font-secondary-text text-black mt-3 mb-2'
                    ref={containerRef}
                    onClick={(e: any) => {
                        if (e.target.tagName === 'A') {
                            e.preventDefault();
                            e.stopPropagation();

                            const href = e.target.href;
                            const link = new URL(href);
                            const currentUrl = new URL(window.location.href);
                            if (currentUrl.hostname === link.hostname) {
                                window.location.href = link.href;
                            } else {
                                window.open(link.href, '_blank');
                            }
                        }
                    }}
                >
                    {(translatingText && spToTranslateID === item.model.id) ||
                    (translatingAward && awardToTranslateID === item.model.thank_you_card_id) ? (
                        <Skeleton variant='text' width='100%' height={textHeight} animation='wave' />
                    ) : (
                        <Box
                            ref={textRef}
                            sx={{
                                cursor: 'text',
                            }}
                        >
                            {renderText()}
                        </Box>
                    )}
                </div>
                <FilesDisplay files={item.model.files} socialPost={!isTYcard()}>
                    {isTYcard() ? (
                        translatingAward && awardToTranslateID === item.model.thank_you_card_id ? (
                            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'end' }}>
                                <Skeleton variant='text' width='95%' height='50px' />
                            </Box>
                        ) : (
                            <h3 className='mb-3 text-light-black mt-3 font-primary-text'>
                                {item.model.recipients.map((element: any) => {
                                    return (
                                        <ReactRouterLink to={`/connect/people/${element.contact_id}`} className='react-router-link'>
                                            <span
                                                data-testid='award-sender-name'
                                                className='sender-name c-pointer'
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                @{element.full_name ?? ''}&nbsp;
                                            </span>
                                        </ReactRouterLink>
                                    );
                                })}
                                {`${t('feed.received', {
                                    text: tyBranding.tycText.btn.value,
                                })} ${t('awards.for')} ${
                                    clickedTranslateAward[item.model.thank_you_card_id]
                                        ? translatedAward[item.model.thank_you_card_id]?.translatedImageTitle
                                        : item.model.image_title
                                }`}
                            </h3>
                        )
                    ) : (
                        ''
                    )}
                </FilesDisplay>
                {item.model.manager_name && item.model.manager_name !== '' && item.model.manager_message !== '' ? (
                    <div className={'social-post-message mt-3'}>
                        <span style={{ fontSize: '1.125rem' }} className={'text-light-black sender-name font-primary-text'}>
                            <strong>{t('feed.messageToManager')}</strong>
                        </span>
                        <div className='mt-2 border-bottom' />
                        <div className={'mt-2'}>
                            <span className='sender-name text-light-black font-secondary-text'>{item.model.manager_message}</span>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {repostWithThoughts && item.model.repost_parent && (
                    <RepostItem
                        item={item.model.repost_parent}
                        repostLink={`/social/post/${item.model.repost_parent.id}`}
                        handleRepostClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                )}
            </Box>
        );
    };

    if (isLoading) {
        return (
            <Card data-testid='social-post-item-card' className={`social-post-item p-3 item-container`} style={{ width: '900px' }}>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Skeleton variant='circular' width={65} height={65} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '60px', mt: '5px' }}>
                        <Skeleton variant='text' width={100} sx={{ fontSize: '14px' }} />
                        <Skeleton variant='text' width={50} sx={{ fontSize: '12px' }} />
                        <Skeleton variant='text' width={75} sx={{ fontSize: '12px' }} />
                    </Box>
                </Box>
                <Box sx={{ mt: '10px' }}>
                    <Skeleton variant='rectangular' width='100%' height={300} />
                </Box>
                <Box sx={{ mt: '10px', display: 'flex', width: '100%', gap: '20px' }}>
                    <Skeleton variant='rectangular' width='100%' height={30} />
                    <Skeleton variant='rectangular' width='100%' height={30} />
                    <Skeleton variant='rectangular' width='100%' height={30} />
                </Box>
            </Card>
        );
    }
    let repostUsers: any[] = [];
    if (item.model?.repost_users?.data?.length > 0) {
        repostUsers = item.model.repost_users.data?.map((user: any) => ({
            avatarUrl: user.avatar_url,
            fullName: user.full_name,
            contactId: user.contact_id,
            isUser: userInfo.connect_id === user.contact_id,
        }));
        let isUserIndex = repostUsers.findIndex((contact: any) => contact.isUser === true);
        if (isUserIndex !== -1) {
            let currentRepostUser = repostUsers.splice(isUserIndex, 1)[0];
            repostUsers.unshift(currentRepostUser);
        }
    }

    const renderRepostText = (users: any[]) => {
        let text = (
            <>
                {users[0].isUser ? (
                    <Link
                        href={`/connect/people/${users[0].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {t('socialPost.single_you')}
                    </Link>
                ) : (
                    <Link
                        href={`/connect/people/${users[0].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[0].fullName}
                    </Link>
                )}{' '}
                {users[0].isUser ? t('socialPost.you_reposted_this') : t('socialPost.singular_reposted_this')}
            </>
        );

        let htmlText = <p style={{ fontSize: '16px', marginTop: '4px', marginBottom: '8px' }}>{text}</p>;
        if (users.length > 3) {
            return (
                <p style={{ fontSize: '16px', marginTop: '4px', marginBottom: '8px' }}>
                    {users[0].isUser ? (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {t('socialPost.you')}
                        </Link>
                    ) : (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {users[0].fullName}
                        </Link>
                    )}
                    ,{' '}
                    <Link
                        href={`/connect/people/${users[1].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[1].fullName}
                    </Link>
                    ,{' '}
                    <Link
                        href={`/connect/people/${users[2].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[2].fullName}
                    </Link>{' '}
                    {t('socialPost.and')}
                    <button
                        onClick={() => setRepostModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                            fontSize: '16px',
                            fontWeight: '600',
                            textDecoration: isHovered ? 'underline' : 'none',
                            color: 'black',
                            cursor: 'pointer',
                            background: 'none',
                            border: 'none',
                        }}
                    >
                        {t('socialPost.more', { dynamicValue: item.model.repost_count - 3 })}
                    </button>
                    {users[0].isUser ? t('socialPost.we_reposted_this') : t('socialPost.they_reposted_this')}
                </p>
            );
        } else if (users.length === 3) {
            return (
                <p style={{ fontSize: '16px', marginTop: '4px', marginBottom: '8px' }}>
                    {users[0].isUser ? (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {t('socialPost.you')}
                        </Link>
                    ) : (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {users[0].fullName}
                        </Link>
                    )}
                    ,{' '}
                    <Link
                        href={`/connect/people/${users[1].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[1].fullName}
                    </Link>{' '}
                    {t('socialPost.and')}{' '}
                    <Link
                        href={`/connect/people/${users[2].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[2].fullName}
                    </Link>{' '}
                    {users[0].isUser ? t('socialPost.we_reposted_this') : t('socialPost.they_reposted_this')}
                </p>
            );
        } else if (users.length === 2) {
            return (
                <p style={{ fontSize: '16px', marginTop: '4px', marginBottom: '8px' }}>
                    {users[0].isUser ? (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {t('socialPost.you')}
                        </Link>
                    ) : (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {users[0].fullName}
                        </Link>
                    )}{' '}
                    {t('socialPost.and')}{' '}
                    <Link
                        href={`/connect/people/${users[1].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[1].fullName}
                    </Link>{' '}
                    {users[0].isUser ? t('socialPost.we_reposted_this') : t('socialPost.they_reposted_this')}
                </p>
            );
        }
        return htmlText;
    };

    const handleReshareWithThoughtsClick = () => {
        setOpenReshareWithThoughtsModal(true);
        setShareAnchorEl(null);
    };

    return (
        <React.Fragment>
            {openReshareWithThoughtsModal && (
                <CreateSocialPost
                    repostModal={openReshareWithThoughtsModal}
                    setRepostModal={setOpenReshareWithThoughtsModal}
                    feedDataMethod={addRepostToFeed}
                    userInfo={userInfo}
                    repost={true}
                    repostItem={item}
                />
            )}
            <SocialPostLikesModal
                postId={item?.model?.id}
                isOpen={likesModalOpen}
                toggle={() => {
                    setLikesModal(!likesModalOpen);
                }}
            />
            <RepostedUsersModal
                postId={item?.model?.id}
                isOpen={repostModalOpen}
                toggle={() => {
                    setRepostModalOpen(!repostModalOpen);
                }}
            />
            {item.model?.repost_users?.data?.length > 0 ? (
                <Card
                    data-testid='social-post-item-card'
                    className={`social-post-item p-3 pb-0 pt-0 item-container ${isLastItem ? '' : 'mb-3'}`}
                    style={{ maxWidth: socialPostPage ? '900px' : '100%', width: socialPostPage ? '650px' : '100%' }}
                >
                    <Box className='border-bottom d-flex flex-row align-items-center ' style={{ paddingTop: '8px' }}>
                        <IconText
                            icon={repostUsers.slice(0, 3).map((user: any, index: number) => (
                                <Link href={`/connect/people/${user.contactId}`}>
                                    <img
                                        src={user.avatarUrl}
                                        style={{
                                            borderRadius: '50%',
                                            boxShadow: 'inset 0px 0px 0px 20px #fff',
                                            height: '30px',
                                            marginLeft: index === 0 ? '0' : '-20px',
                                            position: 'relative',
                                            width: '30px',
                                        }}
                                    />
                                </Link>
                            ))}
                            text={''}
                            children={renderRepostText(repostUsers)}
                        />
                    </Box>
                    {isTYcard() && item.additional_model && item.additional_model.social_post && item.additional_model.social_post ? (
                        <a
                            data-testid='award-link'
                            href={`/awards/${item.additional_model.social_post.thank_you_card.id}`}
                            className='d-block text-decoration-none'
                        >
                            {renderPost()}
                        </a>
                    ) : (
                        renderPost()
                    )}
                    <Row className='pb-2'>
                        <Col lg={6} xs={6} className='d-flex align-items-center'>
                            {item.model.likes && item.model.likes > 0 ? (
                                <span
                                    data-testid='total-likes'
                                    className='me-0 c-pointer d-flex align-items-center'
                                    onClick={() => setLikesModal(true)}
                                >
                                    <TotalLikesIcon className='like-btn me-2' />
                                    <span className='cursor-pointer mr0 d-inline font-secondary-text'>
                                        {`${item.model.likes} ${getLikesStr(item.model.likes ?? 0)}`}
                                    </span>
                                </span>
                            ) : (
                                ''
                            )}
                        </Col>
                        <Col lg={6} xs={6} className='d-flex justify-content-end'>
                            {item.model.comments && item.model.comments > 0 ? (
                                <span className='d-flex align-items-center font-secondary-text'>
                                    <TotalCommentsIcon className='comment-btn-primary comment-btn me-2' />
                                    {`${item.model.comments} ${getCommentsStr(item.model.comments ?? 0)}`}
                                </span>
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>

                    <Row className='border-top py-2'>
                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            <div data-testid='like-btn' onClick={handleLike} className='d-flex justify-content-center align-items-center pe-5'>
                                {item.model.is_liked ? (
                                    <LikeBtnHeartFilled className='c-pointer svg-img' data-testid='like-btn-icon-filled' />
                                ) : (
                                    <LikeBtnHeart className='c-pointer svg-img svg-stroke' data-testid='like-btn-icon' />
                                )}
                                <span className='ms-2 me-0 c-pointer text-light-black media-option' style={{ fontWeight: 'bold' }}>
                                    {t('general.like')}
                                </span>
                            </div>
                        </Col>

                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            <div
                                data-testid='comment-btn'
                                onClick={() => setReplyOpen(!replyOpen)}
                                className='d-flex justify-content-center align-items-center'
                            >
                                <Box sx={{ color: 'red' }}>
                                    <CommentCloudIcon className='comment-btn c-pointer svg-img svg-stroke' />
                                </Box>
                                <span className={classnames('ms-2 me-0 text-light-black c-pointer media-option')} style={{ fontWeight: 'bold' }}>
                                    {t('general.comment')}
                                </span>
                            </div>
                        </Col>

                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            {userInfo.spEnabled && item.model.can_repost && (
                                <>
                                    <span
                                        data-testid='share-btn'
                                        className='ms-1 me-0 c-pointer feed-share-btn d-flex justify-content-center align-items-center ps-5'
                                        onClick={handleClickShare}
                                    >
                                        <ShareArrowIcon className='c-pointer me-1 page-footer-option svg-img svg-stroke' />
                                        <span className='page-footer-option ms-2 text-light-black' style={{ fontWeight: 'bold' }}>
                                            {t('general.share')}
                                        </span>
                                    </span>
                                    <SharePopover
                                        popoverProps={{
                                            open: openSharePopover,
                                            anchorEl: shareAnchorEl,
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                            onClose: () => {
                                                setShareAnchorEl(null);
                                            },
                                        }}
                                        postId={item.model.id}
                                        authorName={item.model.author.full_name}
                                        repostHandler={addRepostToFeed}
                                        handleThoughtsClick={handleReshareWithThoughtsClick}
                                        setShareAnchorEl={setShareAnchorEl}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>

                    <SocialPostComments item={item} setItem={setItem} addReplyOpen={replyOpen} setReplyOpen={setReplyOpen} />
                </Card>
            ) : (
                <Card
                    data-testid='social-post-item-card'
                    className={`social-post-item p-3 pb-0 pt-0 item-container ${isLastItem ? '' : 'mb-3'}`}
                    style={{ maxWidth: socialPostPage ? '900px' : '100%', width: socialPostPage ? '650px' : '100%' }}
                >
                    {isTYcard() && item.additional_model && item.additional_model.social_post && item.additional_model.social_post ? (
                        <a
                            data-testid='award-link'
                            href={`/awards/${item.additional_model.social_post.thank_you_card.id}`}
                            className='d-block text-decoration-none'
                        >
                            {renderPost()}
                        </a>
                    ) : (
                        renderPost()
                    )}
                    <Row className='py-2'>
                        <Col lg={6} xs={6} className='d-flex align-items-center'>
                            {item.model.likes && item.model.likes > 0 ? (
                                <span
                                    data-testid='total-likes'
                                    className='me-0 c-pointer d-flex align-items-center'
                                    onClick={() => setLikesModal(true)}
                                >
                                    <TotalLikesIcon className='like-btn me-2' />
                                    <span className='cursor-pointer mr0 d-inline font-secondary-text'>
                                        {`${item.model.likes} ${getLikesStr(item.model.likes ?? 0)}`}
                                    </span>
                                </span>
                            ) : (
                                ''
                            )}
                        </Col>
                        <Col lg={6} xs={6} className='d-flex justify-content-end'>
                            {item.model.comments && item.model.comments > 0 ? (
                                <span className='d-flex align-items-center font-secondary-text'>
                                    <TotalCommentsIcon className='comment-btn-primary comment-btn me-2' />
                                    {`${item.model.comments} ${getCommentsStr(item.model.comments ?? 0)}`}
                                </span>
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>

                    <Row className='border-top py-2'>
                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            <div data-testid='like-btn' onClick={handleLike} className='d-flex justify-content-center align-items-center pe-5'>
                                {item.model.is_liked ? (
                                    <LikeBtnHeartFilled className='c-pointer svg-img' data-testid='like-btn-icon-filled' />
                                ) : (
                                    <LikeBtnHeart className='c-pointer svg-img svg-stroke' data-testid='like-btn-icon' />
                                )}
                                <span className='ms-2 me-0 c-pointer text-light-black media-option' style={{ fontWeight: 'bold' }}>
                                    {t('general.like')}
                                </span>
                            </div>
                        </Col>

                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            <div
                                data-testid='comment-btn'
                                onClick={() => setReplyOpen(!replyOpen)}
                                className='d-flex justify-content-center align-items-center'
                            >
                                <CommentCloudIcon className='comment-btn c-pointer svg-img svg-stroke' />
                                <span className={classnames('ms-2 me-0 text-light-black c-pointer media-option')} style={{ fontWeight: 'bold' }}>
                                    {t('general.comment')}
                                </span>
                            </div>
                        </Col>

                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            {userInfo.spEnabled && item.model.can_repost && (
                                <>
                                    <span
                                        data-testid='share-btn'
                                        className='ms-1 me-0 c-pointer feed-share-btn d-flex justify-content-center align-items-center ps-5'
                                        onClick={handleClickShare}
                                    >
                                        <ShareArrowIcon className='c-pointer me-1 page-footer-option svg-img svg-stroke' />
                                        <span className='page-footer-option ms-2 text-light-black' style={{ fontWeight: 'bold' }}>
                                            {t('general.share')}
                                        </span>
                                    </span>
                                    <SharePopover
                                        popoverProps={{
                                            open: openSharePopover,
                                            anchorEl: shareAnchorEl,
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                            onClose: () => {
                                                setShareAnchorEl(null);
                                            },
                                        }}
                                        postId={item.model.id}
                                        authorName={item.model.author.full_name}
                                        repostHandler={addRepostToFeed}
                                        feedData={feedData}
                                        setFeedData={setFeedData}
                                        handleThoughtsClick={handleReshareWithThoughtsClick}
                                        setShareAnchorEl={setShareAnchorEl}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>

                    <SocialPostComments item={item} setItem={setItem} addReplyOpen={replyOpen} setReplyOpen={setReplyOpen} />
                </Card>
            )}
        </React.Fragment>
    );
};

export default SocialPostItem;
