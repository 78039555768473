import React, { useState, useEffect } from 'react';
import { Box, BoxProps } from '@mui/material';
import { CommunityCard } from '../../components/communityCard/communityCard';
import Posts from '../../components/connectPage/contactPage/postsPage';
import { PostTabDetailType } from '../../components/connectPage/contactPage/contactDetails';
import { useGroupItemQuery } from '../../hooks/query/useGroupItemQuery';
import { useNavigate } from 'react-router-dom';
import CreateSocialPost from '../../components/feedPage/createSocialPost';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslateQuery } from '../../hooks/query/useTranslateQuery';
import { useQueryClient } from 'react-query';
import { cloneDeep } from 'lodash';
import { handleTranslationError } from '../../utils/translationErrorHandle';
import { useTranslation } from 'react-i18next';

export type GroupItemProps = {
    containerProps?: BoxProps;
    id: string;
    onMembersClick: (id: number) => void;
    displayButton: boolean;
    hideScrollBar: boolean;
    parentScrollIsBottom?: boolean;
    setParentScrollIsBottom?: React.Dispatch<React.SetStateAction<boolean>>;
    myGroups?: boolean | undefined;
    latestGroupPost?: any; //array of object post
    userCount?: number | undefined;
    loading: boolean;
    updateFeedData?: Function;
    skeletonSx: string;
};

export const GroupItem = (props: GroupItemProps) => {
    const {
        containerProps,
        id,
        onMembersClick,
        displayButton,
        hideScrollBar = false,
        parentScrollIsBottom,
        setParentScrollIsBottom,
        myGroups,
        latestGroupPost,
        userCount,
        loading,
        updateFeedData,
        skeletonSx,
    } = props;
    const [scrollIsBottom, setScrollIsBottom] = useState<boolean>(false);
    const userInfo = useSelector((state: any) => state.app.user);
    const [postTabDetail, setPostTabDetail] = useState<PostTabDetailType>({
        postData: [],
        pageNo: 1,
        nextPageAvailable: true,
        isLoadingMore: false,
        isPostsLoading: false,
        isInitialDataLoad: false,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data: group, isLoading: fetchingGroup } = useGroupItemQuery(id as string);
    const location = useLocation();
    const { pathname } = location;
    const connectPage = pathname.includes('/connect/groups');
    const queryClient = useQueryClient();

    const handleScroll = (e: any) => {
        const isAtBottom = Math.round(e.target.clientHeight + e.target.scrollTop) >= e.target.scrollHeight;
        setScrollIsBottom(isAtBottom);
    };

    const updatePosts = (newElem: Array<any>) => {
        setPostTabDetail((prev: any) => {
            return { ...prev, postData: [newElem[0], ...postTabDetail.postData] };
        });
    };

    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const [groupToTranslateID, setGroupToTranslateID] = useState<number | null>(null);
    const [translatedGroup, setTranslatedGroup] = useState<{
        [key: number]: {
            originalText: {
                title: string;
                description: string;
            };
            translatedText: {
                title: string;
                description: string;
            };
        };
    }>({});
    const [clickedTranslateGroup, setClickedTranslateGroup] = useState<{ [key: number]: boolean | null }>({});
    const { isLoading: translatingGroup } = useTranslateQuery(
        groupToTranslateID,
        'community-group',
        languagetoTranslate,
        (response?: { data?: { translated: { title: string; description: string }; original: { title: string; description: string } } }) => {
            if (!response?.data?.translated || !groupToTranslateID) return;
            const translatedObj = response.data.translated;
            const originalObj = response?.data.original;
            queryClient.setQueryData(['individual-group-item', String(groupToTranslateID)], (oldData: any) => {
                const groupData = cloneDeep(oldData);
                if (groupData?.data.id === groupToTranslateID) {
                    setTranslatedGroup((prev) => {
                        return {
                            ...prev,
                            [groupToTranslateID]: {
                                originalText: {
                                    title: originalObj.title,
                                    description: originalObj.description,
                                },
                                translatedText: {
                                    title: translatedObj.title,
                                    description: translatedObj.description,
                                },
                            },
                        };
                    });
                    groupData.data.title = translatedObj.title;
                    groupData.data.description = translatedObj.description;
                }
                return groupData;
            });
            setGroupToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: groupToTranslateID,
                setTranslateID: setGroupToTranslateID,
                setClickedTranslate: setClickedTranslateGroup,
                dispatch,
                t,
            });
        }
    );

    const handleTranslate = (groupID: number, translateGroup: boolean) => {
        setClickedTranslateGroup((prev) => ({ ...prev, [groupID]: translateGroup }));
        if (!translateGroup) {
            queryClient.setQueryData(['individual-group-item', String(groupID)], (oldData: any) => {
                const groupData = cloneDeep(oldData);
                if (groupData?.data.id === groupID) {
                    groupData.data.title = translatedGroup[groupID]?.originalText.title;
                    groupData.data.description = translatedGroup[groupID]?.originalText.description;
                }
                return groupData;
            });
            return;
        }
        setGroupToTranslateID(groupID);
    };

    useEffect(() => {
        if (latestGroupPost) {
            setPostTabDetail((prev: any) => {
                return { ...prev, postData: [...latestGroupPost, ...postTabDetail.postData] };
            });
        }
    }, [latestGroupPost]);

    return (
        <Box
            {...containerProps}
            sx={{
                position: 'relative',
                ...containerProps?.sx,
            }}
        >
            {displayButton && (!loading || !fetchingGroup) && (
                <button
                    className='unstyled-btn'
                    style={{
                        cursor: 'pointer',
                        right: connectPage ? '16px' : '0px',
                        top: '5px',
                        position: 'absolute',
                        zIndex: 1,
                    }}
                    data-testid='close-group-details'
                    onClick={() => {
                        navigate('/connect/groups');
                    }}
                >
                    <span className='fa-stack' style={{ fontSize: '16px' }}>
                        <i className='fa-solid fa-circle fa-stack-2x' style={{ color: '#f3f3f3', fontStyle: 'normal' }}></i>
                        <i className='fa-regular fa-xmark fa-stack-1x fa-inverse' style={{ color: '#000', fontStyle: 'normal' }}></i>
                    </span>
                </button>
            )}
            {myGroups ? (
                <Box
                    sx={{
                        height: hideScrollBar ? 'auto' : 'calc(75vh - 205px)',
                        overflow: hideScrollBar ? 'auto' : 'hidden',
                        overflowY: hideScrollBar ? 'hidden' : 'scroll',
                    }}
                    onScroll={handleScroll}
                >
                    <Posts
                        selectedInUserId={id}
                        scrollIsBottom={hideScrollBar ? (parentScrollIsBottom as boolean) : scrollIsBottom}
                        postTabDetail={postTabDetail}
                        setPostTabDetail={setPostTabDetail}
                        groupsOnly={true}
                        groupID={id}
                        myGroups={myGroups}
                        updateFeedData={updateFeedData}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        height: hideScrollBar ? 'auto' : '75vh',
                        overflow: hideScrollBar ? 'auto' : 'hidden',
                        overflowY: hideScrollBar ? 'hidden' : 'scroll',
                        paddingRight: '16px',
                    }}
                    onScroll={handleScroll}
                >
                    <CommunityCard
                        title={location?.state?.id === Number(group?.data?.id) ? location.state.title : group?.data?.title}
                        description={location?.state?.id === Number(group?.data?.id) ? location.state.description : group?.data?.description}
                        members={group?.data?.user_count || userCount || 0}
                        img={group?.data?.image_url || ''}
                        handleClick={() => {}}
                        id={group?.data?.id}
                        can_post={group?.data?.can_post}
                        containersSx={{
                            cursor: 'default',
                        }}
                        membersClick={onMembersClick}
                        loading={loading || fetchingGroup}
                        handleTranslate={handleTranslate}
                        clickedTranslateGroup={clickedTranslateGroup}
                        locale={group?.data?.locale}
                        translatingGroup={translatingGroup}
                        groupToTranslateID={groupToTranslateID}
                        skeletonSx={skeletonSx}
                    />
                    {connectPage && group?.data.can_post && (
                        <CreateSocialPost
                            className={'card create-social-post mb-3'}
                            userInfo={userInfo}
                            defaultSelectedGroup={group?.data.title}
                            individualGroupOnly={connectPage}
                            feedDataMethod={updatePosts}
                        />
                    )}
                    <Posts
                        selectedInUserId={id}
                        scrollIsBottom={hideScrollBar ? (parentScrollIsBottom as boolean) : scrollIsBottom}
                        postTabDetail={postTabDetail}
                        setPostTabDetail={setPostTabDetail}
                        groupsOnly={true}
                        groupID={id}
                        myGroups={myGroups}
                        updateFeedData={updateFeedData}
                    />
                </Box>
            )}
        </Box>
    );
};
