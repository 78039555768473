import React from 'react';
import { Grid, Tooltip, Box, Typography, Skeleton } from '@mui/material';
const renderHtml = require('html-react-parser');
import '../searchCard/searchCard.scss';
import { LikeBtnHeart, CommentCloudIcon, FilledFolder } from '../../../icons/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faGlobe as faSolidGlobe } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { TranslateBtnWrapper } from '../translateBtnWrapper/translateBtnWrapper';

export type ListViewCardProps = {
    imgSrc?: string;
    imgAlt?: string;
    title?: string;
    description?: string;
    publisher?: { name: string; image: string };
    published_at?: string;
    likes?: number;
    comments?: number;
    loading: boolean;
    type: string;
    handleTranslate?: (id: number, translate: boolean) => void;
    translatingTile?: boolean;
    clickedTranslateObj?: { [key: number]: boolean | null };
    locale?: string | null | undefined;
    contentID: number;
    tileToTranslateID?: number | null;
};

export const ListViewCard = (props: ListViewCardProps) => {
    const {
        imgSrc,
        imgAlt,
        title,
        description,
        publisher,
        published_at,
        loading,
        likes,
        comments,
        type,
        locale,
        clickedTranslateObj,
        translatingTile,
        handleTranslate,
        contentID,
        tileToTranslateID,
    } = props;
    const { t } = useTranslation();
    const isTranslated = clickedTranslateObj?.[contentID];

    if (loading) {
        return (
            <Grid container spacing={1} className='image-card-container' data-testid='list-card-loading'>
                <Grid xs={10} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', padding: '10px' }}>
                    <Box sx={{ width: '300px' }}>
                        <Skeleton variant='text' animation='wave' />
                        <Skeleton variant='text' animation='wave' width='60%' />
                        <Skeleton variant='rectangular' animation='wave' sx={{ marginTop: '10px' }} height='60px' />
                    </Box>
                </Grid>
                <Grid item xs={2} className='image-card-img' sx={{ padding: '0px !imporatant' }}>
                    <Skeleton variant='rectangular' animation='wave' sx={{ height: '100%' }} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            container
            spacing={1}
            className='image-card-container'
            sx={{ margin: 0, width: '100%', backgroundColor: 'white', cursor: 'pointer' }}
            data-testid='list-view-card'
        >
            <Grid
                item
                xs={9}
                display={'flex'}
                flexDirection={'column'}
                gap={'20px'}
                justifyContent={'space-around'}
                sx={{
                    px: '16px !important',
                }}
            >
                <Box>
                    {translatingTile && tileToTranslateID === contentID ? (
                        <Skeleton variant='text' animation='wave' height='20px' width='50%' />
                    ) : (
                        <Typography sx={{ fontSize: '18px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 600 }}>
                            {title && renderHtml(title)}
                        </Typography>
                    )}
                    {translatingTile && tileToTranslateID === contentID ? (
                        <Skeleton variant='text' animation='wave' height='20px' width='50%' />
                    ) : (
                        <Typography className='description' sx={{ fontSize: '15px', marginTop: '8px' }}>
                            {description && renderHtml(description)}
                        </Typography>
                    )}
                </Box>
                {type === 'FOLDER' ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <FilledFolder height={'25px'} width={'25px'} />
                            <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>{t('general.folder')}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '25px' }}>
                            <TranslateBtnWrapper locale={isTranslated ? 'translated' : locale}>
                                <Box
                                    sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleTranslate && handleTranslate(contentID, isTranslated ? false : true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={isTranslated ? faSolidGlobe : faGlobe} style={{ fontSize: '16px' }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                                        {t(isTranslated ? 'general.seeOriginal' : 'general.translate')}
                                    </Typography>
                                </Box>
                            </TranslateBtnWrapper>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: '25px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={publisher?.image} alt={publisher?.name} className='search-card-profile-image' />
                                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>{publisher?.name}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <span className='fa-thin fa-calendar-days' style={{ fontSize: '1.5em', width: '30px', height: '25px' }} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>{published_at}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '25px' }}>
                            <TranslateBtnWrapper locale={isTranslated ? 'translated' : locale}>
                                <Box
                                    sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleTranslate && handleTranslate(contentID, isTranslated ? false : true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={isTranslated ? faSolidGlobe : faGlobe} style={{ fontSize: '16px' }} />
                                    <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                                        {t(isTranslated ? 'general.seeOriginal' : 'general.translate')}
                                    </Typography>
                                </Box>
                            </TranslateBtnWrapper>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <LikeBtnHeart height={'16px'} width={'16px'} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>{likes || 0}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <CommentCloudIcon height={'16px'} width={'16px'} />
                                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>{comments || 0}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Grid>

            <Grid
                item
                xs={3}
                className='image-card-img'
                sx={{
                    img: {
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                    },
                }}
            >
                {imgSrc ? (
                    <img src={imgSrc as string} alt={imgAlt as string} />
                ) : (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'lightgrey',
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                        }}
                    >
                        <Tooltip title='No image found'>
                            <i className='fa-regular fa-image-slash' style={{ fontSize: '50px', color: 'black', fontStyle: 'normal' }} />
                        </Tooltip>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};
