import React from 'react';
import { Box, Checkbox, ListItemText, MenuItem, Select as MuiSelect, SxProps, CheckboxProps, InputLabel, Skeleton, MenuList } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { SearchField, SearchFieldProps } from '../searchField/searchField';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import { isWhite } from '../../utils/colourCheck';
import { useSelector } from 'react-redux';

export type SelectProps = {
    label?: React.ReactNode;
    name?: string;
    inputLabel: boolean;
    loading?: boolean;
    loadingNextPage?: boolean;
    options:
        | {
              id: string;
              title: string;
              image?: string;
          }[]
        | undefined;
    multiple: boolean;
    handleChange: (event: SelectChangeEvent<string[]>, child: React.ReactNode) => void;
    sx?: SxProps;
    placeholder?: string;
    selectedValue: string[];
    setSelectedValue: (value: string[]) => void;
    searchFieldProps?: SearchFieldProps;
    displaySearchField: boolean;
    checkboxProps?: CheckboxProps;
    selectSx?: SxProps;
    inputLabelSx?: SxProps;
    selectContainerSx?: SxProps;
    onOpen?: () => void;
    handleScroll?: (e: any) => void;
    iconStyle?: React.CSSProperties;
    handleOptionClick?: (event: any) => void;
    labelStyle?: React.CSSProperties;
    clearSelection?: boolean;
    handleClearSelection?: () => void;
    textOnly?: boolean;
    titleValue?: boolean;
    displayOptionImages?: boolean;
    disabled?: boolean;
    menuItemDisabled?: (id: string) => boolean;
};

export const Select = (props: SelectProps) => {
    const {
        options,
        selectedValue,
        multiple = false,
        setSelectedValue,
        label,
        displaySearchField = true,
        searchFieldProps = {
            placeholder: 'Search...',
            searchQuery: '',
            sx: { margin: '5px', height: '35px', borderRadius: '8px' },
        },
        checkboxProps = {
            disableFocusRipple: true,
            disableRipple: true,
            icon: <i className='fa-light fa-square' style={{ color: 'black', fontSize: '20px' }} />,
            checkedIcon: <i className='fa-light fa-square-check' style={{ color: 'black', fontSize: '20px' }} />,
        },
        selectSx,
        selectContainerSx,
        inputLabel,
        handleChange,
        onOpen,
        loading,
        handleScroll,
        loadingNextPage,
        name,
        inputLabelSx,
        iconStyle,
        handleOptionClick,
        labelStyle,
        clearSelection,
        handleClearSelection,
        textOnly,
        titleValue,
        displayOptionImages,
        disabled,
        menuItemDisabled,
    } = props;

    const tenantBranding = useSelector((state: any) => state.app.branding);
    const highlightColor = isWhite(tenantBranding.web_navbar_background.toLowerCase())
        ? tenantBranding.web_navbar_foreground
        : tenantBranding.web_navbar_background;
    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const fgColor = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

    const handleClick = (event: any) => {
        event.stopPropagation();
        if (!multiple) {
            if (selectedValue.includes(event.target.textContent)) {
                setSelectedValue([]);
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                ...selectContainerSx,
            }}
        >
            {!inputLabel && label && <label style={{ fontSize: '16px', ...labelStyle }}>{label}</label>}
            {inputLabel && (
                <InputLabel
                    sx={{
                        fontSize: '16px',
                        position: 'absolute',
                        paddingLeft: '5px',
                        color: 'black',
                        zIndex: 1,
                        pointerEvents: 'none',
                        paddingTop: '6px',
                        display: 'flex',
                        gap: '5px',
                        ...inputLabelSx,
                    }}
                >
                    {label}{' '}
                    {(selectedValue?.length > 0 || (textOnly && searchFieldProps.searchQuery)) && (
                        <Box
                            sx={{
                                width: '22px',
                                height: '22px',
                                color: 'white',
                                backgroundColor: highlightColor,
                                fontSize: '14px',
                                lineHeight: '22px',
                                fontWeight: '500',
                                textAlign: 'center',
                                borderRadius: '50%',
                            }}
                        >
                            {textOnly && 1}
                            {!textOnly && selectedValue.length}
                        </Box>
                    )}
                </InputLabel>
            )}
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <MuiSelect
                    labelId='filter-label'
                    label={inputLabel}
                    variant='standard'
                    onChange={handleChange}
                    defaultValue={selectedValue}
                    value={selectedValue}
                    name={name}
                    sx={{
                        width: '100%',
                        height: '35px',
                        color: 'black',
                        border: inputLabel ? '0px' : '2px solid #DCDCDC',
                        backgroundColor: 'transparent',
                        borderRadius: '8px',
                        '& .MuiInputBase-input': {
                            paddingX: '10px',
                            '&:focus': {
                                backgroundColor: 'transparent',
                            },
                        },
                        ...selectSx,
                    }}
                    disableUnderline
                    multiple={multiple}
                    renderValue={(selected) => !inputLabel && (multiple ? selected.join(', ') : selected)}
                    MenuProps={{
                        sx: { marginTop: '5px', borderTop: 'white' },
                        PaperProps: { sx: { borderRadius: '10px', paddingBottom: '5px' } },
                        MenuListProps: {
                            sx: { padding: '0px', maxHeight: '200px', overflow: 'hidden', overflowY: 'scroll' },
                            onScroll: handleScroll,
                        },
                    }}
                    IconComponent={({ className }) => {
                        let open = className.includes('MuiSelect-iconOpen');
                        return (
                            <i
                                className={open ? 'fa-light fa-chevron-up' : 'fa-light fa-chevron-down'}
                                style={{ fontSize: '16px', pointerEvents: 'none', position: 'absolute', right: 0, marginLeft: '20px', ...iconStyle }}
                            />
                        );
                    }}
                    onOpen={onOpen}
                    disabled={disabled}
                >
                    {displaySearchField && searchFieldProps && <SearchField {...searchFieldProps} />}
                    {loading && (
                        <Box>
                            {Array.from({ length: 3 }).map((_, index) => (
                                <MenuItem
                                    disabled
                                    key={index}
                                    sx={{
                                        '&:hover': { borderLeft: `5px solid ${highlightColor}` },
                                        padding: '5px 16px',
                                    }}
                                >
                                    <Skeleton variant='text' width='100%' height='35px' />
                                </MenuItem>
                            ))}
                        </Box>
                    )}
                    {!disabled &&
                        options?.map((value, index) => (
                            <MenuItem
                                id={value.title}
                                key={index}
                                value={inputLabel && !titleValue ? value.id : value.title}
                                onClick={handleOptionClick ? handleOptionClick : handleClick}
                                sx={{
                                    '&:hover': { borderLeft: `5px solid ${highlightColor}` },
                                    padding: '5px 16px',
                                }}
                                disabled={menuItemDisabled && menuItemDisabled(value.id)}
                            >
                                <Checkbox {...checkboxProps} checked={selectedValue?.includes(inputLabel && !titleValue ? value.id : value.title)} />
                                {displayOptionImages && value.image && (
                                    <img
                                        src={value.image}
                                        alt={value.title + '-img'}
                                        style={{ width: '24px', height: '24px', borderRadius: '50%', marginRight: '10px' }}
                                    />
                                )}
                                <ListItemText primary={value.title} primaryTypographyProps={{ sx: { fontWeight: '400', fontSize: '16px' } }} />
                                {menuItemDisabled && menuItemDisabled(value.id) && (
                                    <span
                                        className='fa-light fa-lock'
                                        style={{
                                            textAlign: 'end',
                                        }}
                                    />
                                )}
                            </MenuItem>
                        ))}
                    {loadingNextPage && (
                        <SpinnerLoad
                            className={'d-flex justify-content-center'}
                            size={30}
                            style={{
                                padding: '5px 0',
                            }}
                        />
                    )}
                </MuiSelect>
                {clearSelection && (selectedValue?.length > 0 || (textOnly && searchFieldProps.searchQuery)) && (
                    <i
                        className='fa-regular fa-xmark'
                        style={{
                            fontSize: '16px',
                            cursor: 'pointer',
                            color: fgColor,
                        }}
                        onClick={handleClearSelection}
                    />
                )}
            </Box>
        </Box>
    );
};
