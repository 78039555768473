import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Col, Row } from 'reactstrap';
import { IconCard } from '../../IconCard/iconCard';
import { Box, Typography, Avatar } from '@mui/material';
import './socialPost/socialPostItem.scss';
import { GroupPost } from '../../../../types/types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type ItemHeaderProps = {
    communityGroup?: null | GroupPost;
    headText: string;
    imgUrl: string;
    isImgRound?: boolean;
    iconClass?: string | string[]; // this can be a class for <em> or a file URL
    className?: string;
    time?: string;
    jobTitle?: string;
    iconClickCallback?: Function;
    contact_id: string;
    updatedTime?: string;
    createdTime?: string;
    id?: number;
};

const ItemHeader: FunctionComponent<ItemHeaderProps> = ({
    communityGroup,
    headText,
    imgUrl,
    isImgRound = true,
    iconClass,
    className,
    time,
    jobTitle,
    contact_id,
    iconClickCallback,
    updatedTime,
    createdTime,
    id,
}) => {
    const { t } = useTranslation();

    const handleNavigate = () => {
        if (communityGroup) {
            return `/connect/groups/${communityGroup?.id}`;
        } else {
            return `/connect/people/${contact_id}`;
        }
    };

    const renderIcon = (iconClass: any, lastElement?: boolean) => {
        return iconClass && iconClass.indexOf('.') === -1 ? (
            <em
                className={classnames(
                    `feed-item-icon text-light-black position-absolute mt-1`,
                    {
                        'c-pointer fa-2x': iconClass.includes('fa-ellipsis') && iconClickCallback,
                        'me-4-custom': !lastElement,
                        'item-header-custom-icon': !iconClass.includes('fa-ellipsis')!,
                    },
                    iconClass
                )}
                data-testid='dot-button'
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    iconClickCallback && iconClass.includes('fa-ellipsis') && iconClickCallback(e);
                }}
            />
        ) : (
            iconClass && <img src={iconClass} alt='Item Icon' style={{ height: '2.5rem' }} />
        );
    };

    const displayTime = (
        <Box sx={{ display: 'flex', gap: '5px' }} data-testid='item-header-time'>
            <Typography
                sx={{
                    fontWeight: '400',
                    fontSize: '13px',
                    lineHeight: '1.5',
                    '&:hover': { textDecoration: id ? 'underline' : 'none' },
                }}
            >
                {time ?? ''}
            </Typography>
            {updatedTime && updatedTime !== createdTime && (
                <Typography sx={{ fontWeight: '400', fontSize: '13px', lineHeight: '1.5' }}>&bull; {t('general.edited')}</Typography>
            )}
        </Box>
    );

    return (
        <Row
            data-testid='item-header'
            className={classnames('feed-item-header position-relative', { 'icon-class': iconClass && iconClass.indexOf('.') !== -1 }, className)}
        >
            <Col lg={11} md={10} sm={10} xs={10} className='d-flex align-items-center pt-2'>
                <IconCard
                    className='social-post-user-details '
                    icon={communityGroup ? communityGroup.image_url : imgUrl}
                    sx={{ padding: '0px!important', cursor: 'pointer' }}
                    titleStyle={{
                        width: '100%',
                    }}
                    iconStyle={{
                        width: '62px',
                        height: '62px',
                    }}
                    linkContainer={true}
                    linkProps={{
                        to: handleNavigate(),
                        className: 'react-router-link',
                    }}
                    handleClick={(e) => {
                        e.stopPropagation();
                    }}
                    iconBadge={communityGroup ? true : false}
                    iconBadgeCount={communityGroup ? 1 : 0}
                    iconBadgeProps={
                        communityGroup
                            ? {
                                  badgeContent: (
                                      <Link to={`/connect/people/${contact_id}`}>
                                          <Avatar
                                              src={imgUrl}
                                              sx={{ width: 26, height: 26, border: '1px white solid' }}
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                              }}
                                          />
                                      </Link>
                                  ),
                                  sx: {
                                      '& .MuiBadge-badge': {
                                          right: '24px',
                                          top: '54px',
                                      },
                                  },
                              }
                            : undefined
                    }
                >
                    <Box className='social-post-user-details-description'>
                        <Box className='user-details'>
                            {communityGroup ? (
                                <>
                                    <Link to={`/connect/groups/${communityGroup.id}`} className='react-router-link'>
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                fontSize: '16px',
                                                lineHeight: '1.2',
                                                '&:hover': { textDecoration: 'underline' },
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            {communityGroup.title}
                                        </Typography>
                                    </Link>
                                    <Link to={`/connect/people/${contact_id}`} className='react-router-link'>
                                        <Typography
                                            onClick={(e) => e.stopPropagation()}
                                            sx={{ fontWeight: '600', fontSize: '14px', lineHeight: '1', '&:hover': { textDecoration: 'underline' } }}
                                        >
                                            {headText}
                                        </Typography>
                                    </Link>
                                </>
                            ) : (
                                <Link to={`/connect/people/${contact_id}`} className='react-router-link'>
                                    <Box onClick={(e) => e.stopPropagation()}>
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                fontSize: '16px',
                                                lineHeight: '1.2',
                                                '&:hover': { textDecoration: 'underline' },
                                            }}
                                        >
                                            {headText}
                                        </Typography>
                                        <Typography
                                            sx={{ fontWeight: '400', fontSize: '13px', lineHeight: '1', '&:hover': { textDecoration: 'underline' } }}
                                        >
                                            {jobTitle ?? ''}
                                        </Typography>
                                    </Box>
                                </Link>
                            )}
                            {id ? (
                                <Link to={`/social/post/${id}`} className='react-router-link'>
                                    {displayTime}
                                </Link>
                            ) : (
                                displayTime
                            )}
                        </Box>
                    </Box>
                </IconCard>
            </Col>
            <Col lg={1} md={2} sm={2} xs={2} className='d-flex justify-content-end'>
                {typeof iconClass === 'string' || !iconClass
                    ? renderIcon(iconClass, true)
                    : iconClass?.map((icon, index) => {
                          return <React.Fragment key={index}>{renderIcon(icon, index === iconClass.length - 1)}</React.Fragment>;
                      })}
            </Col>
        </Row>
    );
};

export default ItemHeader;
