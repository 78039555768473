import React, { FunctionComponent, useState } from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import { Layout } from '../../../../types/types';
import { useResizeObserver } from '../../../hooks/useResizeObserverHook';
import SpinnerLoad from '../../common/spinnerLoad/spinnerLoad';
import { Link } from 'react-router-dom';
import '../homePage.scss';
import { cloneDeep } from 'lodash';
import { fetchTranslated } from '../../../hooks/query/useTranslateQuery';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../../../shared/reducers/notifications/actionTypes';
import { useTranslation } from 'react-i18next';

type StandardLayoutProps = {
    layoutData: Layout[];
    isLoading: boolean;
    cardStyle?: any;
    imageStyle?: any;
};

const StandardLayout: FunctionComponent<StandardLayoutProps> = ({ layoutData, isLoading, cardStyle, imageStyle }) => {
    const [containerRef, containerObj] = useResizeObserver();
    const [translateClicked, setTranslateClicked] = useState<boolean>(false);
    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const [translatingText, setTranslatingText] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isTranslated = translateClicked && !translatingText; //check if translation is done
    const [translatedSectionsText, setTranslatedSectionsText] = useState<{
        [key: number]: {
            originalText: {
                title: string;
                subtitle: string;
            };
            translatedText: {
                title: string;
                subtitle: string;
            };
        };
    }>({});
    const features = useSelector((state: any) => state.app.features);
    const displayTranslateBtn = features?.translations?.user_content;
    const queryClient = useQueryClient();

    const handleTranslate = async (shouldTranslate: boolean) => {
        setTranslateClicked(shouldTranslate);
        if (!shouldTranslate) return;
        let translateSectionsObj = cloneDeep(translatedSectionsText);
        setTranslatingText(true);
        await Promise.all(
            layoutData.map(async ({ id, locale, title, subtitle }: Layout) => {
                const isTranslationRequired = locale && locale !== languagetoTranslate;
                const originalText = { title, subtitle };
                try {
                    const translatedText = isTranslationRequired
                        ? (
                              await queryClient.fetchQuery({
                                  queryKey: [`translate-text-${id}`, id, 'section', languagetoTranslate],
                                  queryFn: () => fetchTranslated(id, 'section', languagetoTranslate),
                              })
                          ).data.translated
                        : originalText;

                    translateSectionsObj[id] = { originalText, translatedText };
                } catch (error) {
                    translateSectionsObj = {}; // Clear object on error
                    dispatch(
                        addNotification({
                            label: t('general.errors.translationErrorLabel'),
                            text: t('general.errors.translationError'),
                            type: 'danger',
                        })
                    );
                }
            })
        );
        setTranslatingText(false);
        setTranslatedSectionsText(translateSectionsObj);
    };

    const checkContainerType = () => {
        if (!containerObj) return 4;
        if (containerObj && containerObj.width < 400) {
            return 12;
        } else if (containerObj && containerObj.width < 700) {
            return 6;
        } else {
            return 4;
        }
    };

    const cardTypeStyles = (layout: Layout) => {
        if (layout.background_image_web) {
            return {
                position: 'relative',
                borderRadius: '10px',
            };
        } else {
            return {
                backgroundColor: layout.background_colour_web,
                backgroundImage: `url(${layout.background_image_web})`,
                color: layout.label_colour_web,
                marginBottom: '0px',
                borderRadius: '10px',
            };
        }
    };

    const backgroundStructure = (layout: Layout) => {
        return (
            <div
                style={{
                    backgroundImage: `url(${layout.background_image_web})`,
                    backgroundColor: layout.background_colour_web,
                    color: layout.label_colour_web,
                }}
                className={'background-layer'}
            />
        );
    };

    const checkLocaleTranslatable = (layoutData: Layout[]) => layoutData.some((layout) => layout?.locale !== languagetoTranslate);

    const imageStructure = (layout: Layout) => {
        return (
            <div className={'image-layer-container backdrop-blur'}>
                <img src={layout.background_image_web} className={'image-layer'} alt={'image-layer'} />
            </div>
        );
    };

    const HomePageCardsRoutes = ({ layout, children }: { layout: Layout; children: React.ReactNode }) => {
        if (layout.web_page_layout === 'custom') {
            return (
                <Link to={`/custom/section/${layout.id}`} className='react-router-link'>
                    {children}
                </Link>
            );
        }
        if (layout.web_page_layout === 'tile') {
            return (
                <Link to={`/tile/section/${layout.id}`} className='react-router-link'>
                    {children}
                </Link>
            );
        }
        if (layout.web_page_layout === 'list') {
            return (
                <Link to={`/list/section/${layout.id}`} className='react-router-link'>
                    {children}
                </Link>
            );
        }
        return null;
    };

    return (
        <>
            {isLoading ? (
                <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
            ) : (
                <Grid container data-testid='web-layout' spacing={1} ref={containerRef}>
                    {((displayTranslateBtn && checkLocaleTranslatable(layoutData)) || translateClicked) && (
                        <Tooltip
                            title={isTranslated ? t('general.seeOriginal') : t('general.translate')}
                            placement='bottom'
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -10],
                                            },
                                        },
                                    ],
                                },
                            }}
                        >
                            <button
                                className='unstyled-btn'
                                style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    right: '-15px',
                                    top: '-5px',
                                    zIndex: 1,
                                }}
                                data-testid='translate-btn'
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleTranslate(!translateClicked);
                                }}
                            >
                                <span className='fa-stack' style={{ fontSize: '18px' }}>
                                    <i className='fa-solid fa-circle fa-stack-2x' style={{ color: '#D9D9D9', fontStyle: 'normal' }} />
                                    {translatingText ? (
                                        <CircularProgress size={25} sx={{ color: '#000', mt: '5px' }} />
                                    ) : (
                                        <i
                                            className={(translateClicked ? 'fa-solid' : 'fa-regular') + ' fa-globe fa-stack-1x fa-inverse'}
                                            style={{ color: '#000', fontStyle: 'normal' }}
                                        />
                                    )}
                                </span>
                            </button>
                        </Tooltip>
                    )}
                    {layoutData
                        .sort((a, b) => a.order - b.order)
                        .map((layout, index) => {
                            return (
                                <Grid item xs={checkContainerType()} key={index}>
                                    <HomePageCardsRoutes layout={layout}>
                                        <Card
                                            id={`content_${layout.id}`}
                                            data-testid='toggle-card'
                                            style={{
                                                ...cardTypeStyles(layout),
                                                ...cardStyle,
                                            }}
                                            key={layout.id}
                                            tabIndex={0}
                                        >
                                            {layout.background_image_web ? backgroundStructure(layout) : null}
                                            {layout.background_image_web ? imageStructure(layout) : null}
                                            <div
                                                style={{
                                                    ...imageStyle,
                                                    ...(layout.background_image_web ? { position: 'absolute' } : ''),
                                                }}
                                            >
                                                {layout.icon_image_web ? (
                                                    <img src={layout.icon_image_web} alt='Section Icon' className='card-img' />
                                                ) : null}
                                                <CardBody className='section-card-body' style={{ color: layout.label_colour_web }}>
                                                    <CardTitle tag='h2'>
                                                        {isTranslated
                                                            ? translatedSectionsText[layout.id]?.translatedText?.title || layout.title
                                                            : layout.title}
                                                    </CardTitle>
                                                    <CardText>
                                                        {isTranslated
                                                            ? translatedSectionsText[layout.id]?.translatedText?.subtitle || layout.subtitle
                                                            : layout.subtitle}
                                                    </CardText>
                                                </CardBody>
                                            </div>
                                        </Card>
                                    </HomePageCardsRoutes>
                                </Grid>
                            );
                        })}
                </Grid>
            )}
        </>
    );
};

export default StandardLayout;
