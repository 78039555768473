import './breadcrumbs.scss';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Breadcrumb as EngageBreadcrumb, ContentType } from '~/types/types';
import breadcrumbsService from '../../services/breadcrumbsService';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';

type BreadcrumbProps = {
    contentType: ContentType;
    contentId: number;
};

const Breadcrumbs: FunctionComponent<BreadcrumbProps> = ({ contentType, contentId }) => {
    const [data, setBreadcrumbsData] = useState<EngageBreadcrumb[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [contentId, contentType]);

    const fetchData = () => {
        breadcrumbsService.getData(contentId, contentType, (response: AxiosResponse) => {
            let breadcrumbs = response.data[0]?.data.breadcrumbs;
            if (breadcrumbs) {
                setBreadcrumbsData(breadcrumbs);
            }
        });
    };

    return (
        <div>
            <Breadcrumb data-testid='breadcrumbs' className='breadcrumb-wrapper'>
                {data.map((breadcrumb, index) => {
                    return (
                        <React.Fragment key={index}>
                            {breadcrumb.url === null || breadcrumb.url === '' ? (
                                <BreadcrumbItem active className='breadcrumb-item'>
                                    <strong data-testid='active-breadcrumb-item'>{breadcrumb.label}</strong>
                                </BreadcrumbItem>
                            ) : (
                                <BreadcrumbItem className='breadcrumb-item'>
                                    <Link data-testid='breadcrumb-item' to={breadcrumb.url} className='breadcrumb-item-a'>
                                        <strong>{breadcrumb.label}</strong>
                                    </Link>
                                </BreadcrumbItem>
                            )}
                        </React.Fragment>
                    );
                })}
            </Breadcrumb>
        </div>
    );
};

export default Breadcrumbs;
