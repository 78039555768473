import React, { FunctionComponent, useEffect, useState } from 'react';
import { FeedSocialPost, FeedItem, FeedPostActionTypes } from '../../../../../types/types';
import { useTranslation } from 'react-i18next';
import socialPostService from '../../../../services/socialPostsService';
import { addNotification } from '../../../../../shared/reducers/notifications/actionTypes';
import { useDispatch } from 'react-redux';
import './socialPostItem.scss';
import userService from '../../../../../web/services/usersService';
import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { Popover, List, ListItemButton, Typography, ListItemText, ListItem } from '@mui/material';
import { IconText } from '../../../iconText/IconText';
import { TranslateBtnWrapper } from '../../../translateBtnWrapper/translateBtnWrapper';
import CreateSocialPost from '../../../feedPage/createSocialPost';
import { useSelector } from 'react-redux';
import { CountdownTimer } from '../../../countdownTimer/countdownTimer';

export type FeedPostActionProps = {
    item: FeedSocialPost | FeedItem;
    removeItem: Function;
    canDeletePost: boolean;
    removeContentByUser?: Function;
    handleTranslate?: (id: number, clicked: boolean) => void;
    clickedTranslateTextObj: { [key: number]: boolean | null };
    open: boolean;
    handleClose: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
    anchorEl: HTMLButtonElement | null;
    updateItem?: Function;
    type?: FeedPostActionTypes.P2P | FeedPostActionTypes.PAGE | FeedPostActionTypes.SOCIAL_POST;
    isTYCard?: boolean;
    translatedText?: any;
};

enum contextMenu {
    main,
    snooze,
    report,
}

const FeedPostActions: FunctionComponent<FeedPostActionProps> = ({
    item,
    removeItem,
    canDeletePost,
    removeContentByUser,
    handleTranslate,
    clickedTranslateTextObj,
    open,
    handleClose,
    anchorEl,
    updateItem,
    type = FeedPostActionTypes.SOCIAL_POST,
    translatedText,
    isTYCard,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [reportText, setReportText] = useState('');
    const [menu, setMenu] = useState(contextMenu.main);
    const [displayDeleteAlert, setDisplayDeleteAlert] = useState<boolean>(false);
    const [displayCreateSPModal, setDisplayCreateSPModal] = useState<boolean>(false);
    const [editTimerCompleted, setEditTimerCompleted] = useState<boolean>(false);
    const userInfo = useSelector((state: any) => state.app.user);

    const getTimeLeftInSeconds = (createdAt: string) => {
        const utcCreatedAt = createdAt.endsWith('Z') ? createdAt : `${createdAt}Z`;
        const expirationTime = new Date(utcCreatedAt).getTime() + 10 * 60 * 1000; // Add 10 min
        const currentTime = new Date().getTime();
        const timeLeft = Math.max(0, Math.floor((expirationTime - currentTime) / 1000)); // Convert to seconds
        return timeLeft;
    };

    const headerStyle = {
        fontSize: '16px',
        fontWeight: 600,
    };
    const subtitleStyle = {
        fontSize: '12px',
        fontWeight: 400,
    };
    const iconStyle = {
        fontSize: '20px',
        width: '22px',
        lineHeight: '1.2',
    };

    const deletePost = (e: any) => {
        e.stopPropagation();
        setDisplayDeleteAlert(true);
        confirmAlert({
            title: `${t('feed.deletePost')}`,
            message: `${t('feed.deletePostDesc')}`,
            buttons: [
                {
                    label: t('general.yes'),
                    onClick: () => {
                        socialPostService.deletePost(
                            item.model.id,
                            () => {
                                removeItem(item.model.id);
                                dispatch(
                                    addNotification({
                                        label: `Delete Post`,
                                        text: t('general.success.general'),
                                        type: 'success',
                                    })
                                );
                            },
                            () => {
                                dispatch(
                                    addNotification({
                                        label: `Delete Post`,
                                        text: t('general.errors.errorLoadingData'),
                                        type: 'danger',
                                    })
                                );
                            }
                        );
                        handleClose && handleClose(e, 'backdropClick');
                        setDisplayDeleteAlert(false);
                    },
                },
                {
                    label: t('general.no'),
                    onClick: () => {
                        handleClose && handleClose(e, 'backdropClick');
                    },
                },
            ],
            overlayClassName: 'zIndex1050',
        });
    };

    const editPost = (e: any) => {
        e.stopPropagation();
        setDisplayCreateSPModal(true);
        handleClose && handleClose(e, 'backdropClick');
    };

    const unfollowHandler = () => {
        socialPostService.unfollowUser(
            item.model.author.id,
            () => {
                removeContentByUser && removeContentByUser(item.model.author.id);
                dispatch(
                    addNotification({
                        label: `Unfollow`,
                        text: t('general.success.general'),
                        type: 'success',
                    })
                );
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Unfollow`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const snoozeHandler = (snoozeMins: number) => {
        userService.snooze(
            snoozeMins,
            item.model.author.id,
            () => {
                removeContentByUser && removeContentByUser(item.model.author.id);
                dispatch(
                    addNotification({
                        label: `Snooze`,
                        text: t('general.success.general'),
                        type: 'success',
                    })
                );
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Snooze`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const sendReport = (e: any) => {
        e.stopPropagation();
        if (!reportText) return;
        socialPostService.reportPost(
            item.model.id,
            reportText,
            () => {
                setMenu(contextMenu.main);
                dispatch(
                    addNotification({
                        label: `Report`,
                        text: t('general.success.general'),
                        type: 'success',
                    })
                );
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Report`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const getDeleteAndEdit = () => {
        const timeLeft = getTimeLeftInSeconds(item.model.created_at);
        return (
            <List sx={{ p: 0 }}>
                {userInfo.spEnabled && timeLeft > 0 && !editTimerCompleted && (
                    <ListItemButton onClick={editPost} data-testid='edit-btn'>
                        <IconText icon='fa-regular fa-pencil' text={''} iconStyle={iconStyle}>
                            <Typography sx={headerStyle}>{t('feed.editPost')}</Typography>
                            <Typography
                                sx={{
                                    ...subtitleStyle,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                }}
                            >
                                {t('feed.editDescription')}
                                <CountdownTimer
                                    displayHours={false}
                                    initialTime={timeLeft}
                                    containerSx={{
                                        minWidth: '50px',
                                    }}
                                    onComplete={() => {
                                        setEditTimerCompleted(true);
                                    }}
                                />
                            </Typography>
                        </IconText>
                    </ListItemButton>
                )}
                <ListItemButton onClick={deletePost} data-testid='delete-btn'>
                    <IconText icon='fa-regular fa-trash-can' text={''} iconStyle={iconStyle}>
                        <Typography sx={headerStyle}>{t('feed.deletePost')}</Typography>
                        <Typography sx={subtitleStyle}>{t('feed.deleteDescription')}</Typography>
                    </IconText>
                </ListItemButton>
                <TranslateBtnWrapper locale={clickedTranslateTextObj?.[item.model.id] ? 'translated' : item.model.locale}>
                    <ListItemButton onClick={onTranslateClick} data-testid='translate-btn'>
                        <IconText icon='fa-regular fa-globe' text={''} iconStyle={iconStyle}>
                            <Typography sx={headerStyle}>
                                {clickedTranslateTextObj[item.model.id] ? t('general.seeOriginal') : t('general.translate')}
                            </Typography>
                            <Typography sx={subtitleStyle}>
                                {clickedTranslateTextObj[item.model.id] ? t('feed.seeOriginalDescription') : t('feed.translateDescription')}
                            </Typography>
                        </IconText>
                    </ListItemButton>
                </TranslateBtnWrapper>
            </List>
        );
    };

    const renderReport = () => {
        return (
            <List
                sx={{
                    padding: '5px',
                }}
            >
                <ListItemText data-testid='report-label'>{t('feed.reportReason')}</ListItemText>
                <ListItem sx={{ padding: '0 5px' }}>
                    <textarea
                        data-testid='report-textarea'
                        className='w-100 report-input'
                        rows={8}
                        onChange={reportInputHandler}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {reportText}
                    </textarea>
                </ListItem>
                <ListItem sx={{ justifyContent: 'center', gap: '10px' }}>
                    <Button
                        data-testid='report-cancel'
                        className='float-left cancel-button'
                        onClick={(e) => {
                            e.stopPropagation();
                            setMenu(contextMenu.main);
                        }}
                    >
                        {t('general.cancel')}
                    </Button>
                    <Button disabled={reportText === ''} data-testid='submit-report-button' className='float-right send-button' onClick={sendReport}>
                        {t('feed.report')}
                    </Button>
                </ListItem>
            </List>
        );
    };

    const reportInputHandler = (e: any) => {
        e.stopPropagation();
        const val = e.currentTarget.value.trim();
        setReportText(val);
    };

    const onTranslateClick = (e: any) => {
        e.stopPropagation();

        const idToTranslate = type === FeedPostActionTypes.P2P && isTYCard ? item.model.thank_you_card_id : item.model.id;

        const shouldTranslate = !clickedTranslateTextObj[idToTranslate];

        handleTranslate?.(idToTranslate, shouldTranslate);
    };

    const renderSnooze = () => {
        return (
            <List sx={{ p: 0 }}>
                <ListItemButton onClick={() => snoozeHandler(60 * 24 * 7)} data-testid='snooze-option'>
                    <ListItemText>{`1 ${t('general.week')}`}</ListItemText>
                </ListItemButton>
                <ListItemButton onClick={() => snoozeHandler(60 * 24 * 7 * 4)} data-testid='snooze-option'>
                    <ListItemText>{`1 ${t('general.month')}`}</ListItemText>
                </ListItemButton>
                <ListItemButton onClick={() => snoozeHandler(60 * 24 * 7 * 4 * 12)} data-testid='snooze-option'>
                    <ListItemText>{`1 ${t('general.year')}`}</ListItemText>
                </ListItemButton>
            </List>
        );
    };

    const getAllOptions = () => {
        return (
            <List sx={{ p: 0 }}>
                <ListItemButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setMenu(contextMenu.snooze);
                    }}
                    data-testid='snooze-button'
                    sx={{ padding: '16px 16px 8px 16px' }}
                >
                    <IconText icon='fa-regular fa-alarm-snooze' text={''} iconStyle={iconStyle}>
                        <Typography sx={headerStyle}>{t('feed.snooze')}</Typography>
                        <Typography sx={subtitleStyle}>
                            {t('feed.snoozeDescription', {
                                name: item.model.author.full_name,
                            })}
                        </Typography>
                    </IconText>
                </ListItemButton>
                <ListItemButton
                    onClick={(e) => {
                        e.stopPropagation();
                        unfollowHandler();
                    }}
                    data-testid='unfollow-button'
                    sx={{ padding: '8px 16px 8px 16px' }}
                >
                    <IconText icon='fa-regular fa-user-minus' text={''} iconStyle={iconStyle}>
                        <Typography sx={headerStyle}>{t('feed.unfollow')}</Typography>
                        <Typography sx={subtitleStyle}>
                            {t('feed.unfollowDescription', {
                                name: item.model.author.full_name,
                            })}
                        </Typography>
                    </IconText>
                </ListItemButton>
                <ListItemButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setMenu(contextMenu.report);
                    }}
                    data-testid='report-button'
                    sx={{ padding: '8px 16px 8px 16px' }}
                >
                    <IconText icon='fa-light fa-circle-exclamation' text={''} iconStyle={iconStyle}>
                        <Typography sx={headerStyle}>{t('feed.reportPost')}</Typography>
                        <Typography sx={subtitleStyle}>{t('feed.reportDescription')}</Typography>
                    </IconText>
                </ListItemButton>
                <TranslateBtnWrapper locale={clickedTranslateTextObj?.[item.model.id] ? 'translated' : item.model.locale}>
                    <ListItemButton onClick={onTranslateClick} data-testid='translate-btn' sx={{ padding: '8px 16px 16px 16px' }}>
                        <IconText icon='fa-regular fa-globe' text={''} iconStyle={iconStyle}>
                            <Typography sx={headerStyle}>
                                {clickedTranslateTextObj[item.model.id] ? t('general.seeOriginal') : t('general.translate')}
                            </Typography>
                            <Typography sx={subtitleStyle}>
                                {clickedTranslateTextObj[item.model.id] ? t('feed.seeOriginalDescription') : t('feed.translateDescription')}
                            </Typography>
                        </IconText>
                    </ListItemButton>
                </TranslateBtnWrapper>
            </List>
        );
    };

    useEffect(() => {
        setDisplayDeleteAlert(false);
        if (open) setMenu(contextMenu.main);
    }, [open]);

    if (displayCreateSPModal) {
        return (
            <CreateSocialPost
                className={'p-1 mb-3 card create-social-post'}
                userInfo={userInfo}
                feedDataMethod={updateItem}
                editMode={displayCreateSPModal}
                setEditMode={setDisplayCreateSPModal}
                editPostObject={{
                    editorTextValue: clickedTranslateTextObj?.[item.model.id] ? translatedText?.[item.model.id].originalText : item.model.message,
                    filesInfo: item.model.files,
                    editID: item.model.id,
                }}
                repost={item.model?.repost_parent}
                repostItem={item.model?.repost_parent}
                created_at={item.model?.repost_parent ? item.model?.created_at : item.model.created_at}
                defaultSelectedGroup={item.model?.community_group?.title}
            />
        );
    }

    return (
        <Popover
            open={open}
            onClose={(e: any) => {
                e.stopPropagation();
                handleClose && handleClose(e, 'backdropClick');
            }}
            sx={{
                display: displayDeleteAlert ? 'none' : 'block',
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            data-testid='feed-post-actions-modal'
        >
            <div data-testid='feed-post-actions'>
                {type !== FeedPostActionTypes.P2P && type !== FeedPostActionTypes.PAGE ? (
                    canDeletePost ? (
                        <div className={'fake-modal-content'}>{getDeleteAndEdit()}</div>
                    ) : menu === contextMenu.main ? (
                        <div className={'fake-modal-content'}>{getAllOptions()}</div>
                    ) : menu === contextMenu.report ? (
                        <div data-testid='report-popup' className={'fake-modal-content'}>
                            {renderReport()}
                        </div>
                    ) : (
                        <div className={'fake-modal-content'}>{renderSnooze()}</div>
                    )
                ) : (
                    <div className={'fake-modal-content'}>
                        <TranslateBtnWrapper
                            locale={
                                clickedTranslateTextObj?.[isTYCard ? item.model.thank_you_card_id : item.model.id] ? 'translated' : item.model.locale
                            }
                        >
                            <ListItemButton onClick={onTranslateClick} data-testid='translate-btn'>
                                <IconText icon='fa-regular fa-globe' text={''} iconStyle={iconStyle}>
                                    <Typography sx={headerStyle}>
                                        {clickedTranslateTextObj[isTYCard ? item.model.thank_you_card_id : item.model.id]
                                            ? t('general.seeOriginal')
                                            : t('general.translate')}
                                    </Typography>
                                    <Typography sx={subtitleStyle}>
                                        {clickedTranslateTextObj[isTYCard ? item.model.thank_you_card_id : item.model.id]
                                            ? t('feed.seeOriginalDescription')
                                            : t('feed.translateDescription')}
                                    </Typography>
                                </IconText>
                            </ListItemButton>
                        </TranslateBtnWrapper>
                    </div>
                )}
            </div>
        </Popover>
    );
};

export default FeedPostActions;
