import React, { useState, useEffect } from 'react';
import { Grid, GridProps } from '@mui/material';
import { GroupCard } from '../../components/groupCard/groupCard';
import { useQueryClient } from 'react-query';
import { useTranslateQuery } from '../../hooks/query/useTranslateQuery';
import { cloneDeep } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { handleTranslationError } from '../../utils/translationErrorHandle';

export type GroupsListProps = {
    loading: boolean;
    containerProps?: GridProps;
    groups: any;
    handleGroupCardClick: (id: number) => void;
    onMembersClick: (id: number) => void;
    id: string;
    onJoinOrLeaveGroupClick: (id: number, following: boolean) => void;
    searchQuery: any;
    prefix: string;
};

export const GroupsList = (props: GroupsListProps) => {
    const { loading, containerProps, groups, handleGroupCardClick, onMembersClick, id, onJoinOrLeaveGroupClick, searchQuery, prefix } = props;
    const queryClient = useQueryClient();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const languagetoTranslate = window.localStorage.getItem('userLanguage') || 'en';
    const [groupToTranslateID, setGroupToTranslateID] = useState<number | null>(null);
    const [translatedGroup, setTranslatedGroup] = useState<{
        [key: number]: {
            originalText: {
                title: string;
                description: string;
            };
            translatedText: {
                title: string;
                description: string;
            };
        };
    }>({});
    const [clickedTranslateGroup, setClickedTranslateGroup] = useState<{ [key: number]: boolean | null }>({});
    const { isLoading: translatingGroup } = useTranslateQuery(
        groupToTranslateID,
        'community-group',
        languagetoTranslate,
        (response?: { data?: { translated: { title: string; description: string }; original: { title: string; description: string } } }) => {
            if (!response?.data?.translated || !groupToTranslateID) return;
            const translatedObj = response.data.translated;
            const originalObj = response.data.original;
            queryClient.setQueryData(['groups-list', searchQuery, prefix], (oldData: any) => {
                const groupData = cloneDeep(oldData);
                groupData?.pages.map((page: any) => {
                    page?.data.map((group: any) => {
                        if (group.id === groupToTranslateID) {
                            setTranslatedGroup((prev) => ({
                                ...prev,
                                [groupToTranslateID]: {
                                    originalText: {
                                        title: originalObj?.title,
                                        description: originalObj?.description,
                                    },
                                    translatedText: {
                                        title: translatedObj.title,
                                        description: translatedObj.description,
                                    },
                                },
                            }));
                            location.state = {
                                id: group.id,
                                title: translatedObj.title,
                                description: translatedObj.description,
                            };
                            group.title = translatedObj.title;
                            group.description = translatedObj.description;
                            return;
                        }
                    });
                });
                return groupData;
            });
            setGroupToTranslateID(null);
        },
        () => {
            handleTranslationError({
                translateID: groupToTranslateID,
                setTranslateID: setGroupToTranslateID,
                setClickedTranslate: setClickedTranslateGroup,
                dispatch,
                t,
            });
        }
    );

    const handleTranslate = (groupID: number, translateGroup: boolean) => {
        setClickedTranslateGroup((prev) => ({ ...prev, [groupID]: translateGroup }));
        if (!translateGroup) {
            queryClient.setQueryData(['groups-list', searchQuery, prefix], (oldData: any) => {
                const groupData = cloneDeep(oldData);
                groupData?.pages.map((page: any) => {
                    page?.data.map((group: any) => {
                        const original = translatedGroup[groupID].originalText;
                        if (group.id === groupID) {
                            group.title = original.title;
                            group.description = original.description;
                            location.state = {
                                id: groupID,
                                title: original.title,
                                description: original.description,
                            };
                            return;
                        }
                    });
                });
                return groupData;
            });
            return;
        }
        setGroupToTranslateID(groupID);
    };

    useEffect(() => {
        navigate(location.pathname, { replace: true });
    }, []);

    if (loading) {
        return (
            <>
                {Array(12)
                    .fill(0)
                    .map((_, index) => {
                        return (
                            <Grid item key={index} {...containerProps}>
                                <GroupCard
                                    loading={loading}
                                    img=''
                                    title=''
                                    description=''
                                    id={groupToTranslateID}
                                    members={0}
                                    is_member={false}
                                    handleClick={() => {}}
                                    onMembersClick={() => {}}
                                    onJoinOrLeaveGroupClick={() => {}}
                                    can_leave={true}
                                    handleTranslate={handleTranslate}
                                    clickedTranslateGroup={clickedTranslateGroup}
                                    translatingGroup={translatingGroup}
                                    groupToTranslateID={groupToTranslateID}
                                />
                            </Grid>
                        );
                    })}
            </>
        );
    }

    return (
        <>
            {groups &&
                groups?.pages.map((page: any, index: number) => {
                    return page.data.map((group: any, index: number) => {
                        return (
                            <Grid item key={index} {...containerProps}>
                                <GroupCard
                                    containerSx={{
                                        width: '100%',
                                        background: group?.id == id ? '#E3E3E3' : 'white',
                                    }}
                                    onMembersClick={onMembersClick}
                                    id={group.id}
                                    handleClick={handleGroupCardClick}
                                    loading={loading}
                                    img={group.image_url}
                                    title={group.title}
                                    description={group.description}
                                    members={group?.user_count || 0}
                                    is_member={group.is_member}
                                    can_leave={group.can_leave}
                                    onJoinOrLeaveGroupClick={() => onJoinOrLeaveGroupClick(group.id, group.is_member)}
                                    handleTranslate={handleTranslate}
                                    clickedTranslateGroup={clickedTranslateGroup}
                                    locale={group.locale}
                                    translatingGroup={translatingGroup}
                                    groupToTranslateID={groupToTranslateID}
                                />
                            </Grid>
                        );
                    });
                })}
        </>
    );
};
