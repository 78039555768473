export default {
  "locations": "Locations",
  "contacts": "Contacts",
  "favourites": "Favourites",
  "all": "All",
  "noDataFound": "No data found",
  "thereAreNoPost": "There are no social posts, start posting to see them here!",
  "noOrgChart": "There is no organisational chart associated with this user.",
  "awarded": "Awarded",
  "expires": "Expires",
  "contactDetails": "Contact Details",
  "followers": "Followers",
  "following": "Following",
  "follow": "Follow",
  "unfollow": "Unfollow",
  "unsnooze": "Unsnooze",
  "snooze": "Snooze",
  "1Hour": "1 Hour",
  "8Hours": "8 Hours",
  "24Hours": "24 Hours",
  "1Week": "1 Week",
  "1Month": "1 Month",
  "1Year": "1 Year",
  "message": "Message",
  "details": "Details",
  "posts": "Posts",
  "organisation": "Organisation",
  "profileImageHeader": "Profile Image",
  "headerImageHeader": "Profile Header Image",
  "uploadFile": "Upload file",
  "zoom": "Zoom (%) ",
  "rotate": "Rotate ",
  "done": "Done",
  "ratio": "Keep Aspect Ratio",
  "on": "Enable",
  "off": "Disable",
  "search": "Search",
  "noFavourites": "Start adding favourite contacts!",
  "manager": "Manager",
  "location": "Location",
  "primaryEmail": "Primary Email",
  "dob": "Date Of Birth",
  "start": "Start Date",
  "bio": "Bio",
  "chart": "Org Chart",
  "close": "Close",
  "address": "Address",
  "description": "Description",
  "fName": "First Name",
  "lName": "Last Name",
  "title": "Title",
  "department": "Department",
  "save": "Save",
  "error": "There was an error,",
  "errorLong": "please try again later.",
  "success": "Success!",
  "successLong": "Details saved correctly",
  "email": "Email",
  "badge": "Badges",
  "skills": "Skills",
  "phone": "Phone",
  "hobbies": "Hobbies",
  "clearAll": "Clear All",
  "quickFilters": "Quick filters",
  "customSearchTextPlaceholder": "Start typing and press enter...",
  "working hours": "Working Hours",
  "fun facts": "Fun Facts",
  "noResultsFound": "No results found",
  "charsLimitMsg": "Please enter 3 or more characters to search",
  "errorLoadingFollowers": "Error loading followers",
  "errorLoadingFollowings": "Error loading followings",
  "followsYou": "Follows you",
  "searchContacts": "Search contacts",
  "searchLocations": "Search locations",
  "allContacts": "All Contacts",
  "edit": "Edit",
  "people": "People",
  "directory": "Directory",
  "noSearchResultsContactDescription": "Oops! It appears we couldn't find any contacts matching your search.",
  "noContactResultsDescription": "Oops! It appears we couldn't find any contacts.",
  "noSearchResultsFavouriteDescription": "Oops! It appears we couldn't find any favourite contacts matching your search.",
  "noFavouriteResultsDescription": "Oops! No favourite contacts found. Add contacts to your favourites to view them here.",
  "noSearchResultsLocationDescription": "Oops! It appears we couldn't find any locations matching your search.",
  "noLocationResultsDescription": "Oops! It appears we couldn't find any locations.",
  "clearFilters": "Clear filters",
  "filters": "Filters",
  "noLocationPermission": "Oops! It looks like you don’t have the permissions to see this. Try another location.",
  "groups": "Groups",
  "joinGroup": "Join Group",
  "leaveGroup": "Leave Group",
  "mandatoryGroup": "Mandatory Group",
  "noSearchResultsGroupsDescription": "Oops! It appears we couldn't find any groups matching your search.",
  "noGroupsResultsDescription": "Oops! It appears we couldn't find any groups.",
  "groupMembers": "Group Members",
  "noGroupMembers": "Oops! it looks like there are no members in this group yet.",
  "cannotLeaveGroupTooltip": "You’ve been auto-added to this group by an admin. If you would like to leave, please contact a group admin for assistance.",
  "restrictPostingGroup": "You cannot post to this group"
};
