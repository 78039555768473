export default {
  "repliedToYourComment": "Hat auf deinen Kommentar geantwortet",
  "likedYourComment": "Dein Kommentar hat mir gefallen",
  "sentYou": "Hat dir {{text, en-handle-an}} gesendet",
  "received": "{{text, en-handle-an}} erhalten",
  "sendEmail": "E-Mail",
  "sendMessage": "Nachricht",
  "createThread": "Neuen Thread erstellen",
  "attachments": "Anlagen",
  "done": "Erledigt",
  "expand": "Erweitern",
  "hide": "Verstecken",
  "showMoreComments": "Zeige mehr Kommentare",
  "hideComments": "Kommentare ausblenden",
  "showComments": "Kommentare einblenden",
  "hideReplies": "Antworten ausblenden",
  "showReplies": "Antworten einblenden",
  "deleteComment": "Kommentar löschen",
  "deleteCommentDesc": "Bist du sicher, dass du diesen Kommentar entfernen möchtest?",
  "noCommentsYet": "Es gibt noch keine Kommentare...",
  "chooseAction": "Wähle eine Aktion",
  "deletePost": "Löschen",
  "deletePostDesc": "Bist du sicher, dass du diesen Beitrag entfernen möchtest?",
  "unfollow": "Nicht mehr folgen",
  "snooze": "Schlummern",
  "reportPost": "Beitrag melden",
  "report": "Bericht",
  "reportReason": "Grund für die Meldung dieses Beitrags",
  "messageToManager": "Nachricht an den Manager",
  "delete": "Löschen",
  "emptyAwardsMessage": "Im Moment gibt es hier nichts vorzuzeigen, da du noch nichts erhalten hast!",
  "Followers": "Anhänger",
  "Following": "Folgend",
  "SendAThankYou": "{{label, en-handle-an}} senden",
  "SeeAll": "Alles ansehen",
  "SentToYou": "Zu dir geschickt",
  "CreateASocialPost": "Einen Beitrag in sozialen Netzwerken erstellen",
  "Photo": "Foto",
  "Video": "Video",
  "Files": "Dateien",
  "Post": "Beitrag",
  "LatestComments": "Letzte Kommentare",
  "SentBy": "Gesendet Von",
  "AllPosts": "Alle",
  "MyGroups": "Meine Gruppen",
  "Search": "Suchen...",
  "SpecificGroup": "Spezifische Gruppe",
  "selectGroupToPost": "Gruppe zum Posten auswählen...",
  "cannotPostAlert": "Es scheint, dass Sie keine Berechtigung haben, in dieser Gruppe zu posten. Bitte wählen Sie eine andere Gruppe aus.",
  "translateDescription": "In Ihre bevorzugte Sprache übersetzen",
  "seeOriginalDescription": "Originaltext anzeigen",
  "snoozeDescription": "Beiträge von {{name}} vorübergehend nicht anzeigen",
  "unfollowDescription": "Beiträge von {{name}} nicht mehr anzeigen",
  "reportDescription": "Haben Sie ein Problem mit diesem Beitrag?",
  "deleteDescription": "Diesen Beitrag dauerhaft entfernen?",
  "editPost": "Bearbeiten",
  "editDescription": "Ändere diesen Beitrag für bis zu",
  "EditASocialPost": "Einen Beitrag in sozialen Netzwerken bearbeiten"
};