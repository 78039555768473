import React, { useEffect, useState } from 'react';
import { Paper, Typography, Box, Avatar, Popover, Skeleton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBellSlash, faBoxArchive } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import editGroupIcon from '../../../icons/edit-group-icon.svg';
import ThreadParticipantList from '../threadParticipantList/threadParticipantList';
import { fetchParticipants } from '../../pages/Chat/chatProvider';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import MuteThread from '../muteThread/muteThread';
import { useArchiveThread, useUnarchiveThread } from '../../hooks/useArchiveThreadHook';
import { GroupDetailsType } from '../../../types/interface/Thread';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { useDispatch } from 'react-redux';
import { useMarkAsMute } from '../../hooks/useMarkAsMute';
import ManageParticipantModal from '../manageParticipantModal/manageParticipantModal';
import { useLeaveGroupThread } from '../../hooks/useLeaveGroupThread';
import LeaveGroupModal from '../leaveGroupModal/leaveGroupModal';
import { useSelector } from 'react-redux';
import { isWhite } from '../../../web/utils/colourCheck';
import { faPersonFromPortal } from '@fortawesome/pro-solid-svg-icons';
import { TranslateBtnWrapper } from '../translateBtnWrapper/translateBtnWrapper';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { faGlobe as faSolidGlobe } from '@fortawesome/pro-solid-svg-icons';

export type ThreadInfoProps = {
    description: string;
    createdAt: string;
    title: string;
    avatarUrl?: string | undefined;
    threadType?: string | undefined;
    mutedUntil: string | undefined;
    isArchived: boolean;
    isThreadAdmin: boolean;
    mutedByAdmin: boolean;
    displayThreadInfoEl: HTMLButtonElement | null;
    open: boolean;
    locale?: string;
    refreshThreadDetail: (isLeaveGroup?: boolean) => void;
    setDisplayThreadInfoEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    handleArchiveThread: (threadId: string | undefined) => void;
    setIsGroupSetting: React.Dispatch<React.SetStateAction<boolean>>;
    setGroupEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    handleEditGroupDetail: (groupDetails: GroupDetailsType) => void;
    handleTranslate: (id: number, translateText: boolean) => void;
    clickedTranslateTextObj: { [key: number]: boolean | null };
    id: number;
    translating: boolean;
};

export const ThreadInfo = (props: ThreadInfoProps) => {
    const {
        description,
        createdAt,
        title,
        avatarUrl,
        isArchived,
        threadType,
        mutedUntil,
        mutedByAdmin,
        isThreadAdmin,
        open,
        displayThreadInfoEl,
        locale,
        setDisplayThreadInfoEl,
        handleArchiveThread,
        refreshThreadDetail,
        setIsGroupSetting,
        setGroupEditMode,
        handleEditGroupDetail,
        handleTranslate,
        clickedTranslateTextObj,
        translating,
        id,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const { id: threadID } = useParams<{ id: string }>();
    const [participantSearchString, setParticipantSearchString] = useState<string>('');
    const [openManageParticipantModal, setOpenManageParticipantModal] = useState<boolean>(false);
    const [isOpenLeaveGroupModal, setIsOpenLeaveGroupModal] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<(EventTarget & SVGSVGElement) | null>(null);
    const [muteOptionValue, setMuteOptionValue] = useState<string>('60'); // Default value 60 minutes
    const muted = mutedUntil && new Date(mutedUntil) > new Date() ? true : false;
    const [searchValue, setSearchValue] = useState<string>('');
    const tenantBranding = useSelector((state: any) => state.app.branding);
    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const leaveButtonBg = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    const leaveButtonFg = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;
    const { mutate: muteMutation } = useMarkAsMute(refreshThreadDetail);
    const { mutate: archiveMutate } = useArchiveThread(
        () => {
            refreshThreadDetail();
            handleArchiveThread(threadID);
            setDisplayThreadInfoEl(null);
        },
        () => {
            dispatch(
                addNotification({
                    label: t('chat.archive'),
                    text: t('chat.archiveErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );
    const { mutate: unArchiveMutate } = useUnarchiveThread(
        () => {
            refreshThreadDetail();
            handleArchiveThread(threadID);
            setDisplayThreadInfoEl(null);
        },
        () => {
            dispatch(
                addNotification({
                    label: t('chat.unarchive'),
                    text: t('chat.unArchiveErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );
    const { mutate: leaveGroupThreadMutate, isLoading: leaveGroupLoading } = useLeaveGroupThread(
        async () => {
            navigate('/chat');
            refreshThreadDetail(true);
        },
        () => {
            dispatch(
                addNotification({
                    label: t('chat.leaveGroup'),
                    text: t('chat.leaveGroupErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );

    const {
        data: participantsData,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage: fetchingUserGroups,
    } = useInfiniteQuery(
        ['participants', threadID, participantSearchString],
        ({ pageParam = 1 }) => fetchParticipants(threadID, pageParam, participantSearchString),
        {
            enabled: threadType === 'groups' || threadType === 'multiple',
            getNextPageParam: (lastPage, allPages) => {
                if (lastPage) {
                    if (lastPage.meta.current_page === lastPage.meta.last_page) return undefined;
                    return allPages.length + 1;
                }
            },
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 10 * 2, // 20 minutes
        }
    );

    const handleSearchValueChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setParticipantSearchString(e.target.value);
    });

    useEffect(() => {
        setParticipantSearchString('');
        setSearchValue('');
    }, [threadID, description, title, createdAt]);

    const handleLeaveGroupThread = () => {
        setIsOpenLeaveGroupModal(false);
        leaveGroupThreadMutate(threadID as string);
    };

    if (isOpenLeaveGroupModal) {
        return (
            <LeaveGroupModal
                isOpen={isOpenLeaveGroupModal}
                leaveGroupLoading={leaveGroupLoading}
                setIsOpen={setIsOpenLeaveGroupModal}
                handleOnSubmit={handleLeaveGroupThread}
            />
        );
    }

    if (openManageParticipantModal) {
        return (
            <ManageParticipantModal
                openManageParticipantModal={openManageParticipantModal}
                setOpenManageParticipantModal={setOpenManageParticipantModal}
                participantSearchString={participantSearchString}
            />
        );
    }

    return (
        <Popover
            open={open}
            anchorEl={displayThreadInfoEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={() => setDisplayThreadInfoEl(null)}
        >
            <Paper
                sx={{
                    maxHeight: '85vh',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                }}
                elevation={0}
                className='thread-info'
                data-testid={'thread-info'}
            >
                <Box>
                    <Box className='thread-info-title' data-testid='thread-info-title'>
                        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>{t('chat.chatInfo')}</Typography>
                        <Box>
                            <TranslateBtnWrapper locale={clickedTranslateTextObj?.[id] ? 'translated' : locale}>
                                <Tooltip title={clickedTranslateTextObj[id] ? t('general.seeOriginal') : t('general.translate')}>
                                    <FontAwesomeIcon
                                        className={'c-pointer'}
                                        data-testid='translate-btn'
                                        icon={clickedTranslateTextObj[id] ? faSolidGlobe : faGlobe}
                                        style={{ margin: '0 5px', fontSize: '18px' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleTranslate(id, !clickedTranslateTextObj[id]);
                                        }}
                                    />
                                </Tooltip>
                            </TranslateBtnWrapper>
                            {threadType === 'private' && (
                                <FontAwesomeIcon
                                    className='c-pointer'
                                    onClick={() => {
                                        if (isArchived) {
                                            unArchiveMutate(parseInt(threadID as string));
                                        } else {
                                            archiveMutate(parseInt(threadID as string));
                                        }
                                        queryClient.invalidateQueries(['threads', ['', true]]);
                                    }}
                                    icon={faBoxArchive}
                                    style={{ margin: '0 5px', fontSize: '18px' }}
                                />
                            )}
                            <FontAwesomeIcon
                                className={threadType !== 'public' && !mutedByAdmin ? 'c-pointer' : ''}
                                data-testid='mute-thread-icon'
                                onClick={(e) => {
                                    if (threadType !== 'public' && !mutedByAdmin) {
                                        if (muted) {
                                            // Unmute API call
                                            muteMutation({
                                                threadID: threadID,
                                                formData: {
                                                    muted_until: null,
                                                },
                                            });
                                            navigate('.', {
                                                replace: true,
                                                state: {
                                                    ...location?.state,
                                                    mutedUntil: '',
                                                },
                                            });
                                        } else {
                                            // Open Mute Popover
                                            setAnchorEl(e.currentTarget);
                                        }
                                    }
                                }}
                                aria-describedby={Boolean(anchorEl) ? 'simple-popover' : undefined}
                                icon={muted || threadType === 'public' || mutedByAdmin ? faBellSlash : faBell}
                                style={{ margin: '0 5px', fontSize: '18px' }}
                            />
                            <MuteThread
                                isOpen={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                muted={muted}
                                mutedUntil={mutedUntil}
                                threadID={threadID}
                                muteOptionValue={muteOptionValue}
                                setAnchorEl={setAnchorEl}
                                setMuteOptionValue={setMuteOptionValue}
                                muteMutation={muteMutation}
                            />
                        </Box>
                    </Box>
                    <Box id='thread-info-avatar' className='thread-info-avatar' data-testid='thread-info-avatar'>
                        <Avatar sx={{ width: '140px', height: '140px', margin: '0 auto' }} src={avatarUrl} alt={title + 'avtar-img'} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {translating ? (
                                <Skeleton variant='text' width={200} height={30} />
                            ) : (
                                <Typography sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '1.5' }}>{title}</Typography>
                            )}
                        </Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '1.5' }}>
                            {t('chat.created')} {createdAt}
                        </Typography>
                    </Box>
                    <Box id='description' className='thread-info-description' data-testid='thread-info-description'>
                        <div className='d-flex justify-content-between'>
                            <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '1.5' }}>{t('chat.description')}</Typography>
                            {threadType === 'multiple' && isThreadAdmin && (
                                <img
                                    src={editGroupIcon}
                                    className='edit-group-icon c-pointer'
                                    onClick={() => {
                                        handleEditGroupDetail({
                                            id: threadID,
                                            title: title,
                                            description: description,
                                            image: avatarUrl,
                                        });
                                        setIsGroupSetting(true);
                                        setGroupEditMode(true);
                                    }}
                                    data-name='Layer 1'
                                />
                            )}
                        </div>
                        {translating ? (
                            <Skeleton variant='text' width={200} height={30} />
                        ) : (
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '1.5' }}>{description}</Typography>
                        )}
                    </Box>
                </Box>
                {(threadType === 'groups' || threadType === 'multiple') && (
                    <>
                        <Box className='participants-info mt-2' data-testid='participants-info'>
                            <div className='d-flex justify-content-between'>
                                <Typography className='mb-1' sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '1.5' }}>
                                    {t('chat.participants')}
                                </Typography>
                                {isThreadAdmin && threadType == 'multiple' && (
                                    <React.Fragment>
                                        <button className='manage-participant-button' onClick={() => setOpenManageParticipantModal(true)}>
                                            <Typography className='me-2' sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '1.5' }}>
                                                {t('chat.manage')}
                                            </Typography>
                                            <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M9.5 0C7.62108 0 5.78435 0.557165 4.22209 1.60104C2.65982 2.64491 1.44218 4.12861 0.723149 5.86451C0.00411622 7.60041 -0.184015 9.51054 0.182544 11.3534C0.549104 13.1962 1.45389 14.8889 2.78249 16.2175C4.11109 17.5461 5.80383 18.4509 7.64665 18.8175C9.48947 19.184 11.3996 18.9959 13.1355 18.2769C14.8714 17.5578 16.3551 16.3402 17.399 14.7779C18.4428 13.2157 19 11.3789 19 9.5C18.9973 6.98128 17.9955 4.5665 16.2145 2.78549C14.4335 1.00449 12.0187 0.00272419 9.5 0ZM12.6667 10.2917H10.2917V12.6667C10.2917 12.8766 10.2083 13.078 10.0598 13.2265C9.91133 13.3749 9.70997 13.4583 9.5 13.4583C9.29004 13.4583 9.08868 13.3749 8.94021 13.2265C8.79175 13.078 8.70834 12.8766 8.70834 12.6667V10.2917H6.33334C6.12337 10.2917 5.92201 10.2083 5.77354 10.0598C5.62508 9.91133 5.54167 9.70996 5.54167 9.5C5.54167 9.29004 5.62508 9.08867 5.77354 8.94021C5.92201 8.79174 6.12337 8.70833 6.33334 8.70833H8.70834V6.33333C8.70834 6.12337 8.79175 5.92201 8.94021 5.77354C9.08868 5.62507 9.29004 5.54167 9.5 5.54167C9.70997 5.54167 9.91133 5.62507 10.0598 5.77354C10.2083 5.92201 10.2917 6.12337 10.2917 6.33333V8.70833H12.6667C12.8766 8.70833 13.078 8.79174 13.2265 8.94021C13.3749 9.08867 13.4583 9.29004 13.4583 9.5C13.4583 9.70996 13.3749 9.91133 13.2265 10.0598C13.078 10.2083 12.8766 10.2917 12.6667 10.2917Z'
                                                    fill={leaveButtonBg}
                                                />
                                            </svg>
                                        </button>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className='search-section d-flex align-items-center'>
                                <p className='fa-regular form-control search-icon fa-magnifying-glass m-0'></p>
                                <input
                                    type='text'
                                    className='participants-search'
                                    placeholder={`${t('chat.searchParticipants')}`}
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        queryClient.invalidateQueries(['participants', threadID, e.target.value]);
                                        if (handleSearchValueChange) handleSearchValueChange(e);
                                    }}
                                />
                            </div>
                        </Box>
                        <ThreadParticipantList
                            fetchingUserGroups={fetchingUserGroups}
                            hasNextPage={hasNextPage}
                            userGroups={participantsData?.pages}
                            fetchNextPage={fetchNextPage}
                        />
                        {!isThreadAdmin && threadType === 'multiple' && (
                            <>
                                <button
                                    className='leave-group-btn d-flex justify-content-center align-items-center mt-1'
                                    id='leave-group-btn'
                                    onClick={() => {
                                        setDisplayThreadInfoEl(null);
                                        setIsOpenLeaveGroupModal(true);
                                    }}
                                    style={{ backgroundColor: leaveButtonBg, color: leaveButtonFg }}
                                >
                                    <FontAwesomeIcon className='leave-group-icon me-2' icon={faPersonFromPortal} />
                                    <p className='m-0 leave-group-text'>{t('chat.leaveGroup')}</p>
                                </button>
                            </>
                        )}
                    </>
                )}
            </Paper>
        </Popover>
    );
};
