import React, { FunctionComponent, useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { FileData } from '../../../../types/types';
import classnames from 'classnames';
import Scrollbar from '../../common/scrollbar/scrollbar';
import ImageCrop from '../../common/imageCrop/imageCrop';
import FilePreview from './filePreview';
import FilesList from './filesList';
import { useInView } from 'react-intersection-observer';
import { MediaContainer } from '../../mediaContainer/mediaContainer';
import { MessageFiles } from '../../../../types/interface/MessageFiles';
import { truncateText } from '../../../utils/truncateText';

import './filesDisplay.scss';
import { height } from '@mui/system';
const fileDescriptions: any = require('./fileDescriptions.json');

export type FilesDisplayProps = {
    files: Array<FileData>;
    className?: string;
    displayCompact?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    socialPost?: boolean;
    repostItem?: boolean;
};

const FilesDisplay: FunctionComponent<FilesDisplayProps> = ({ files, className, displayCompact = false, children, socialPost, repostItem }) => {
    const [filesModalOpen, setFilesModal] = useState(false);
    const [previewFile, setPreviewFile] = useState<FileData | null>(null);
    const ref = useRef<HTMLVideoElement | null>(null);
    const { ref: inViewRef, inView } = useInView({
        threshold: 1,
    });

    const setRefs = useCallback(
        (node: any) => {
            ref.current = node;
            inViewRef(node);
        },
        [inViewRef]
    );

    useEffect(() => {
        if (inView) {
            ref?.current?.play();
        } else {
            ref?.current?.pause();
        }
    }, [inView]);

    const [mediaFiles, setMedia] = useState<Array<FileData>>([]);
    const [docFiles, setDocs] = useState<Array<FileData>>([]);

    const isSupportedByBrowser = CSS.supports('backdrop-filter: blur(50px)');

    useEffect(() => {
        const newMedial: Array<FileData> = [];
        const newDocs: Array<FileData> = [];
        files.forEach((file) => {
            const type = file.mime_type.split('/')[0];
            if (type === 'image' || type === 'video') {
                newMedial.push(file);
            } else {
                newDocs.push(file);
            }
        });

        setMedia(newMedial);
        setDocs(newDocs);
    }, [files]);

    const openFilesList = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setFilesModal(true);
    };

    const showFile = (file: FileData) => {
        setFilesModal(false);
        setPreviewFile(file);
    };

    const renderImg = (file: FileData, colWidth: number, extendFiles: number = 0) => {
        const imgPreviewUrl = file.type === 'image' ? file.url : file.preview;
        if (files.length === 1 && !displayCompact && files[0].type === 'image') {
            let customClassValues = 'img-preview c-pointer img-rad';

            if (socialPost)
                // Show full width
                customClassValues = 'img-preview-single c-pointer';

            return (
                <img
                    data-testid='compact-file-image'
                    src={imgPreviewUrl}
                    className={customClassValues}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setPreviewFile(file);
                    }}
                    alt={'Media Content'}
                />
            );
        }
        // Show video
        if (files.length === 1 && !displayCompact && files[0].type === 'video') {
            const file = files[0];
            return (
                <video data-testid='individual-preview-video' className='preview-single-video' controls autoPlay muted ref={setRefs}>
                    <source src={file ? file.url : ''} type={file ? file.mime_type : ''} />
                </video>
            );
        }

        return (
            <Col
                data-testid='preview-column'
                className='img-preview-col position-relative c-pointer p-0 background-layer'
                data-type={file?.type}
                xs={colWidth}
                style={{
                    backgroundImage: `url(${imgPreviewUrl})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '4px',
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setPreviewFile(file);
                }}
            >
                {file.type === 'video' ? (
                    <React.Fragment>
                        <ImageCrop
                            className='w-100 backdrop-blur img-rad'
                            src={imgPreviewUrl}
                            fitImgParentSelector='.feed-list-container'
                            imgProps={{
                                style: { objectFit: 'contain', borderRadius: '4px' },
                            }}
                            imgContainerStyleProps={{
                                height: '300px',
                                backgroundColor: isSupportedByBrowser ? 'none' : '#efefef',
                                borderRadius: '4px',
                            }}
                        />
                        <img
                            className='video-preview-icon position-absolute img-responsive cursor-pointer'
                            src='/assets/img/video-preview-icon.svg'
                            alt='video'
                        />
                    </React.Fragment>
                ) : (
                    ''
                )}
                {extendFiles > 0 ? (
                    <div
                        className='files-extender position-absolute h-100 w-100 d-flex justify-content-center align-items-center'
                        onClick={openFilesList}
                    >
                        <h2 className='fw-bold text-white m-0'>+{extendFiles}</h2>
                    </div>
                ) : (
                    ''
                )}
                {file.type === 'image' ? (
                    <div className={'image-layer-container backdrop-blur'}>
                        <img data-testid='file-image' src={imgPreviewUrl} className={'image-layer'} alt={'image-layer'} />
                    </div>
                ) : (
                    ''
                )}
            </Col>
        );
    };

    const renderMediaCompact = () => {
        if (!mediaFiles.length) return <div />;

        const imagesCols: Array<React.ReactElement> = [];
        const media = [...mediaFiles];
        for (let i = 0; i < media.length; i++) {
            if (i > 3) break;
            imagesCols.push(renderImg(media[i], 3, i === 3 && media.length > 4 ? media.length - (i + 1) : 0));
        }
        return <Row className='px-2'>{imagesCols}</Row>;
    };

    const renderDocs = () => {
        if (!docFiles.length) return <div />;

        return docFiles.map((doc) => {
            const description = truncateText(fileDescriptions[doc.mime_type.split('/')[1]] || 'File', 27, 0, 4);
            const docName = truncateText(doc.name, 20, 7, 4);

            return (
                <a data-testid='doc-preview' className='doc-preview d-block mb-1' href={doc.url} target='_blank'>
                    <img data-testid='doc-img' className='d-inline-block ms-0' src={doc.preview} />
                    <div className='d-inline-block'>
                        <span data-testid='doc-name' className='font-weight-bold d-flex align-items-center'>
                            {docName}
                        </span>
                        <span data-testid='doc-description' className='font-weight-light d-flex align-items-center'>
                            {description}
                        </span>
                    </div>
                </a>
            );
        });
    };

    const updateMediaFiles = () => {
        let updatedMediaFiles: MessageFiles[] = [];
        for (let media of mediaFiles) {
            updatedMediaFiles.push({
                id: media?.id as number,
                name: media.name,
                preview: media.preview,
                type: media.type,
                path: media.url,
            });
        }
        return updatedMediaFiles;
    };

    return files.length > 0 ? (
        <div
            data-testid='files-display'
            className={classnames('files-display-comp', className)}
            style={{
                margin: mediaFiles.length >= 1 && socialPost && !repostItem ? '0 -1.25rem 0 -1rem' : repostItem ? '0px' : 'inherit',
                paddingLeft: repostItem ? '5px' : 'inherit',
            }}
        >
            <FilesList
                isOpen={filesModalOpen}
                toggle={() => setFilesModal(false)}
                fileClickCallback={(file: FileData) => showFile(file)}
                files={mediaFiles}
            />

            {previewFile ? <FilePreview isOpen={previewFile !== null} file={previewFile} toggle={() => setPreviewFile(null)} /> : ''}

            {mediaFiles.length === 1 && socialPost ? (
                mediaFiles[0].type === 'image' ? (
                    <MediaContainer
                        media={updateMediaFiles()}
                        mediaContainerProps={{
                            height: '300px',
                            width: '100%',
                        }}
                        objectFit='contain'
                        displayImagePreview={true}
                    />
                ) : (
                    <div className='media-container'>{renderImg(mediaFiles[0], 12)}</div>
                )
            ) : (
                <div
                    className={classnames('media-container mb-1', {
                        'float-left me-3 d-flex justify-content-center align-items-flex-start w-auto h-auto':
                            mediaFiles.length === 1 && !displayCompact,
                    })}
                >
                    {displayCompact
                        ? renderMediaCompact()
                        : mediaFiles.length > 0 && (
                              <MediaContainer
                                  media={updateMediaFiles()}
                                  mediaContainerProps={{
                                      height: '300px',
                                      width: '100%',
                                  }}
                                  displayImagePreview={true}
                              />
                          )}
                </div>
            )}

            {children}

            <div
                className={classnames('docs-container', {
                    scroll: docFiles.length > 2,
                })}
            >
                {docFiles.length > 2 ? <Scrollbar>{renderDocs()}</Scrollbar> : renderDocs()}
            </div>
        </div>
    ) : (
        <div />
    );
};

export default FilesDisplay;
